/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
export type AnyObject = {
  [index: string]: any;
};

export type AsyncState<T> = {
  value?: T;
  loading: boolean;
  error: Error | undefined;
};

export type KeyOf<TTarget> = keyof TTarget;

export type KeysOfType<TTarget, TValue> = {
  [K in keyof TTarget]: TTarget[K] extends TValue ? K : never;
}[keyof TTarget];

export type LiteralKeyedObject<K extends string, V> = { [key in K]: V };

// prettier-ignore
export type RequireOnlyOne<T, Keys extends keyof T = keyof T> =
    Pick<T, Exclude<keyof T, Keys>>
    & {
        [K in Keys]-?:
            Required<Pick<T, K>>
            & Partial<Record<Exclude<Keys, K>, undefined>>
    }[Keys];

export type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export interface ApiResult<T> {
  result?: T | null;
  hasErrors: boolean;
  validationFailures?: ValidationError[];
}

export interface ValidationError {
  propertyName?: string;
  errorMessage?: string;
}

export interface DataTablePage<T> {
  rows?: T[];
  columns?: ColumnDefinition[];
  totalRecords: number;
  totalFilteredRecords: number;
}

export interface ColumnDefinition {
  accessor: string;
  header: string;
  sortable: boolean;
  filterable: boolean;
  type: string;
  componentAttribute: ComponentAttribute | null;
}

export interface ComponentAttribute {
  name: string;
  typeId: string;
}

export class AccountService {
  /**
   *
   */
  static keepSessionAlive(options: IRequestOptions = {}): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/keep-session-alive';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLoggedInUserDetails(options: IRequestOptions = {}): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isUserLoggedIn(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/am-i-logged-in';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isCurrentUserAnInstructor(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/is-instructor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: PasswordLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static vendorLogin(
    params: {
      /** requestBody */
      body?: VendorLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/vendor-login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customerLoginWithSsn(
    params: {
      /** requestBody */
      body?: CustomerAuthWithSsnDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLoginResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/customer-login-with-ssn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customerLoginWithAlternateId(
    params: {
      /** requestBody */
      body?: CustomerAuthWithAlternateIdDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLoginResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/customer-login-with-alternate-id';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customerLoginWithCustomerNumber(
    params: {
      /** requestBody */
      body?: CustomerAuthWithCustomerNumberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLoginResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/customer-login-with-customer-number';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customerLoginWithDriversLicense(
    params: {
      /** requestBody */
      body?: CustomerAuthWithDriversLicenseDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLoginResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/customer-login-with-drivers-license';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customerLoginWithToken(
    params: {
      /** requestBody */
      body?: CustomerLoginWithTokenCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLoginResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/customer-login-with-token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/forgot-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/reset-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangeUserPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/change-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeExpiredPassword(
    params: {
      /** requestBody */
      body?: ChangeExpiredPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/change-expired-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccountRedirect(
    params: {
      /**  */
      username?: string;
      /**  */
      token?: string;
      /**  */
      portal?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account-redirect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { username: params['username'], token: params['token'], portal: params['portal'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccount(
    params: {
      /**  */
      username?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountConfirmationResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { username: params['username'], token: params['token'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AccountDistributionCodeService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountDistributionCodeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account-distribution-codes/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountDistributionCodeListItemDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account-distribution-codes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AccountDistributionCodeCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account-distribution-codes';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AccountDistributionCodeCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account-distribution-codes';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account-distribution-codes/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AchBalanceReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetAchBalanceDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AchBalanceListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/ach-balance/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetAchBalanceDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/ach-balance/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AchBatchService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WeeklyAchBatchDatesApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/ach-batches/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class AchReturnsReportService {
  /**
   *
   */
  static getOptions(options: IRequestOptions = {}): Promise<AchReturnsReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/ach-returns/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetAchReturnsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AchReturnsListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/ach-returns/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetAchReturnsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/ach-returns/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AdminCustomerAutoRenewalService {
  /**
   *
   */
  static getPaymentMethod(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerAutoRenewPaymentMethodDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/auto-renew/customer-payment-method';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deletePaymentMethod(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/auto-renew/delete-customer-payment-method';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static toggleAutoRenewal(
    params: {
      /**  */
      shoppingCartItemId?: number;
      /**  */
      isEnabled?: boolean;
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/auto-renew/toggle-auto-renewal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        shoppingCartItemId: params['shoppingCartItemId'],
        isEnabled: params['isEnabled'],
        customerId: params['customerId']
      };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProducts(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AutoRenewProductDisplayDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/auto-renew/customer-products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enrollProduct(
    params: {
      /**  */
      customerId?: number;
      /**  */
      transactionCustomerProductId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/auto-renew/enroll-customer-product';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        customerId: params['customerId'],
        transactionCustomerProductId: params['transactionCustomerProductId']
      };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unenrollProduct(
    params: {
      /**  */
      customerId?: number;
      /**  */
      customerAutoRenewProductId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/auto-renew/unenroll-customer-product';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        customerId: params['customerId'],
        customerAutoRenewProductId: params['customerAutoRenewProductId']
      };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AdminHarvestsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetHarvestsForCustomerDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetHarvestDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/harvests/table';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static post(
    params: {
      /**  */
      harvestSpecies?: HarvestSpecies;
      /**  */
      id?: number;
      /**  */
      transactionTagId?: number;
      /**  */
      confirmationNumber?: string;
      /**  */
      dateKilled?: string;
      /**  */
      countyCodeId?: number;
      /**  */
      landTypeCode?: LandTypeCode;
      /**  */
      genderCode?: GenderCode;
      /**  */
      antlerCode?: AntlerCode;
      /**  */
      deerAreaId?: number;
      /**  */
      validationMethod?: ValidationMethod;
      /**  */
      turkeyMaturityCode?: TurkeyMaturityCode;
      /**  */
      harvestImageUri?: string;
      /**  */
      harvestImage: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/harvests';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        HarvestSpecies: params['harvestSpecies'],
        Id: params['id'],
        TransactionTagId: params['transactionTagId'],
        ConfirmationNumber: params['confirmationNumber'],
        DateKilled: params['dateKilled'],
        CountyCodeId: params['countyCodeId'],
        LandTypeCode: params['landTypeCode'],
        GenderCode: params['genderCode'],
        AntlerCode: params['antlerCode'],
        DeerAreaId: params['deerAreaId'],
        ValidationMethod: params['validationMethod'],
        TurkeyMaturityCode: params['turkeyMaturityCode'],
        HarvestImageUri: params['harvestImageUri']
      };

      let data = null;
      data = new FormData();
      if (params['harvestImage']) {
        if (Object.prototype.toString.call(params['harvestImage']) === '[object Array]') {
          for (const item of params['harvestImage']) {
            data.append('HarvestImage', item as any);
          }
        } else {
          data.append('HarvestImage', params['harvestImage'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateHarvestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/harvests';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      transactionTagId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/harvests/{transactionTagId}';
      url = url.replace('{transactionTagId}', params['transactionTagId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendHarvestEmail(
    params: {
      /**  */
      harvestTagId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/harvests/send-harvest-email';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { harvestTagId: params['harvestTagId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AdminVerifyCustomerService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer/verify-customer/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: VerifyCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer/verify-customer';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AuthorizationService {
  /**
   *
   */
  static exchange(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/connect/token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AutoRenewService {
  /**
   *
   */
  static getPaymentMethod(options: IRequestOptions = {}): Promise<CustomerAutoRenewPaymentMethodDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/auto-renew/payment-method';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addPaymentMethod(
    params: {
      /** requestBody */
      body?: CreateEditCustomerAutoRenewPaymentMethodDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/auto-renew/add-payment-method';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deletePaymentMethod(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/auto-renew/delete-payment-method';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProducts(options: IRequestOptions = {}): Promise<AutoRenewProductDisplayDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/auto-renew/products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enrollProduct(
    params: {
      /**  */
      transactionCustomerProductId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/auto-renew/enroll-product';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { transactionCustomerProductId: params['transactionCustomerProductId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unenrollProduct(
    params: {
      /**  */
      customerAutoRenewProductId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/auto-renew/unenroll-product';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerAutoRenewProductId: params['customerAutoRenewProductId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static retryAutoRenewProcess(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/auto-renew/retry';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIsEnabled(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/auto-renew/enabled';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class AutoRenewalsReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetAutoRenewalsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AutoRenewalsListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/auto-renewals/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class BoatRenewalService {
  /**
   *
   */
  static getBoatRenewal(
    params: {
      /** requestBody */
      body?: BoatRenewalLookupCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BoatRenewalDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/boat-renewal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBoatRenewalTransactionById(
    params: {
      /**  */
      boatRenewalId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionBoatDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/boat-renewal/get-transaction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { boatRenewalId: params['boatRenewalId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionToken(
    params: {
      /** requestBody */
      body?: GetBoatRenewalTransactionTokenQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElavonTransactionTokenResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/boat-renewal/get-transaction-token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validateAddress(
    params: {
      /** requestBody */
      body?: BoatRenewalDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/boat-renewal/validate-address';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifyTransaction(
    params: {
      /**  */
      transactionId?: string;
      /** requestBody */
      body?: BoatRenewalDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateBoatTransactionResultDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/boat-renewal/verify-transaction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { transactionId: params['transactionId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static saveTransactionFailure(
    params: {
      /**  */
      transactionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/boat-renewal/save-transaction-failure';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { transactionId: params['transactionId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resendBoatRenewalTransactionReceiptEmail(
    params: {
      /** requestBody */
      body?: BoatRenewalTransactionReceiptEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/boat-renewal/resend-boat-receipt-email';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static transactionReceiptPrint(
    params: {
      /** requestBody */
      body?: TransactionReceiptDetails;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/boat-renewal/print-receipt/pdf/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class BoatRenewalAdminService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BoatRenewalDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/renewals/boat-renewals/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: BoatRenewalUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/renewals/boat-renewals';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class BoatRenewalsReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetBoatRenewalsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BoatRenewalListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/renewals/boat-renewals/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportBoatRenewalsExcel(
    params: {
      /** requestBody */
      body?: GetBoatRenewalsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/renewals/boat-renewals/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class BoatRenewalTransactionsReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetBoatTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionBoatListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/boat-renewal-transactions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportBoatRenewalTransactionsExcels(
    params: {
      /** requestBody */
      body?: GetBoatTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/boat-renewal-transactions/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CertificationCodeService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CertificationCodeGetDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/certification-codes/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(options: IRequestOptions = {}): Promise<CertificationCodeGetDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/certification-codes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CertificationCodeGetDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/certification-codes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/certification-codes/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClassEventTypeService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassEventTypeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/class-event-types/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassEventTypeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/class-event-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ClassEventTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/class-event-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ClassEventTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/class-event-types';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/class-event-types/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClerkSalesReportService {
  /**
   *
   */
  static getTenderTypeSalesTable(
    params: {
      /** requestBody */
      body?: GetVendorReportClerkRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenderTypeSalesListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/tender-type/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLicensesSalesByDateTable(
    params: {
      /** requestBody */
      body?: GetVendorReportClerkRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LicenseSalesByDateListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/license-sales-by-date/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionRecordsDateTable(
    params: {
      /** requestBody */
      body?: GetVendorReportClerkRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionRecordListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transaction-details/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CommercialLicenseRenewalService {
  /**
   *
   */
  static lookupCommercialLicense(
    params: {
      /** requestBody */
      body?: CommercialLicenseRenewalLookupCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommercialLicenseAccountInformationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/commercial-license-renewal/license-lookup';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCommercialLicenseRenewalTransactionById(
    params: {
      /**  */
      commercialLicenseNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionCommercialDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/commercial-license-renewal/get-transaction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { commercialLicenseNumber: params['commercialLicenseNumber'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllCommercialLicenses(
    params: {
      /** requestBody */
      body?: GetUnpaidCommercialLicensesByEnteredLicenseNumberQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommercialLicenseListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/commercial-license-renewal/get-all-licenses';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionToken(
    params: {
      /** requestBody */
      body?: GetCommercialLicenseRenewalTransactionTokenQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElavonTransactionTokenResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/commercial-license-renewal/get-transaction-token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifyTransaction(
    params: {
      /** requestBody */
      body?: VerifyRenewalAndCreateCommercialTransactionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateTransactionCommercialResultDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/commercial-license-renewal/verify-transaction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static saveTransactionFailure(
    params: {
      /**  */
      transactionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/commercial-license-renewal/save-transaction-failure';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { transactionId: params['transactionId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static transactionReceiptPrint(
    params: {
      /** requestBody */
      body?: TransactionReceiptDetails;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/commercial-license-renewal/print-receipt/pdf/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLastImportedDate(options: IRequestOptions = {}): Promise<DateTimeOffsetApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/commercial-license-renewal/last-imported';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIsEnabled(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/commercial-license-renewal/enabled';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CommercialLicenseRenewalTransactionsReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetCommercialLicenseTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionCommercialListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/licenses/commercial-license-transactions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportCommercialLicenseRenewalExcel(
    params: {
      /** requestBody */
      body?: GetCommercialLicenseTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/licenses/commercial-license-transactions/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CorporationService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: CorporationDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CorporationDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/corporations/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CorporationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/corporations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/corporations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CorporationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/corporations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CorporationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/corporations';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activate(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/corporations/activate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deactivate(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/corporations/deactivate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportCorporationsExcelFile(
    params: {
      /** requestBody */
      body?: CorporationDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/corporations/table/exports/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/corporations/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CourseSystemEnrollmentsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetCourseSystemEnrollmentDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CourseSystemEnrollmentListDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/course-system-enrollments/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getExcelReport(
    params: {
      /** requestBody */
      body?: GetCourseSystemEnrollmentDataTableReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/course-system-enrollments/course-system-enrollments/reports/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerApplicationService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: LotteryCustomerApplicationDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryCustomerApplicationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/lottery-customer-application/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerAttachmentService {
  /**
   *
   */
  static getByCustomerId(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: CustomerAttachmentDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerAttachmentListDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-attachments/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerAttachmentDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-attachments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-attachments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      id?: number;
      /**  */
      customerId?: number;
      /**  */
      description?: string;
      /**  */
      documentTypeId?: number;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-attachments/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        Id: params['id'],
        CustomerId: params['customerId'],
        Description: params['description'],
        DocumentTypeId: params['documentTypeId']
      };

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id?: number;
      /**  */
      customerId?: number;
      /**  */
      description?: string;
      /**  */
      documentTypeId?: number;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-attachments';

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);
      configs.params = {
        Id: params['id'],
        CustomerId: params['customerId'],
        Description: params['description'],
        DocumentTypeId: params['documentTypeId']
      };

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerCatalogService {
  /**
   *
   */
  static get(options: IRequestOptions = {}): Promise<CustomerCatalogOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getShoppingCart(options: IRequestOptions = {}): Promise<ShoppingCartDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/shopping-cart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static toggleAutoRenewal(
    params: {
      /**  */
      shoppingCartItemId?: number;
      /**  */
      isEnabled?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/toggle-auto-renewal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { shoppingCartItemId: params['shoppingCartItemId'], isEnabled: params['isEnabled'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addShoppingCartItem(
    params: {
      /** requestBody */
      body?: ShoppingCartProductDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/add-item';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addHipCertificationItem(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/add-hip-certification';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addProductAncillaryInfo(
    params: {
      /** requestBody */
      body?: ProductAncillaryInfoDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/add-ancillary-info';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addDonation(
    params: {
      /** requestBody */
      body?: AddDonationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/add-donation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSelectedDonation(
    params: {
      /**  */
      isReceiptView: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CurrentDonationsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/get-donation/{isReceiptView}';
      url = url.replace('{isReceiptView}', params['isReceiptView'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeItem(
    params: {
      /**  */
      productId?: number;
      /**  */
      duplicateProductId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/remove-item';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { productId: params['productId'], duplicateProductId: params['duplicateProductId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clearCart(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/clear-cart';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addOnlineCourseToCart(
    params: {
      /** requestBody */
      body?: AddOnlineCourseToCartCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/add-online-course-to-cart';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOnlineCourseByCourseCode(
    params: {
      /** requestBody */
      body?: GetOnlineCourseProductByCourseCodeQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogProductDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/get-online-course-product';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductImages(
    params: {
      /**  */
      productId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogProductImageDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/catalog/get-product-images';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { productId: params['productId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerCertificationsService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: CustomerCertificationDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerCertificationDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerCertificationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateCustomerCertificationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateCustomerCertificationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createExemptionCertification(
    params: {
      /** requestBody */
      body?: CreateCustomerExemptionCertificationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications/exemption';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOptions(options: IRequestOptions = {}): Promise<CustomerCertificationOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadCustomerCertificationPdf(
    params: {
      /** requestBody */
      body?: GetCustomerCertificatePdfQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications/download-customer-certification-pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadInstructorCertificationPdf(
    params: {
      /** requestBody */
      body?: GetInstructorCertificatePdfQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications/download-instructor-certification-pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadParticipationCertificationPdf(
    params: {
      /** requestBody */
      body?: GetParticipationCertificatePdfQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-certifications/download-participation-certification-pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerCommentsService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      customerId?: number;
      /**  */
      revocationId?: number;
      /** requestBody */
      body?: CommentDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommentDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-comments/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'], revocationId: params['revocationId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommentDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-comments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-comments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CommentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-comments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CommentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customer-comments';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerCourseSystemService {
  /**
   *
   */
  static getCourseSystemCourses(options: IRequestOptions = {}): Promise<CourseCatalogDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/get-courses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createCourseRegistration(
    params: {
      /** requestBody */
      body?: CreateCourseSystemEnrollmentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CourseSystemEnrollmentGetDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/create-registration';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEnrollmentLoginUrlsForLoggedInUser(
    options: IRequestOptions = {}
  ): Promise<GetCourseSystemEnrollmentLoginUrlResponseDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/get-login-url';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerEventsService {
  /**
   *
   */
  static getEventsForLoggedInUser(options: IRequestOptions = {}): Promise<GetEventsForCustomerQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/my-events';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getActiveEvents(options: IRequestOptions = {}): Promise<IspEventListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/active';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IspEventDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByPrivatePasscode(
    params: {
      /**  */
      passcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IspEventDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/private';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { passcode: params['passcode'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static registerLoggedInUserToEvent(
    params: {
      /** requestBody */
      body?: RegisterLoggedInUserToEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RegisterLoggedInUserToEventResultDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/register-self';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unregisterLoggedInUserFromEvent(
    params: {
      /** requestBody */
      body?: UnregisterLoggedInUserFromEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UnregisterLoggedInUserFromEventResultDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/unregister-self';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static swapCustomerEventRegistration(
    params: {
      /** requestBody */
      body?: SwapCustomerRegistrationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/swap-registration';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static waitlistLoggedInUserToEvent(
    params: {
      /** requestBody */
      body?: WaitlistLoggedInUserToEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WaitlistLoggedInUserToEventResultDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/waitlist-self';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeLoggedInUserFromWaitlist(
    params: {
      /** requestBody */
      body?: RemoveCustomerEventFromWaitlistCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RemoveCustomerEventFromWaitlistResultDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/remove-self-from-waitlist';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canLoggedInUserRegisterForEvent(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CanLoggedInUserRegisterForEventQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/{id}/can-i-register-for-event';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static swapCustomerEventWaitlist(
    params: {
      /** requestBody */
      body?: SwapCustomerWaitlistCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/swap-waitlist';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadCertificationPdfForLoggedInUser(
    params: {
      /** requestBody */
      body?: GetCustomerCertificatePdfForLoggedInUserQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events/download-customer-certification-pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static emailInstructorOnEvent(
    params: {
      /** requestBody */
      body?: EmailInstructorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/events';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerHarvestsService {
  /**
   *
   */
  static getAllForCustomer(
    params: {
      /**  */
      customerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionTagListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/harvests/get-all/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static post(
    params: {
      /**  */
      harvestSpecies?: HarvestSpecies;
      /**  */
      id?: number;
      /**  */
      transactionTagId?: number;
      /**  */
      confirmationNumber?: string;
      /**  */
      dateKilled?: string;
      /**  */
      countyCodeId?: number;
      /**  */
      landTypeCode?: LandTypeCode;
      /**  */
      genderCode?: GenderCode;
      /**  */
      antlerCode?: AntlerCode;
      /**  */
      deerAreaId?: number;
      /**  */
      validationMethod?: ValidationMethod;
      /**  */
      turkeyMaturityCode?: TurkeyMaturityCode;
      /**  */
      harvestImageUri?: string;
      /**  */
      image: any;
      /**  */
      harvestImage: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/harvests';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        HarvestSpecies: params['harvestSpecies'],
        Id: params['id'],
        TransactionTagId: params['transactionTagId'],
        ConfirmationNumber: params['confirmationNumber'],
        DateKilled: params['dateKilled'],
        CountyCodeId: params['countyCodeId'],
        LandTypeCode: params['landTypeCode'],
        GenderCode: params['genderCode'],
        AntlerCode: params['antlerCode'],
        DeerAreaId: params['deerAreaId'],
        ValidationMethod: params['validationMethod'],
        TurkeyMaturityCode: params['turkeyMaturityCode'],
        HarvestImageUri: params['harvestImageUri']
      };

      let data = null;
      data = new FormData();
      if (params['image']) {
        if (Object.prototype.toString.call(params['image']) === '[object Array]') {
          for (const item of params['image']) {
            data.append('image', item as any);
          }
        } else {
          data.append('image', params['image'] as any);
        }
      }

      if (params['harvestImage']) {
        if (Object.prototype.toString.call(params['harvestImage']) === '[object Array]') {
          for (const item of params['harvestImage']) {
            data.append('HarvestImage', item as any);
          }
        } else {
          data.append('HarvestImage', params['harvestImage'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByTransactionTagId(
    params: {
      /**  */
      transactionTagId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetHarvestDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/harvests/{transactionTagId}';
      url = url.replace('{transactionTagId}', params['transactionTagId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportCustomerTagsPdfFile(
    params: {
      /** requestBody */
      body?: GetCustomerTagsExportPdfQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/harvests/tag-export-pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerLicenseEligibilityCodeService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLicenseEligibilityCodeDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/custumer-license-eligibility-codes/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLicenseEligibilityCodeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/custumer-license-eligibility-codes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CustomerLicenseEligibilityCodeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/custumer-license-eligibility-codes';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CustomerLicenseEligibilityCodeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/custumer-license-eligibility-codes';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerLookupService {
  /**
   *
   */
  static lookupWithAlternateId(
    params: {
      /** requestBody */
      body?: CustomerLookupWithAlternateIdQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLookupDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/CustomerLookup/customer-lookup-with-alternate-id';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lookupWithCustomerNumber(
    params: {
      /** requestBody */
      body?: CustomerLookupWithCustomerNumberQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLookupDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/CustomerLookup/customer-lookup-with-customer-number';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lookupWithDriversLicense(
    params: {
      /** requestBody */
      body?: CustomerLookupWithDriversLicenseQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLookupDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/CustomerLookup/customer-lookup-with-drivers-license';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lookupWithSsn(
    params: {
      /** requestBody */
      body?: CustomerLookupWithSsnQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerLookupDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/CustomerLookup/customer-lookup-with-ssn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerOnlineCourseService {
  /**
   *
   */
  static getOnlineCoursesForLoggedInUser(
    options: IRequestOptions = {}
  ): Promise<CourseSystemEnrollmentDetailDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/online-courses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProofOfOnlineCourseCompletionExport(
    params: {
      /** requestBody */
      body?: GetProofOfOnlineCourseCompletionPdfExportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/online-courses/proof-of-completion/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isOnlineCourseEnabled(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/online-courses/online-course-enabled';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerOptionsService {
  /**
   *
   */
  static getOptions(options: IRequestOptions = {}): Promise<FormOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEnums(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/enums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCustomerOptions(options: IRequestOptions = {}): Promise<FormOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/customer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorOptions(options: IRequestOptions = {}): Promise<VendorOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/vendor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCorporationOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CorporationOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/corporation/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRevocationOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RevocationOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/revocations';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductOptions(options: IRequestOptions = {}): Promise<ProductOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDocumentTypeOptions(options: IRequestOptions = {}): Promise<DocumentTypeOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/document-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductRuleOptions(
    params: {
      /**  */
      productId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRuleOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/product-rules';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { productId: params['productId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductFeeOptions(options: IRequestOptions = {}): Promise<ProductFeeOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/product-fees';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductTagOptions(options: IRequestOptions = {}): Promise<ProductTagOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/product-tags';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFilteredEventOptions(options: IRequestOptions = {}): Promise<EventOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/events';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassEventTypeOptions(options: IRequestOptions = {}): Promise<ClassEventTypeOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/class-event-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAddressOptions(options: IRequestOptions = {}): Promise<AddressOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/address';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryDistributionFeeOptions(
    options: IRequestOptions = {}
  ): Promise<LotteryFeeDistributionOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/lottery-distribution-fees';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryApplicationOptions(
    params: {
      /**  */
      lotteryApplicationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryApplicationOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/lottery-application-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lotteryApplicationId: params['lotteryApplicationId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDisplayMenuByName(
    params: {
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DisplayMenuDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/options/display-menu';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerPortalCustomersService {
  /**
   *
   */
  static getDashboard(options: IRequestOptions = {}): Promise<CustomerDashboardDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/customer/dashboard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCertificationsForLoggedInUser(
    options: IRequestOptions = {}
  ): Promise<CustomerCertificationDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/customer/my-certifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRevocationCount(options: IRequestOptions = {}): Promise<RevocationListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/customer/my-revocation-count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportTransactionCustomerLicensePdfFile(
    params: {
      /** requestBody */
      body?: GetCustomerLicenseExportPdfQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/customer/license/pdf/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportAllCustomerLicensePdfFile(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/customer/all-license/pdf/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerPreferencePointsService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: CustomerPreferencePointsAdminDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerPreferencePointsAdminListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/preference-points/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CustomerPreferencePointCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/preference-points';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerProductsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetCustomerProductCustomDataTableQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerProductListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/products/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportCustomerProductsExcelFile(
    params: {
      /** requestBody */
      body?: GetCustomerProductCustomDataTableQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/products/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionTagTable(
    params: {
      /** requestBody */
      body?: GetTransactionTagDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerProductTransactionTagListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/products/transaction-tag-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportCustomerProductTransactionTagsExcelFile(
    params: {
      /** requestBody */
      body?: GetTransactionTagDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/products/table/export/transaction-tag-excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCustomerProductOptions(options: IRequestOptions = {}): Promise<CustomerProductsOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/products/customer-product-options';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomersService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: CustomerDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDashboard(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDashboardDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/{id}/dashboard';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableOfPreviousAddresses(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: CustomerPreviousAddressesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerPreviousAddressDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/previous-addresses';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AdminCreateCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AdminUpdateCustomerWrapper;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportCustomersExcel(
    params: {
      /** requestBody */
      body?: CustomerDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRevocationCount(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32ApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/revocations-count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enrollToSmsTagging(
    params: {
      /** requestBody */
      body?: EnableSmsTaggingCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/enroll-sms-tagging';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static merge(
    params: {
      /** requestBody */
      body?: MergeCustomersCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/merge';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isCustomerLotteryMergeSelectionEnabled(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/customer-lottery-merge-selection-enabled';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DashboardService {
  /**
   *
   */
  static getDashboard(
    params: {
      /**  */
      customerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorDashboardDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/dashboard/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DataImportService {
  /**
   *
   */
  static upload(
    params: {
      /**  */
      email?: string;
      /**  */
      csvType?: CsvTypes;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/data-import/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = { Email: params['email'], CsvType: params['csvType'] };

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DisplayMenuService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DisplayMenuDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/display-menus/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DisplayMenuDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/display-menus/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/display-menus/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DisplayMenuDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/display-menus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DisplayMenuDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/display-menus';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/display-menus/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DocumentTypesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/document-types/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/document-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DocumentTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/document-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DocumentTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/document-types';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DuckStampExportService {
  /**
   *
   */
  static exportDuckStampPurchasesCsvFile(
    params: {
      /** requestBody */
      body?: GetDailyDuckStampPurchasesExportCsvFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/duckstamps/export/csv';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ECitationService {
  /**
   *
   */
  static lab365CustomerSearch(
    params: {
      /** requestBody */
      body?: ECitationCustomerInfoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ECitationCustomerDetailDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/eCitation/customer-search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EventAreaService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventAreaListDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-areas/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventAreaDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-areas/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: EventAreaDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-areas';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: EventAreaDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-areas';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EventLocationsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventLocationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/locations/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventLocationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(options: IRequestOptions = {}): Promise<EventLocationListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/locations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: EventLocationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/locations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: EventLocationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/locations';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/locations/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EventReportsService {
  /**
   *
   */
  static getClassEventTypeSummaryReportData(
    params: {
      /** requestBody */
      body?: GetClassEventTypeSummaryReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetClassEventTypeSummaryReportQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/class-event-type-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassEventTypeSummaryReportOptions(
    options: IRequestOptions = {}
  ): Promise<GetClassEventTypeSummaryReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/class-event-type-report-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassEventTypeSummaryReportExcel(
    params: {
      /** requestBody */
      body?: GetClassEventTypeSummaryReportExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/class-event-type-report/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParticipantsReportData(
    params: {
      /** requestBody */
      body?: GetParticipantsReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetParticipantsReportQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/participants-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParticipantsReportExcel(
    params: {
      /** requestBody */
      body?: GetParticipantsReportExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/participants-report/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventSummaryReportExcel(
    params: {
      /** requestBody */
      body?: GetEventResultsSummaryExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/event-summary/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorExpirationReportData(
    params: {
      /** requestBody */
      body?: GetInstructorExpirationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetInstructorExpirationQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/instructor-expirations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorExpirationReportExcel(
    params: {
      /** requestBody */
      body?: GetInstructorExpirationExcelReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/instructor-expiration/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getImportedCertificationsReportData(
    params: {
      /** requestBody */
      body?: GetImportedCertificationsReportDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImportedCertificationsReportListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/imported-certifications-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getImportedCertificationsReportExcel(
    params: {
      /** requestBody */
      body?: GetImportedCertificationsReportExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/imported-certifications-report/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCertificationsReportData(
    params: {
      /** requestBody */
      body?: GetCertificationsReportDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CertificationsReportListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/certifications-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCertificationsReportExcel(
    params: {
      /** requestBody */
      body?: GetCertificationsReportExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-reports/certifications-report/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EventsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEventDate(
    params: {
      /** requestBody */
      body?: EventDateTimeDto[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/create-event-date';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancel(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByMonth(
    params: {
      /** requestBody */
      body?: GetEventByMonthQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventCalendarDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/monthly-events';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFilteredEvents(
    params: {
      /** requestBody */
      body?: GetFilteredEventsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetFilteredEventsQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/filtered';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventDates(
    params: {
      /**  */
      eventId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDateTimeDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/event-dates/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: EventDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateStatus(
    params: {
      /** requestBody */
      body?: UpdateEventStatusCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/update/status';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventInstructors(
    params: {
      /**  */
      eventId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventInstructorListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{eventId}/instructors';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addInstructor(
    params: {
      /**  */
      eventId: number;
      /**  */
      instructorId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{eventId}/instructors/{instructorId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{instructorId}', params['instructorId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeInstructor(
    params: {
      /**  */
      eventId: number;
      /**  */
      instructorId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{eventId}/instructors/{instructorId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{instructorId}', params['instructorId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setInstructorAsPrimary(
    params: {
      /**  */
      eventId: number;
      /**  */
      instructorId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{eventId}/instructors/{instructorId}/set-primary';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{instructorId}', params['instructorId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventStudents(
    params: {
      /**  */
      eventId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetEventStudentsQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{eventId}/students';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addStudent(
    params: {
      /**  */
      eventId: number;
      /**  */
      customerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{eventId}/customers/{customerId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeStudent(
    params: {
      /**  */
      eventId: number;
      /**  */
      customerId: number;
      /**  */
      instructorNotes?: string;
      /**  */
      customerEventState?: CustomerEventState;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{eventId}/customers/{customerId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { instructorNotes: params['instructorNotes'], customerEventState: params['customerEventState'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static passAllCustomerEvents(
    params: {
      /**  */
      eventId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/{eventId}/pass-all-students';
      url = url.replace('{eventId}', params['eventId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static passCustomerEvent(
    params: {
      /** requestBody */
      body?: PassCustomerEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/events/pass-student';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static failCustomerEvent(
    params: {
      /**  */
      customerEventState?: CustomerEventState;
      /**  */
      customerEventId?: number;
      /** requestBody */
      body?: FailCustomerEventBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/events/fail-student';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { customerEventState: params['customerEventState'], customerEventId: params['customerEventId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitEventResults(
    params: {
      /** requestBody */
      body?: SubmitEventResultsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/events/submit-results';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static completeEvent(
    params: {
      /** requestBody */
      body?: CompleteEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/events/complete-event';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateCustomerCertification(
    params: {
      /** requestBody */
      body?: GenerateCertificationForStudentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/events/generate-certification';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportEventsExcelFile(
    params: {
      /** requestBody */
      body?: GetFilteredEventsForExportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportEventRosterPdfFile(
    params: {
      /** requestBody */
      body?: GetEventRosterExportPdfFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/events/export-roster/pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportEventRosterExcelFile(
    params: {
      /** requestBody */
      body?: GetEventRosterExportExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/events/export-roster/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static emailEventInstructors(
    params: {
      /** requestBody */
      body?: EmailEventInstructorsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/email-event-instructors';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static emailCustomerEvents(
    params: {
      /** requestBody */
      body?: EmailCustomerEventsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/email-event-students';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventsForSummary(
    params: {
      /** requestBody */
      body?: GetEventResultsSummaryQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetEventResultsSummaryQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/summary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static emailEventLink(
    params: {
      /** requestBody */
      body?: EmailEventLinkCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/email-event-link';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static emailCustomerCertificateToOwner(
    params: {
      /** requestBody */
      body?: EmailCustomerCertificateToOwnerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailCustomerCertificateToOwnerResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/email-customer-certificate-to-owner';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static emailInstructorCertificationToOwner(
    params: {
      /** requestBody */
      body?: EmailInstructorCertificationToOwnerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailInstructorCertificationToOwnerResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/events/email-instructor-certificate-to-owner';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EventTypeService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventTypeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-types/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventTypeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: EventTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: EventTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-types';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/event-types/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FeatureFlagService {
  /**
   *
   */
  static isFulfillmentProcessEnabled(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/feature-flags/is-fulfillment-enabled';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FilesService {
  /**
   *
   */
  static download(
    params: {
      /**  */
      uri?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/files/download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { uri: params['uri'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FulfillmentMethodsService {
  /**
   *
   */
  static getFulfillmentMethodTable(
    params: {
      /** requestBody */
      body?: GetFulfillmentMethodTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FulfillmentMethodListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/fulfillment-methods/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static fulfillmentMethodCreate(
    params: {
      /** requestBody */
      body?: CreateFulfillmentMethodCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/fulfillment-methods/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static fulfillmentMethodUpdate(
    params: {
      /** requestBody */
      body?: UpdateFulfillmentMethodCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/fulfillment-methods/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FulfillmentMethodListItemDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/fulfillment-methods/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIsEnabledByType(
    params: {
      /**  */
      type?: FulfillmentType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/fulfillment-methods/get-is-enabled';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type: params['type'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FundSplitReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetFundSplitDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundSplitListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/fund-split/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetFundSplitDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/fund-split/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class HardCardSalesReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetHardCardDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HardCardSalesListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/hard-card/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportHardCardSalesReportCsv(
    params: {
      /** requestBody */
      body?: GetHardCardDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/hard-card/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class HarvestSeasonService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: HarvestSeasonDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HarvestSeasonListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/harvest-seasons/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: HarvestSeasonCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/harvest-seasons';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: HarvestSeasonCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/harvest-seasons';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HarvestSeasonListItemDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/harvest-seasons/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class HipFileExportService {
  /**
   *
   */
  static exportHipFile(
    params: {
      /** requestBody */
      body?: GetHipFileExportTxtFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/hip-surveys/export/txt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class HuntService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HuntListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunts/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HuntCreateUpdateDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: HuntCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: HuntCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunts';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static fillHunt(
    params: {
      /** requestBody */
      body?: HuntFillRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunts/fill-hunt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class HuntLocationService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HuntLocationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunt-locations/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HuntLocationCreateUpdateDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunt-locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: HuntLocationCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunt-locations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: HuntLocationCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunt-locations';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/hunt-locations/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class InstructorsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: InstructorDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstructorListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorsToAddToEvent(
    params: {
      /**  */
      eventId?: number;
      /**  */
      searchText?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstructorListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { EventId: params['eventId'], SearchText: params['searchText'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateInstructorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstructorUserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateInstructorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventInstructorActivitiesForEventInstructor(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventInstructorActivitiesForEventInstructorDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/{id}/activities';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEventInstructorActivitiesForEventInstructor(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateEventInstructorActivitiesForEventInstructorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/{id}/update-activities';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static emailInstructors(
    params: {
      /** requestBody */
      body?: EmailInstructorsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/email-instructors';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadInstructorCertificationPdf(
    params: {
      /** requestBody */
      body?: GetInstructorCertificatePdfQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/download-instructor-certification-pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static emailInstructorCertificationPdf(
    params: {
      /** requestBody */
      body?: EmailInstructorCertificationToOwnerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailInstructorCertificationToOwnerResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/email-instructor-certification-pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetInstructorsExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorsForSummary(
    params: {
      /** requestBody */
      body?: GetInstructorsSummaryQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetInstructorsSummaryQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/summary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorsSummaryExcel(
    params: {
      /** requestBody */
      body?: GetInstructorsSummaryExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/summary/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorCertDateReportData(
    params: {
      /** requestBody */
      body?: GetInstructorCertDateReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetInstructorCertDateReportQueryResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/cert-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorCertDateReportExcel(
    params: {
      /** requestBody */
      body?: GetInstructorCertDateReportExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/cert-report/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangeInstructorPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructors/change-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class InstructorTypesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstructorTypeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructor-types/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstructorTypeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructor-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InstructorTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructor-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InstructorTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructor-types';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/instructor-types/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class IntegrationsService {
  /**
   *
   */
  static receiveWebhook(
    params: {
      /**  */
      webhookIdentifier?: string;
      /** requestBody */
      body?: ProcessCourseSystemWebhookRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/course-system-webhook';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { webhookIdentifier: params['webhookIdentifier'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class IspCustomersService {
  /**
   *
   */
  static getById(options: IRequestOptions = {}): Promise<CustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: IspCreateCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: IspUpdateCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCustomerMailingAddressById(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerMailingAddressDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/mailing-address';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIsBlackBearLicenseHolder(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/is-black-bear-license-holder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableOfPreviousAddresses(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: CustomerPreviousAddressesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerPreviousAddressDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/previous-addresses';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validateUpdateWithResidencyVerification(
    params: {
      /** requestBody */
      body?: IspValidateUpdateCustomerWithResidencyVerificationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/validate-update-with-residency-verification';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateWithResidencyVerification(
    params: {
      /** requestBody */
      body?: IspUpdateCustomerWithResidencyVerificationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/update-with-residency-verification';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSmsPreferences(
    params: {
      /** requestBody */
      body?: IspAndVendorUpdateSmsPreferencesCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/sms-preferences';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hasMilitaryIdNumber(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/has-military-id';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addMilitaryIdNumber(
    params: {
      /**  */
      militaryIdNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/military-id';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { militaryIdNumber: params['militaryIdNumber'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLoggedInUsersEmail(
    params: {
      /** requestBody */
      body?: UpdateLoggedInUsersEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/my-email';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isChatbotEnabled(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/is-chatbot-enabled';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static klaviyoSettings(options: IRequestOptions = {}): Promise<KlaviyoSettingsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/klaviyo-settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getKlaviyoProfilePropertiesByCustomerId(
    options: IRequestOptions = {}
  ): Promise<KlaviyoProfilePropertiesDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/klaviyo-profile-properties';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class JournalsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetJournalsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JournalListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/journals/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JournalDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/journals/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDistributionsTableById(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: GetJournalDistributionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JournalDistributionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/journals/{id}/distributions/table';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionsTableById(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: GetJournalTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JournalTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/journals/{id}/transactions/table';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addJournal(
    params: {
      /** requestBody */
      body?: AddJournalCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/journals';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class KlaviyoService {
  /**
   *
   */
  static getKlaviyoSettings(options: IRequestOptions = {}): Promise<KlaviyoSettingsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/klaviyo/settings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LifetimeLicensesReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetLifetimeLicenseDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LifetimeLicenesesDataTableDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/licenses/lifetime-licenses/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportLifetimeLicensesReport(
    params: {
      /** requestBody */
      body?: GetLifetimeLicenseDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/licenses/lifetime-licenses/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportPssLifetimeLicensesReports(
    params: {
      /** requestBody */
      body?: GetLifetimeLicenseDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileResultListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/licenses/lifetime-licenses/pss-export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LocationClassSalesReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetLocationClassSalesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationClassSalesListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/location-class-sales/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetLocationClassSalesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/location-class-sales/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRecentLeftoverNotificationJobStatus(
    params: {
      /**  */
      lotteryId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecentLeftoverNotificationJobStatusApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/lottery-details/{lotteryId}';
      url = url.replace('{lotteryId}', params['lotteryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: LotteryCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateLotteryResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: LotteryUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static copyLottery(
    params: {
      /** requestBody */
      body?: CopyLotteryFormDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/copy-lottery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static executeManualDraw(
    params: {
      /**  */
      lotteryId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/execute-manual-draw/{lotteryId}';
      url = url.replace('{lotteryId}', params['lotteryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryApplicantsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetLotteryApplicantTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicantListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-applicants/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicantListItemDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-applicants/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateCustomerApplicationStatusDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-applicants';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static disqualify(
    params: {
      /**  */
      lotteryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-applicants/disqualify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hasUnacceptedApplicants(
    params: {
      /**  */
      lotteryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-applicants/has-unaccepted-applicants';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendLotteryResultsConfirmationEmail(
    params: {
      /**  */
      lotteryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-applicants/send-lottery-results-confirmation-email';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllApplicationsForCustomerTable(
    params: {
      /**  */
      customerId?: number;
      /**  */
      activeOnly?: boolean;
      /** requestBody */
      body?: LotteryCustomerApplicationDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryCustomerApplicationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-applicants/customer-merge-application-list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'], activeOnly: params['activeOnly'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryApplicationService {
  /**
   *
   */
  static getHunts(
    params: {
      /**  */
      lotteryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotterySelectionDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-application/hunts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getApplicationHuntSelections(
    params: {
      /**  */
      lotteryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HuntSelectionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-application/hunt-choices';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateHuntChoices(
    params: {
      /** requestBody */
      body?: HuntSelectionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-application/update-hunt-choices';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateApplicationGroupCode(
    params: {
      /** requestBody */
      body?: UpdateApplicationGroupCodeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-application/update-group';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createApplication(
    params: {
      /** requestBody */
      body?: HuntSelectionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-application/create-application';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryAwardService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryAwardListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-awards/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryAwardCreateUpdateDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-awards/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: LotteryAwardCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-awards';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: LotteryAwardCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-awards';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-awards/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryCheckoutService {
  /**
   *
   */
  static get(options: IRequestOptions = {}): Promise<CheckoutInformationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-checkout';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionToken(
    params: {
      /** requestBody */
      body?: TransactionDetailsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElavonTransactionTokenResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-checkout/get-transaction-token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifyTransaction(
    params: {
      /** requestBody */
      body?: VerifyLotteryTransactionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateTransactionReturnDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-checkout/verify-transaction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifyNoFeeTransaction(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-checkout/verify-no-fee-transaction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static saveTransactionFailure(
    params: {
      /**  */
      transactionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-checkout/save-transaction-failure';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { transactionId: params['transactionId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSelectedDonation(
    params: {
      /**  */
      isReceiptView: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CurrentDonationsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-checkout/get-donation/{isReceiptView}';
      url = url.replace('{isReceiptView}', params['isReceiptView'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryCustomerApplicationService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: LotteryCustomerApplicationDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryCustomerApplicationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/lottery-customer-application/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDashboardItems(options: IRequestOptions = {}): Promise<CustomerDashboardLotteryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/lottery-customer-application/dashboard-items';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifyLeftoverLotteryCartItems(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/lottery-customer-application/verify-leftover-cart-items';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateAwardAcceptedStatus(
    params: {
      /**  */
      lotteryCustomerApplicationId?: number;
      /**  */
      wasAccepted?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/lottery-customer-application/update-award-accepted-status';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        lotteryCustomerApplicationId: params['lotteryCustomerApplicationId'],
        wasAccepted: params['wasAccepted']
      };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static declineAcceptedAward(
    params: {
      /**  */
      lotteryCustomerApplicationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/lottery-customer-application/decline-accepted-award';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { lotteryCustomerApplicationId: params['lotteryCustomerApplicationId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryDisplayCategoriesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryDisplayCategoryDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-species/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryDisplayCategoryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-species/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: LotteryDisplayCategoryDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-species';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: LotteryDisplayCategoryDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-species';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-species/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryEmailNotificationService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetEmailNotificationsTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryEmailNotificationTemplateListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryEmailNotificationCreateUpdateDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRecentLeftoverNotificationJobStatusForLotteryEmailNotification(
    params: {
      /**  */
      lotteryEmailNotificationTemplateId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecentLeftoverNotificationJobStatusApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        '/api/admin/lottery-email-notifications/lottery-email-notification-template-details/{lotteryEmailNotificationTemplateId}';
      url = url.replace('{lotteryEmailNotificationTemplateId}', params['lotteryEmailNotificationTemplateId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      id: number;
      /**  */
      name: string;
      /**  */
      subject: string;
      /**  */
      emailBody: string;
      /**  */
      actionTypes: [];
      /**  */
      huntLocationIds: [];
      /**  */
      years: [];
      /**  */
      lotteryIds: [];
      /**  */
      huntIds: [];
      /**  */
      applicationStatusIds: [];
      /**  */
      preferencePointTypeIds: [];
      /**  */
      selectRecipients: [];
      /**  */
      huntStartDate: string;
      /**  */
      huntEndDate: string;
      /**  */
      isEnabled: boolean;
      /**  */
      isSelectRecipientsOnly: boolean;
      /**  */
      sendWhen: NotificationSendingOptions;
      /**  */
      files: [];
      /**  */
      attachmentIds: [];
      /**  */
      attachmentNames: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['id']) {
        if (Object.prototype.toString.call(params['id']) === '[object Array]') {
          for (const item of params['id']) {
            data.append('Id', item as any);
          }
        } else {
          data.append('Id', params['id'] as any);
        }
      }

      if (params['name']) {
        if (Object.prototype.toString.call(params['name']) === '[object Array]') {
          for (const item of params['name']) {
            data.append('Name', item as any);
          }
        } else {
          data.append('Name', params['name'] as any);
        }
      }

      if (params['subject']) {
        if (Object.prototype.toString.call(params['subject']) === '[object Array]') {
          for (const item of params['subject']) {
            data.append('Subject', item as any);
          }
        } else {
          data.append('Subject', params['subject'] as any);
        }
      }

      if (params['emailBody']) {
        if (Object.prototype.toString.call(params['emailBody']) === '[object Array]') {
          for (const item of params['emailBody']) {
            data.append('EmailBody', item as any);
          }
        } else {
          data.append('EmailBody', params['emailBody'] as any);
        }
      }

      if (params['actionTypes']) {
        if (Object.prototype.toString.call(params['actionTypes']) === '[object Array]') {
          for (const item of params['actionTypes']) {
            data.append('ActionTypes', item as any);
          }
        } else {
          data.append('ActionTypes', params['actionTypes'] as any);
        }
      }

      if (params['huntLocationIds']) {
        if (Object.prototype.toString.call(params['huntLocationIds']) === '[object Array]') {
          for (const item of params['huntLocationIds']) {
            data.append('HuntLocationIds', item as any);
          }
        } else {
          data.append('HuntLocationIds', params['huntLocationIds'] as any);
        }
      }

      if (params['years']) {
        if (Object.prototype.toString.call(params['years']) === '[object Array]') {
          for (const item of params['years']) {
            data.append('Years', item as any);
          }
        } else {
          data.append('Years', params['years'] as any);
        }
      }

      if (params['lotteryIds']) {
        if (Object.prototype.toString.call(params['lotteryIds']) === '[object Array]') {
          for (const item of params['lotteryIds']) {
            data.append('LotteryIds', item as any);
          }
        } else {
          data.append('LotteryIds', params['lotteryIds'] as any);
        }
      }

      if (params['huntIds']) {
        if (Object.prototype.toString.call(params['huntIds']) === '[object Array]') {
          for (const item of params['huntIds']) {
            data.append('HuntIds', item as any);
          }
        } else {
          data.append('HuntIds', params['huntIds'] as any);
        }
      }

      if (params['applicationStatusIds']) {
        if (Object.prototype.toString.call(params['applicationStatusIds']) === '[object Array]') {
          for (const item of params['applicationStatusIds']) {
            data.append('ApplicationStatusIds', item as any);
          }
        } else {
          data.append('ApplicationStatusIds', params['applicationStatusIds'] as any);
        }
      }

      if (params['preferencePointTypeIds']) {
        if (Object.prototype.toString.call(params['preferencePointTypeIds']) === '[object Array]') {
          for (const item of params['preferencePointTypeIds']) {
            data.append('PreferencePointTypeIds', item as any);
          }
        } else {
          data.append('PreferencePointTypeIds', params['preferencePointTypeIds'] as any);
        }
      }

      if (params['selectRecipients']) {
        if (Object.prototype.toString.call(params['selectRecipients']) === '[object Array]') {
          for (const item of params['selectRecipients']) {
            data.append('SelectRecipients', item as any);
          }
        } else {
          data.append('SelectRecipients', params['selectRecipients'] as any);
        }
      }

      if (params['huntStartDate']) {
        if (Object.prototype.toString.call(params['huntStartDate']) === '[object Array]') {
          for (const item of params['huntStartDate']) {
            data.append('HuntStartDate', item as any);
          }
        } else {
          data.append('HuntStartDate', params['huntStartDate'] as any);
        }
      }

      if (params['huntEndDate']) {
        if (Object.prototype.toString.call(params['huntEndDate']) === '[object Array]') {
          for (const item of params['huntEndDate']) {
            data.append('HuntEndDate', item as any);
          }
        } else {
          data.append('HuntEndDate', params['huntEndDate'] as any);
        }
      }

      if (params['isEnabled']) {
        if (Object.prototype.toString.call(params['isEnabled']) === '[object Array]') {
          for (const item of params['isEnabled']) {
            data.append('IsEnabled', item as any);
          }
        } else {
          data.append('IsEnabled', params['isEnabled'] as any);
        }
      }

      if (params['isSelectRecipientsOnly']) {
        if (Object.prototype.toString.call(params['isSelectRecipientsOnly']) === '[object Array]') {
          for (const item of params['isSelectRecipientsOnly']) {
            data.append('IsSelectRecipientsOnly', item as any);
          }
        } else {
          data.append('IsSelectRecipientsOnly', params['isSelectRecipientsOnly'] as any);
        }
      }

      if (params['sendWhen']) {
        if (Object.prototype.toString.call(params['sendWhen']) === '[object Array]') {
          for (const item of params['sendWhen']) {
            data.append('SendWhen', item as any);
          }
        } else {
          data.append('SendWhen', params['sendWhen'] as any);
        }
      }

      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('Files', item as any);
          }
        } else {
          data.append('Files', params['files'] as any);
        }
      }

      if (params['attachmentIds']) {
        if (Object.prototype.toString.call(params['attachmentIds']) === '[object Array]') {
          for (const item of params['attachmentIds']) {
            data.append('AttachmentIds', item as any);
          }
        } else {
          data.append('AttachmentIds', params['attachmentIds'] as any);
        }
      }

      if (params['attachmentNames']) {
        if (Object.prototype.toString.call(params['attachmentNames']) === '[object Array]') {
          for (const item of params['attachmentNames']) {
            data.append('AttachmentNames', item as any);
          }
        } else {
          data.append('AttachmentNames', params['attachmentNames'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      name: string;
      /**  */
      subject: string;
      /**  */
      emailBody: string;
      /**  */
      actionTypes: [];
      /**  */
      huntLocationIds: [];
      /**  */
      years: [];
      /**  */
      lotteryIds: [];
      /**  */
      huntIds: [];
      /**  */
      applicationStatusIds: [];
      /**  */
      preferencePointTypeIds: [];
      /**  */
      selectRecipients: [];
      /**  */
      huntStartDate: string;
      /**  */
      huntEndDate: string;
      /**  */
      isEnabled: boolean;
      /**  */
      isSelectRecipientsOnly: boolean;
      /**  */
      sendWhen: NotificationSendingOptions;
      /**  */
      files: [];
      /**  */
      attachmentIds: [];
      /**  */
      attachmentNames: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications';

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['id']) {
        if (Object.prototype.toString.call(params['id']) === '[object Array]') {
          for (const item of params['id']) {
            data.append('Id', item as any);
          }
        } else {
          data.append('Id', params['id'] as any);
        }
      }

      if (params['name']) {
        if (Object.prototype.toString.call(params['name']) === '[object Array]') {
          for (const item of params['name']) {
            data.append('Name', item as any);
          }
        } else {
          data.append('Name', params['name'] as any);
        }
      }

      if (params['subject']) {
        if (Object.prototype.toString.call(params['subject']) === '[object Array]') {
          for (const item of params['subject']) {
            data.append('Subject', item as any);
          }
        } else {
          data.append('Subject', params['subject'] as any);
        }
      }

      if (params['emailBody']) {
        if (Object.prototype.toString.call(params['emailBody']) === '[object Array]') {
          for (const item of params['emailBody']) {
            data.append('EmailBody', item as any);
          }
        } else {
          data.append('EmailBody', params['emailBody'] as any);
        }
      }

      if (params['actionTypes']) {
        if (Object.prototype.toString.call(params['actionTypes']) === '[object Array]') {
          for (const item of params['actionTypes']) {
            data.append('ActionTypes', item as any);
          }
        } else {
          data.append('ActionTypes', params['actionTypes'] as any);
        }
      }

      if (params['huntLocationIds']) {
        if (Object.prototype.toString.call(params['huntLocationIds']) === '[object Array]') {
          for (const item of params['huntLocationIds']) {
            data.append('HuntLocationIds', item as any);
          }
        } else {
          data.append('HuntLocationIds', params['huntLocationIds'] as any);
        }
      }

      if (params['years']) {
        if (Object.prototype.toString.call(params['years']) === '[object Array]') {
          for (const item of params['years']) {
            data.append('Years', item as any);
          }
        } else {
          data.append('Years', params['years'] as any);
        }
      }

      if (params['lotteryIds']) {
        if (Object.prototype.toString.call(params['lotteryIds']) === '[object Array]') {
          for (const item of params['lotteryIds']) {
            data.append('LotteryIds', item as any);
          }
        } else {
          data.append('LotteryIds', params['lotteryIds'] as any);
        }
      }

      if (params['huntIds']) {
        if (Object.prototype.toString.call(params['huntIds']) === '[object Array]') {
          for (const item of params['huntIds']) {
            data.append('HuntIds', item as any);
          }
        } else {
          data.append('HuntIds', params['huntIds'] as any);
        }
      }

      if (params['applicationStatusIds']) {
        if (Object.prototype.toString.call(params['applicationStatusIds']) === '[object Array]') {
          for (const item of params['applicationStatusIds']) {
            data.append('ApplicationStatusIds', item as any);
          }
        } else {
          data.append('ApplicationStatusIds', params['applicationStatusIds'] as any);
        }
      }

      if (params['preferencePointTypeIds']) {
        if (Object.prototype.toString.call(params['preferencePointTypeIds']) === '[object Array]') {
          for (const item of params['preferencePointTypeIds']) {
            data.append('PreferencePointTypeIds', item as any);
          }
        } else {
          data.append('PreferencePointTypeIds', params['preferencePointTypeIds'] as any);
        }
      }

      if (params['selectRecipients']) {
        if (Object.prototype.toString.call(params['selectRecipients']) === '[object Array]') {
          for (const item of params['selectRecipients']) {
            data.append('SelectRecipients', item as any);
          }
        } else {
          data.append('SelectRecipients', params['selectRecipients'] as any);
        }
      }

      if (params['huntStartDate']) {
        if (Object.prototype.toString.call(params['huntStartDate']) === '[object Array]') {
          for (const item of params['huntStartDate']) {
            data.append('HuntStartDate', item as any);
          }
        } else {
          data.append('HuntStartDate', params['huntStartDate'] as any);
        }
      }

      if (params['huntEndDate']) {
        if (Object.prototype.toString.call(params['huntEndDate']) === '[object Array]') {
          for (const item of params['huntEndDate']) {
            data.append('HuntEndDate', item as any);
          }
        } else {
          data.append('HuntEndDate', params['huntEndDate'] as any);
        }
      }

      if (params['isEnabled']) {
        if (Object.prototype.toString.call(params['isEnabled']) === '[object Array]') {
          for (const item of params['isEnabled']) {
            data.append('IsEnabled', item as any);
          }
        } else {
          data.append('IsEnabled', params['isEnabled'] as any);
        }
      }

      if (params['isSelectRecipientsOnly']) {
        if (Object.prototype.toString.call(params['isSelectRecipientsOnly']) === '[object Array]') {
          for (const item of params['isSelectRecipientsOnly']) {
            data.append('IsSelectRecipientsOnly', item as any);
          }
        } else {
          data.append('IsSelectRecipientsOnly', params['isSelectRecipientsOnly'] as any);
        }
      }

      if (params['sendWhen']) {
        if (Object.prototype.toString.call(params['sendWhen']) === '[object Array]') {
          for (const item of params['sendWhen']) {
            data.append('SendWhen', item as any);
          }
        } else {
          data.append('SendWhen', params['sendWhen'] as any);
        }
      }

      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('Files', item as any);
          }
        } else {
          data.append('Files', params['files'] as any);
        }
      }

      if (params['attachmentIds']) {
        if (Object.prototype.toString.call(params['attachmentIds']) === '[object Array]') {
          for (const item of params['attachmentIds']) {
            data.append('AttachmentIds', item as any);
          }
        } else {
          data.append('AttachmentIds', params['attachmentIds'] as any);
        }
      }

      if (params['attachmentNames']) {
        if (Object.prototype.toString.call(params['attachmentNames']) === '[object Array]') {
          for (const item of params['attachmentNames']) {
            data.append('AttachmentNames', item as any);
          }
        } else {
          data.append('AttachmentNames', params['attachmentNames'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEnabled(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications/update-enabled/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendOnce(
    params: {
      /**  */
      notificationTemplateId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications/send-once';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { notificationTemplateId: params['notificationTemplateId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSelectRecipientsToAddToSendOnce(
    params: {
      /** requestBody */
      body?: LotteryEmailNotificationCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryNotificationOnlyOnceSelectRecipientDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications/get-send-once-select-recipients';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static previewNotificationRecipients(
    params: {
      /** requestBody */
      body?: LotteryEmailNotificationCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32ApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications/preview-notification-recipients';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static previewNotificationBody(
    params: {
      /** requestBody */
      body?: LotteryEmailNotificationCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications/preview-notification-body';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-email-notifications/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryFeeService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryFeeListDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-fees/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryFeeListDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-fees/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateLotteryFeeCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-fees';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateLotteryFeeCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-fees';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-fees/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryFeeDistributionService {
  /**
   *
   */
  static getByLotteryFeeId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryFeeDistributionListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-fee-distributions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryNotificationHistoryService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: GetLotteryEmailNotificationsForCustomerTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryEmailNotificationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-notification-history/table/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resendNotification(
    params: {
      /**  */
      lotteryNotificationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-notification-history';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { lotteryNotificationId: params['lotteryNotificationId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryReceiptService {
  /**
   *
   */
  static get(options: IRequestOptions = {}): Promise<CheckoutInformationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-receipt';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static subscribeToSmsUpdates(
    params: {
      /** requestBody */
      body?: SubscribeToLotterySMSUpdatesCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-receipt/subscribe-to-sms-updates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryReportsService {
  /**
   *
   */
  static getLotteryApplicantsReport(
    params: {
      /** requestBody */
      body?: GetLotteryApplicantTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/reports/table/lottery-applicants';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryResultsReport(
    params: {
      /** requestBody */
      body?: GetLotteryApplicantTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/reports/table/lottery-results';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryAnalysisReport(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/reports/table/lottery-analysis';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryFieldReport(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/reports/table/lottery-field';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryFieldReportById(
    params: {
      /** requestBody */
      body?: GetSingleHuntFieldReportFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lotteries/reports/table/lottery-field-hunt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryShoppingCartService {
  /**
   *
   */
  static getShoppingCart(options: IRequestOptions = {}): Promise<ShoppingCartDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/shopping-cart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDisplayLotteries(
    params: {
      /**  */
      speciesId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DisplayLotteriesDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/display-lotteries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { speciesId: params['speciesId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addAward(
    params: {
      /**  */
      lotteryCustomerApplicationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/add-award';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { lotteryCustomerApplicationId: params['lotteryCustomerApplicationId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addAwardAcceptanceLink(
    params: {
      /**  */
      lotteryCustomerApplicationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/add-award-acceptance-link';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { lotteryCustomerApplicationId: params['lotteryCustomerApplicationId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addDonation(
    params: {
      /** requestBody */
      body?: AddDonationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/add-donation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeItem(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/remove-item';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGroup(
    params: {
      /**  */
      lotteryId?: number;
      /**  */
      code?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GroupApplicationDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/group';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'], code: params['code'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addGroupApplication(
    params: {
      /**  */
      lotteryId?: number;
      /**  */
      code?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/add-group-application';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'], code: params['code'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLeftoverApplicationCartTimer(options: IRequestOptions = {}): Promise<Int32ApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/leftover-application-cart-timer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isCartExpired(
    params: {
      /**  */
      shoppingCartId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/lottery-shopping-cart/check-expiration';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { shoppingCartId: params['shoppingCartId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class LotterySmsNotificationService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotterySMSNotificationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-sms-notifications/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotterySMSNotificationEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-sms-notifications/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRecentLeftoverNotificationJobStatusForLotterySmsNotification(
    params: {
      /**  */
      lotterySmsNotificationTemplateId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecentLeftoverNotificationJobStatusApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        '/api/admin/lottery-sms-notifications/lottery-sms-notification-template-details/{lotterySmsNotificationTemplateId}';
      url = url.replace('{lotterySmsNotificationTemplateId}', params['lotterySmsNotificationTemplateId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: LotterySMSNotificationEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-sms-notifications';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/lottery-sms-notifications/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LotteryTransactionsReportService {
  /**
   *
   */
  static getOptions(options: IRequestOptions = {}): Promise<LotteryTransactionsReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/lottery-transactions/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetails(
    params: {
      /** requestBody */
      body?: GetLotteryTransactionSumsByAchBatchQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetLotteryTransactionSumsByBatchResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/lottery-transactions/details';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetLotteryTransactionsByAchBatchDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/lottery-transactions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetLotteryTransactionsByAchBatchDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/lottery-transactions/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MetadataService {
  /**
   *
   */
  static getDeploymentInformation(options: IRequestOptions = {}): Promise<DeploymentInfoSettingsApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/metadata/deployment-info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MobileAccountService {
  /**
   *
   */
  static customerLookupWithSsn(
    params: {
      /** requestBody */
      body?: CustomerAuthWithSsnDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MobileCustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/account/customer-lookup-with-ssn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customerLoginWithAlternateId(
    params: {
      /** requestBody */
      body?: CustomerAuthWithAlternateIdDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MobileCustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/account/customer-lookup-with-alternate-id';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customerLoginWithCustomerNumber(
    params: {
      /** requestBody */
      body?: CustomerAuthWithCustomerNumberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MobileCustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/account/customer-lookup-with-customer-number';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customerLoginWithDriversLicense(
    params: {
      /** requestBody */
      body?: CustomerAuthWithDriversLicenseDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MobileCustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/account/customer-lookup-with-drivers-license';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateLoginLink(
    params: {
      /** requestBody */
      body?: CreateMobileLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/account/login-link';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateProductCatalogTagsViewLoginLink(
    params: {
      /** requestBody */
      body?: CreateMobileProductCatalogTagsViewLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/account/product-catalog-tags-login-link';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static saveCustomerDevice(
    params: {
      /** requestBody */
      body?: CreateUpdateCustomerDeviceDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/account/save-device';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requestAccountDeletion(
    params: {
      /** requestBody */
      body?: RequestAccountDeletionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/account/request-account-deletion';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MobileCustomerService {
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: MobileIspUpdateCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/customer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MobileHarvestService {
  /**
   *
   */
  static getAllForCustomer(options: IRequestOptions = {}): Promise<MobileTransactionTagListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/harvest/get-all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static post(
    params: {
      /** requestBody */
      body?: MobileCreateHarvestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/harvest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MobileOptionsService {
  /**
   *
   */
  static getHarvestOptions(options: IRequestOptions = {}): Promise<MobileHarvestOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/options/harvest';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNotificationSettings(options: IRequestOptions = {}): Promise<MobileNotificationSettingsApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile/options/notification-settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MonthlyAchReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetMonthlyAchDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MonthlyAchListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/monthly-ach/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetMonthlyAchDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/monthly-ach/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MySalesReportService {
  /**
   *
   */
  static getOptions(options: IRequestOptions = {}): Promise<MySalesReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/vendors/my-sales/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTenderTypeSalesTable(
    params: {
      /** requestBody */
      body?: GetVendorReportClerkRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenderTypeSalesListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/vendors/my-sales/tender-type/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLicensesSalesByDateTable(
    params: {
      /** requestBody */
      body?: GetVendorReportClerkRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LicenseSalesByDateListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/vendors/my-sales/license-sales-by-date/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionRecordsDateTable(
    params: {
      /** requestBody */
      body?: GetVendorReportClerkRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionRecordListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/vendors/my-sales/transaction-details/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class NonAchReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetNonAchDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonAchListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/non-ach/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetNonAchDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/non-ach/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class NonEftSalesRecService {
  /**
   *
   */
  static getTenderTable(
    params: {
      /** requestBody */
      body?: GetNonEftSalesRecDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonEftSalesRecTenderListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/non-eft-sales-rec/tender/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPivTable(
    params: {
      /** requestBody */
      body?: GetNonEftSalesRecDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonEftSalesRecPivListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/non-eft-sales-rec/piv/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFundTable(
    params: {
      /** requestBody */
      body?: GetNonEftSalesRecDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonEftSalesRecFundListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/non-eft-sales-rec/fund/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionDetailsTable(
    params: {
      /** requestBody */
      body?: GetNonEftSalesRecDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonEftSalesRecTransactionDetailListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/non-eft-sales-rec/transaction-detail/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportNonEftSalesRecPdfFile(
    params: {
      /** requestBody */
      body?: GetNonEftSalesRecDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/non-eft-sales-rec/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class OptionsService {
  /**
   *
   */
  static getHarvestOptions(options: IRequestOptions = {}): Promise<HarvestOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/options/harvests';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOptions(options: IRequestOptions = {}): Promise<FormOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEnums(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/enums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCustomerOptions(options: IRequestOptions = {}): Promise<FormOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/customer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorOptions(options: IRequestOptions = {}): Promise<VendorOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/vendor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCorporationOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CorporationOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/corporation/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRevocationOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RevocationOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/revocations';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductOptions(options: IRequestOptions = {}): Promise<ProductOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDocumentTypeOptions(options: IRequestOptions = {}): Promise<DocumentTypeOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/document-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductRuleOptions(
    params: {
      /**  */
      productId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRuleOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/product-rules';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { productId: params['productId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductFeeOptions(options: IRequestOptions = {}): Promise<ProductFeeOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/product-fees';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductTagOptions(options: IRequestOptions = {}): Promise<ProductTagOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/product-tags';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFilteredEventOptions(options: IRequestOptions = {}): Promise<EventOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/filtered-events';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventOptions(options: IRequestOptions = {}): Promise<EventOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/events';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassEventTypeOptions(options: IRequestOptions = {}): Promise<ClassEventTypeOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/class-event-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventSummaryOptions(options: IRequestOptions = {}): Promise<EventSummaryOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/events-summary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorSummaryOptions(options: IRequestOptions = {}): Promise<InstructorSummaryOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/instructors-summary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParticipantsReportOptions(options: IRequestOptions = {}): Promise<ParticipantsReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/participants-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorCertReportOptions(
    options: IRequestOptions = {}
  ): Promise<InstructorCertReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/instructors-certification-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getImportedCertificationsReportOptions(
    options: IRequestOptions = {}
  ): Promise<ImportedCertificationsReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/imported-certifications-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCertificationsReportOptions(
    options: IRequestOptions = {}
  ): Promise<CertificationsReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/certifications-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAddressOptions(options: IRequestOptions = {}): Promise<AddressOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/address';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryDistributionFeeOptions(
    options: IRequestOptions = {}
  ): Promise<LotteryFeeDistributionOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/lottery-distribution-fees';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryOptions(
    params: {
      /**  */
      lotteryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/lottery-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryApplicationOptions(
    params: {
      /**  */
      lotteryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LotteryApplicationOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/lottery-application-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getJournalOptions(options: IRequestOptions = {}): Promise<JournalOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/journals';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAddJournalOptions(options: IRequestOptions = {}): Promise<AddJournalOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/add-journal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatementOptions(options: IRequestOptions = {}): Promise<StatementOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/statements';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventInstructorActivityTypeOptions(
    options: IRequestOptions = {}
  ): Promise<GetEventInstructorActivityOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/event-user-activity-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEmailNotificationOptions(
    params: {
      /**  */
      lotteryIds?: any | null[];
      /**  */
      lotteryYears?: any | null[];
      /**  */
      preferencePointTypeIds?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailNotificationOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/email-notification-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        lotteryIds: params['lotteryIds'],
        lotteryYears: params['lotteryYears'],
        preferencePointTypeIds: params['preferencePointTypeIds']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLotteryPreferencePointTypeOptions(
    options: IRequestOptions = {}
  ): Promise<LotteryPreferencePointTypeOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/lottery-preference-point-type-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCourseSystemEnrollmentOptions(
    options: IRequestOptions = {}
  ): Promise<CourseSystemEnrollmentOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/course-system-enrollment-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructorExpirationOptions(
    options: IRequestOptions = {}
  ): Promise<InstructorExpirationOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/instructor-expiration-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OrganDonorRegistryService {
  /**
   *
   */
  static isEnabled(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/organ-donor-registry/is-enabled';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PreferencePointsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: CustomerPreferencePointsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerPreferencePointListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/preference-points/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPreferencePointsForCustomerByType(
    params: {
      /**  */
      preferencePointTypeId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32ApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/preference-points/get/{preferencePointTypeId}';
      url = url.replace('{preferencePointTypeId}', params['preferencePointTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllPreferencePointsForCustomer(
    options: IRequestOptions = {}
  ): Promise<PreferencePointIndividualTotalsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/preference-points/get-all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBeforeAndAfterByType(
    params: {
      /**  */
      lotteryId?: number;
      /**  */
      preferencePointTypeId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerPreferencePointsBeforeAndAfterDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/preference-points/get-before-and-after';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lotteryId: params['lotteryId'], preferencePointTypeId: params['preferencePointTypeId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PreferencePointTypeService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PreferencePointTypeDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/preference-point-types/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PreferencePointTypeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/preference-point-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: PreferencePointTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/preference-point-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: PreferencePointTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/preference-point-types';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PrivilegeFeeReportService {
  /**
   *
   */
  static getExcelFile(
    params: {
      /** requestBody */
      body?: GetPrivilegeFeeReportExportExcelFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/privilege-fee/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProductFeesService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      productId?: number;
      /** requestBody */
      body?: ProductFeeDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductFeeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-fees/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { productId: params['productId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductFeeCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-fees';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProductImagesService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      productId?: number;
      /** requestBody */
      body?: ProductImageDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductImageDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-images/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { productId: params['productId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductImageCreateUpdateDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-images/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static archive(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-images/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      id?: number;
      /**  */
      productId?: number;
      /**  */
      imageCode?: string;
      /**  */
      imageUri?: string;
      /**  */
      displayOrder?: number;
      /**  */
      isArchived?: boolean;
      /**  */
      file: any;
      /**  */
      image: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-images';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        Id: params['id'],
        ProductId: params['productId'],
        ImageCode: params['imageCode'],
        ImageUri: params['imageUri'],
        DisplayOrder: params['displayOrder'],
        IsArchived: params['isArchived']
      };

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      if (params['image']) {
        if (Object.prototype.toString.call(params['image']) === '[object Array]') {
          for (const item of params['image']) {
            data.append('Image', item as any);
          }
        } else {
          data.append('Image', params['image'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateDisplayOrder(
    params: {
      /** requestBody */
      body?: UpdateProductImageDisplayOrderDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-images';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProductRulesService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      productId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRuleDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-rules/get-all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { productId: params['productId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductRuleCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-rules';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProductsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: ProductDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/products/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/products/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/products/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/products';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/products';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportProductsExcelFile(
    params: {
      /** requestBody */
      body?: ProductDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/products/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIsLicense365Enabled(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/products/license-365-enabled';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProductSalesVolumeReportService {
  /**
   *
   */
  static getOptions(options: IRequestOptions = {}): Promise<ProductSalesVolumeReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/product-sales-volume/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetProductSalesVolumeDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSalesVolumeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/product-sales-volume/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetProductSalesVolumeDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/product-sales-volume/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProductTagsService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      productId?: number;
      /** requestBody */
      body?: ProductTagDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductTagDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-tags/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { productId: params['productId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductTagDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-tags/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-tags/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductTagCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-tags';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductTagCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/product-tags';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class RevocationsService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: RevocationDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RevocationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocations/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RevocationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: RevocationCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: RevocationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocations';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class RevocationTypesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RevocationTypeDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocation-types/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RevocationTypeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocation-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: RevocationTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocation-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: RevocationTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocation-types';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/revocation-types/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class RolesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/roles/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/roles/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateRoleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/roles';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateRoleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/roles';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/roles/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class RolpService {
  /**
   *
   */
  static lookupCustomerRolpLicenses(
    params: {
      /** requestBody */
      body?: RolpCustomerLicenseSearchRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RolpCustomerLicenseSearchResponseListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/rolp/customer-rolp-license-lookup';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SeasonalHarvestReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetSeasonalHarvestDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HarvestExportDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/harvests/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportHarvestsPdfFile(
    params: {
      /** requestBody */
      body?: GetSeasonalHarvestDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/harvests/table/export/pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getSettings(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSettingById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSetting(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSettingResetValue(options: IRequestOptions = {}): Promise<BooleanNullableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/setting-reset';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static toggleSettingReset(
    params: {
      /**  */
      value?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/toggle-setting-reset';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { value: params['value'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SmsTaggingService {
  /**
   *
   */
  static enrollToSmsTagging(
    params: {
      /**  */
      phoneNumber?: string;
      /**  */
      enabled?: boolean;
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/sms-tagging/enroll-sms-tagging';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        phoneNumber: params['phoneNumber'],
        enabled: params['enabled'],
        customerId: params['customerId']
      };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmationMessage(
    params: {
      /**  */
      phoneNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/sms-tagging/send-enrollment-confirmation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { phoneNumber: params['phoneNumber'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class StatementsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetStatementsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatementListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/statements/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatementDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/statements/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getJournalsTableById(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: GetStatementJournalsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatementJournalListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/statements/{id}/journals/table';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportStatementPdfFile(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/statements/statement-export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class StatewideSalesDetailsReportService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetStatewideSalesDetailsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatewideSalesDetailsListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/statewide-sales-details/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetStatewideSalesDetailsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/statewide-sales-details/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static getStatusAsync(options: IRequestOptions = {}): Promise<StatusResultListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class StudentsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: CustomerDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/students/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class TransactionCustomerService {
  /**
   *
   */
  static getAllForCustomer(
    params: {
      /**  */
      customerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionCustomerProductListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/customers/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createFreeTransaction(
    params: {
      /** requestBody */
      body?: CreateTransactionForCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateTransactionReturnDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/create-free-transaction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionToken(
    params: {
      /** requestBody */
      body?: TransactionDetailsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElavonTransactionTokenResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/get-transaction-token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTokenizationTransactionToken(
    options: IRequestOptions = {}
  ): Promise<ElavonTransactionTokenResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/get-tokenization-transaction-token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifyTransaction(
    params: {
      /** requestBody */
      body?: VerifyProductTransactionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateTransactionReturnDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/verify-transaction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static saveTransactionFailure(
    params: {
      /**  */
      transactionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/save-transaction-failure';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { transactionId: params['transactionId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resendTransactionReceiptEmail(
    params: {
      /** requestBody */
      body?: SendTransactionReceiptEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/resend-transaction-receipt-email';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resendLotteryTransactionReceiptEmail(
    params: {
      /** requestBody */
      body?: ResendLotteryTransactionReceiptEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/resend-lottery-receipt-email';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEmailAddress(
    params: {
      /**  */
      emailAddress?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/update-email-address';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { emailAddress: params['emailAddress'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateMailingAddressForTransaction(
    params: {
      /** requestBody */
      body?: UpdateMailingAddressForTransactionFulfillmentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/update-mailing-address-for-transaction';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCheckoutInfo(options: IRequestOptions = {}): Promise<CheckoutInformationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportTransactionReceiptPdfFile(
    params: {
      /** requestBody */
      body?: TransactionReceiptDetails;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/transaction-receipt/pdf/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportTransactionCustomerLicensePdfFile(
    params: {
      /** requestBody */
      body?: GetTransactionCustomerLicenseExportPdfQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/license/pdf/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportCustomerLicensePdfFile(
    params: {
      /** requestBody */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/duplicate-license/pdf/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inlineTransactionCustomerLicensePdfFile(
    params: {
      /**  */
      transactionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/license/pdf/customer-licenses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { transactionId: params['transactionId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inlineCustomerLicensePdfFile(
    params: {
      /**  */
      tcpIds?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/duplicate-license/pdf/customer-licenses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tcpIds: params['tcpIds'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getConvenienceFee(options: IRequestOptions = {}): Promise<DecimalApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-portal/transaction/get-convenience-fee';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class TransactionFulfillmentsService {
  /**
   *
   */
  static getTransactionFulfillmentTable(
    params: {
      /** requestBody */
      body?: GetTransactionFulfillmentTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionFulfillmentListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transaction-fulfillments/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportTransactionFulfillmentTable(
    params: {
      /** requestBody */
      body?: GetTransactionFulfillmentTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transaction-fulfillments/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class TransactionsService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: GetTransactionDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionCustomerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transactions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PastTransactionDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transactions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static voidTransaction(
    params: {
      /** requestBody */
      body?: VoidTransactionForAdminCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transactions/void';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class TransactionsForCustomerService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: GetTransactionCustomerDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionCustomerProductListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/transactions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetTransactionCustomerProductByIdDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/transactions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPssLifetimeLicenseTransactionFulfillment(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/transactions/has-pss-lifetime-transaction-fulfillment/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateTransactionCustomerProductStatusCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/transactions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateTripStartDate(
    params: {
      /** requestBody */
      body?: UpdateTransactionCustomerProductTripDatesCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/transactions';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateMailingAddressForTransaction(
    params: {
      /** requestBody */
      body?: UpdateMailingAddressForTransactionFulfillmentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/transactions/update-mailing-address-for-transaction';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setActivateOrReprint(
    params: {
      /** requestBody */
      body?: ActivateOrReprintCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/transactions/toggle-license-reprint';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllForCustomer(
    params: {
      /**  */
      customerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionCustomerProductListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/customers/transactions/customer/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class TwilioFlexService {
  /**
   *
   */
  static getHarvestCustomersByPhoneNumber(
    params: {
      /**  */
      phoneNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HarvestCustomerListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio-flex/get-customers-by-phone-number';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { phoneNumber: params['phoneNumber'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class TwilioSmsService {
  /**
   *
   */
  static unsubscribeFromSmsNotifications(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio/unsubscribe-from-notification';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static textToTagIsEnabled(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio/text-to-tag/is-enabled';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmTextToTagEnrollment(
    params: {
      /**  */
      senderNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerTextToTagInfoDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio/text-to-tag/confirm-text-to-tag-enrollment/{senderNumber}';
      url = url.replace('{senderNumber}', params['senderNumber'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static beginTextToTag(
    params: {
      /**  */
      senderNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerTextToTagInfoDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio/text-to-tag/confirm-customer/{senderNumber}';
      url = url.replace('{senderNumber}', params['senderNumber'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTagsForCustomer(
    params: {
      /**  */
      customerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TagsInTextListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio/text-to-tag/get-tags-for-customer/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTagByIndex(
    params: {
      /**  */
      customerId?: number;
      /**  */
      tagIndex?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TagsInTextListItemDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio/text-to-tag/get-tag-by-index';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'], tagIndex: params['tagIndex'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDeerAreas(
    params: {
      /**  */
      parishName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeerAreaTextToTagListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio/text-to-tag/get-deer-areas';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { parishName: params['parishName'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitTagInfo(
    params: {
      /** requestBody */
      body?: CollectedTextToTagInfoDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TagsInTextListItemDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio/text-to-tag/submit-tag-info';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateLoginLink(
    params: {
      /** requestBody */
      body?: CreateLoginViewTokenCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/twilio/text-to-tag/get-one-time-log-in-link';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangeUserPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/change-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/roleOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendActivationEmail(
    params: {
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/send-activation-email';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorCatalogService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorCatalogOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/catalog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getShoppingCart(
    params: {
      /**  */
      customerId?: number;
      /**  */
      hasCreditCardFee?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShoppingCartDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/catalog/shopping-cart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'], hasCreditCardFee: params['hasCreditCardFee'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addProductAncillaryInfo(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: ProductAncillaryInfoDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/catalog/add-ancillary-info';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addShoppingCartItem(
    params: {
      /**  */
      customerId?: number;
      /** requestBody */
      body?: ShoppingCartProductDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/catalog/add-item';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeItem(
    params: {
      /**  */
      productId?: number;
      /**  */
      customerId?: number;
      /**  */
      replacedTransactionCustomerProductId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/catalog/remove-item';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = {
        productId: params['productId'],
        customerId: params['customerId'],
        replacedTransactionCustomerProductId: params['replacedTransactionCustomerProductId']
      };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clearCart(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/catalog/clear-cart';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addDonation(
    params: {
      /** requestBody */
      body?: AddDonationAsVendorDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/catalog/add-donation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSelectedDonation(
    params: {
      /**  */
      isReceiptView: boolean;
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CurrentDonationsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/catalog/get-donation/{isReceiptView}';
      url = url.replace('{isReceiptView}', params['isReceiptView'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductImages(
    params: {
      /**  */
      productId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogProductImageDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/catalog/get-product-images';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { productId: params['productId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class VendorCommentsService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      vendorId?: number;
      /** requestBody */
      body?: CommentDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommentDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-comments/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { vendorId: params['vendorId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommentDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-comments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-comments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CommentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-comments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CommentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-comments';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorCustomerService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: VendorCreateCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: VendorUpdateCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validateUpdateWithResidencyVerification(
    params: {
      /** requestBody */
      body?: VendorValidateUpdateCustomerWithResidencyVerificationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer/validate-update-with-residency-verification';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateWithResidencyVerification(
    params: {
      /** requestBody */
      body?: VendorUpdateCustomerWithResidencyVerificationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer/update-with-residency-verification';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verify(
    params: {
      /** requestBody */
      body?: VendorUpdateCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer/verify';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hasMilitaryIdNumber(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer/has-military-id';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addMilitaryIdNumber(
    params: {
      /**  */
      militaryIdNumber?: string;
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer/military-id';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { militaryIdNumber: params['militaryIdNumber'], customerId: params['customerId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEmailAddress(
    params: {
      /**  */
      emailAddress?: string;
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer/update-email-address';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { emailAddress: params['emailAddress'], customerId: params['customerId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllCustomerTransactions(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssueDuplicateDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer/all-customer-transactions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class VendorFundSplitReportService {
  /**
   *
   */
  static getOptions(options: IRequestOptions = {}): Promise<VendorFundSplitReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/vendor-fund-split/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetVendorFundSplitDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorFundSplitListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/vendor-fund-split/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetVendorFundSplitDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/vendor-fund-split/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorJournalsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetJournalsByVendorDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorJournalListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendors/journals/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorNotificationsService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      vendorId?: number;
      /** requestBody */
      body?: VendorNotificationsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorNotificationDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-notifications/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { vendorId: params['vendorId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorNotificationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-notifications/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-notifications/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: VendorNotificationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-notifications';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /** requestBody */
      body?: VendorNotificationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendor-notifications';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorOptionsService {
  /**
   *
   */
  static getVendorUserOptions(options: IRequestOptions = {}): Promise<VendorUserOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/vendor-user';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorStatementOptions(
    params: {
      /**  */
      vendorId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorStatementOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/options/vendor-statement';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { vendorId: params['vendorId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCorporateReportOptions(options: IRequestOptions = {}): Promise<CorporateReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vendor-corporate-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class VendorReportsService {
  /**
   *
   */
  static getCorporateReport(
    params: {
      /** requestBody */
      body?: GetCorporationReportDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetCorporationReportDataTableResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/reports/corporate-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCorporateReportExcel(
    params: {
      /** requestBody */
      body?: GetCorporationReportDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/reports/corporate-report/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportStatementPdfFile(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/reports/statement-export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendors/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDashboard(
    params: {
      /**  */
      vendorId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorDashboardDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendors/dashboard/{vendorId}';
      url = url.replace('{vendorId}', params['vendorId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendors/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendors/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: VendorDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendors';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: VendorDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendors';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportVendorsExcelFile(
    params: {
      /** requestBody */
      body?: VendorDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendors/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditTable(
    params: {
      /** requestBody */
      body?: AuditDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditListingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/vendors/audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorSalesService {
  /**
   *
   */
  static getSummariesTable(
    params: {
      /** requestBody */
      body?: GetVendorSaleDailySummariesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorSaleDaySummaryListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/summaries/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportSummariesCsvFile(
    params: {
      /** requestBody */
      body?: GetVendorSaleDailySummariesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/summaries/table/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSalesTable(
    params: {
      /** requestBody */
      body?: GetVendorSalesByDateDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorSaleListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorSalesReportService {
  /**
   *
   */
  static getTenderTypeSalesTable(
    params: {
      /** requestBody */
      body?: GetVendorReportClerkRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenderTypeSalesListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/vendors/vendor-sales/tender-type/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLicenseTypeSalesTable(
    params: {
      /** requestBody */
      body?: GetVendorReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LicenseTypeSalesListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/vendors/vendor-sales/license-type/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionDetailsTable(
    params: {
      /** requestBody */
      body?: GetVendorReportClerkRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionRecordListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/vendors/vendor-sales/transaction-details/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorsUserService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateVendorUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin-vendor-user';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateVendorUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin-vendor-user';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: UpdateVendorUserPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin-vendor-user/change-password';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin-vendor-user/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class VendorTransactionService {
  /**
   *
   */
  static generalVendorCreateTransaction(
    params: {
      /** requestBody */
      body?: CreateTransactionForVendorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateTransactionReturnDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/transaction/general-vendor';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hqVendorCreateTransaction(
    params: {
      /** requestBody */
      body?: CreateTransactionForHqVendorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateTransactionReturnDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/transaction/hq-vendor';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorPivNumber(
    params: {
      /** requestBody */
      body?: GetVendorPivQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetVendorPivReturnDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/transaction/vendor-piv';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static voidTransaction(
    params: {
      /** requestBody */
      body?: VoidTransactionForVendorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/transaction/void';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCheckoutInfo(
    params: {
      /**  */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CheckoutInformationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/transaction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorSalesReportPdf(
    params: {
      /** requestBody */
      body?: GetVendorSalesReportPdfFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/transaction/sales-report/export/pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorMySalesReportPdf(
    params: {
      /** requestBody */
      body?: GetVendorMySalesReportPdfFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/transaction/my-sales-report/export/pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorClerkSalesReportPdf(
    params: {
      /** requestBody */
      body?: GetVendorClerkSalesReportPdfFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/transaction/clerk-sales-report/export/pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorVoidSalesReportPdf(
    params: {
      /** requestBody */
      body?: GetVendorVoidSalesReportPdfFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/transaction/void-sales-report/export/pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VendorTypeService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      vendorId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/is-hq';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { vendorId: params['vendorId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class VendorUserService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateVendorUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vendor-user';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateVendorUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vendor-user';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: UpdateVendorUserPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vendor-user/change-password';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vendor-user/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class VendorVerifyCustomerService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer/verify-customer/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: VerifyCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendor/customer/verify-customer';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VerifyCustomerService {
  /**
   *
   */
  static getById(options: IRequestOptions = {}): Promise<CustomerDetailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/verify-customer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: IspVerifyCustomerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer/verify-customer';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VoidSalesReportService {
  /**
   *
   */
  static getVoidSalesTable(
    params: {
      /** requestBody */
      body?: GetVendorReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoidTransactionRecordListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/vendors/void-sales/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WeeklyAchReportService {
  /**
   *
   */
  static getOptions(options: IRequestOptions = {}): Promise<WeeklyAchReportOptionsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/weekly-ach/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetWeeklyAchDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WeeklyAchListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/weekly-ach/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTableExcelFile(
    params: {
      /** requestBody */
      body?: GetWeeklyAchDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/accounting/weekly-ach/table/export/excel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export interface ACHProblemDto {
  /**  */
  addendaLine?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  achReturnCode?: ACHReturnCodeDto;

  /**  */
  achBatchDetail?: JournalACHBatchDetailDto;
}

export interface ACHReturnCodeDto {
  /**  */
  code?: string;

  /**  */
  reason?: string;
}

export interface AccountConfirmationResult {
  /**  */
  isPasswordResetRequired: boolean;

  /**  */
  code?: string;
}

export interface AccountConfirmationResultApiResult {
  /**  */
  result?: AccountConfirmationResult;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AccountDistributionCodeCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  fund?: string;

  /**  */
  description?: string;

  /**  */
  isLicenseSales: boolean;

  /**  */
  isActive: boolean;
}

export interface AccountDistributionCodeListItemDto {
  /**  */
  id: number;

  /**  */
  fund?: string;

  /**  */
  description?: string;

  /**  */
  isLicenseSales: boolean;

  /**  */
  isActive: boolean;

  /**  */
  createdTimestamp: Date;
}

export interface AccountDistributionCodeListItemDtoApiResult {
  /**  */
  result?: AccountDistributionCodeListItemDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AccountDistributionCodeListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: AccountDistributionCodeListItemDto[];
}

export interface AccountDistributionCodeListItemDtoDataTablePageApiResult {
  /**  */
  result?: AccountDistributionCodeListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AchBalanceListItemDto {
  /**  */
  productCode?: string;

  /**  */
  privilege?: string;

  /**  */
  totalNumberSold: number;

  /**  */
  totalActive: number;

  /**  */
  totalVoidedOrRefunded: number;

  /**  */
  amount: number;

  /**  */
  commission: number;

  /**  */
  stateAmount: number;

  /**  */
  voidAndRefundAmount: number;

  /**  */
  conFund1201: number;

  /**  */
  conFund1201A: number;

  /**  */
  dCrab: number;

  /**  */
  fedDuck: number;

  /**  */
  h4H5808: number;

  /**  */
  laDuck: number;

  /**  */
  collectorStamp: number;

  /**  */
  laFur: number;

  /**  */
  laTurkey: number;

  /**  */
  nha: number;

  /**  */
  salt: number;

  /**  */
  wHab: number;

  /**  */
  boatComm: number;

  /**  */
  charter: number;

  /**  */
  laAlligator1313: number;

  /**  */
  laAlligator1313A: number;

  /**  */
  charterBoatFishing: number;

  /**  */
  ldwfFoundationEscrow: number;

  /**  */
  wlfms1000022: number;

  /**  */
  w23W2300Licensing: number;

  /**  */
  nsfFee: number;

  /**  */
  appliedCredit: number;

  /**  */
  licensingTotal: number;

  /**  */
  alligatorLottery: number;

  /**  */
  whiteLakeLottery: number;

  /**  */
  whiteLakeHuntFees: number;

  /**  */
  wmaLottery: number;

  /**  */
  atchafalayaDelta: number;

  /**  */
  w23W2300Lottery: number;

  /**  */
  lotteryTotal: number;

  /**  */
  hunterEd: number;

  /**  */
  boaterEd: number;

  /**  */
  total: number;
}

export interface AchBalanceListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: AchBalanceListItemDto[];
}

export interface AchBalanceListItemDtoDataTablePageApiResult {
  /**  */
  result?: AchBalanceListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AchReturnsListItemDto {
  /**  */
  vendorId?: number;

  /**  */
  achBatchCreatedTimestamp: Date;

  /**  */
  achBatchDetailAmount: number;

  /**  */
  vendorName?: string;

  /**  */
  achReturnCode?: string;

  /**  */
  achReturnReason?: string;
}

export interface AchReturnsListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: AchReturnsListItemDto[];
}

export interface AchReturnsListItemDtoDataTablePageApiResult {
  /**  */
  result?: AchReturnsListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AchReturnsReportOptionsDto {
  /**  */
  achReturnCodes?: OptionItemDto[];

  /**  */
  vendors?: OptionItemDto[];
}

export interface AchReturnsReportOptionsDtoApiResult {
  /**  */
  result?: AchReturnsReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ActivateOrReprintCommand {
  /**  */
  transactionCustomerProductId: number;

  /**  */
  isReprint: boolean;

  /**  */
  isEnabling: boolean;
}

export interface AddDonationAsVendorDto {
  /**  */
  customerId: number;

  /**  */
  donationProductCode?: string;

  /**  */
  donationAmount: number;
}

export interface AddDonationDto {
  /**  */
  donationProductCode?: string;

  /**  */
  donationAmount: number;
}

export interface AddJournalCommand {
  /**  */
  accountDistributionCodeId: number;

  /**  */
  journalTypeId: number;

  /**  */
  statusCodeId: number;

  /**  */
  vendorId: number;

  /**  */
  amount: number;

  /**  */
  comment?: string;
}

export interface AddJournalOptionsDto {
  /**  */
  accountDistributionCodes?: OptionItemDto[];

  /**  */
  journalTypes?: OptionItemDto[];

  /**  */
  statusCodes?: OptionItemDto[];
}

export interface AddJournalOptionsDtoApiResult {
  /**  */
  result?: AddJournalOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AddOnlineCourseToCartCommand {
  /**  */
  courseCode?: string;
}

export interface AddressDto {
  /**  */
  fullAddress?: string;

  /**  */
  county?: string;

  /**  */
  street1?: string;

  /**  */
  street2?: string;

  /**  */
  city?: string;

  /**  */
  zipCode?: string;

  /**  */
  plus4?: string;

  /**  */
  internationalPostalCode?: string;

  /**  */
  stateCode?: string;

  /**  */
  countryCode?: string;

  /**  */
  countyCodeId?: number;
}

export interface AddressOptionsDto {
  /**  */
  counties?: OptionItemDto[];
}

export interface AddressOptionsDtoApiResult {
  /**  */
  result?: AddressOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AdminCreateCustomerCommand {
  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface AdminUpdateCustomerWrapper {
  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface ApplicantListItemDto {
  /**  */
  id: number;

  /**  */
  lotteryId: number;

  /**  */
  customerId: number;

  /**  */
  customerNumber?: string;

  /**  */
  name?: string;

  /**  */
  quantity: number;

  /**  */
  status?: string;

  /**  */
  acceptanceDeadline?: Date;

  /**  */
  createdTimestamp: Date;

  /**  */
  groupCode?: string;

  /**  */
  groupLeader?: string;

  /**  */
  preferencePointsBefore?: number;

  /**  */
  preferencePointsAfter?: number;

  /**  */
  awardedHuntChoiceDisplayName?: string;

  /**  */
  awardedHuntChoice?: HuntChoiceDto;

  /**  */
  selectedHunts?: HuntChoiceDto[];

  /**  */
  accepted?: string;

  /**  */
  isApplicationFeePaid: boolean;

  /**  */
  isAwardFeePaid: boolean;

  /**  */
  isEligibleToDisqualify?: string;

  /**  */
  lotteryAcceptanceDeadlineDays?: number;

  /**  */
  lastLotteryApplicationStatusName?: string;
}

export interface ApplicantListItemDtoApiResult {
  /**  */
  result?: ApplicantListItemDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ApplicantListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: ApplicantListItemDto[];
}

export interface ApplicantListItemDtoDataTablePageApiResult {
  /**  */
  result?: ApplicantListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AuditDataTableRequest {
  /**  */
  entityId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface AuditListingDto {
  /**  */
  auditAction?: AuditAction;

  /**  */
  recordTypeName?: string;

  /**  */
  recordPropertyName?: string;

  /**  */
  oldValue?: string;

  /**  */
  newValue?: string;

  /**  */
  timestamp: Date;

  /**  */
  userName?: string;
}

export interface AuditListingDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: AuditListingDto[];
}

export interface AuditListingDtoDataTablePageApiResult {
  /**  */
  result?: AuditListingDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AutoRenewProductDisplayDto {
  /**  */
  enrolledAutoRenewProducts?: CustomerAutoRenewProductListItemDto[];

  /**  */
  eligibleAutoRenewProducts?: CustomerAutoRenewEligibleProductListItemDto[];
}

export interface AutoRenewProductDisplayDtoApiResult {
  /**  */
  result?: AutoRenewProductDisplayDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface AutoRenewalsListItemDto {
  /**  */
  productCode?: string;

  /**  */
  productName?: string;

  /**  */
  productFeeAmount: number;

  /**  */
  numberOfCustomersWithAutoRenew: number;
}

export interface AutoRenewalsListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: AutoRenewalsListItemDto[];
}

export interface AutoRenewalsListItemDtoDataTablePageApiResult {
  /**  */
  result?: AutoRenewalsListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface BaseInstructorCertificationCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  instructorId: number;

  /**  */
  certificationDate?: Date;

  /**  */
  dateLastTaught?: Date;

  /**  */
  isActive: boolean;
}

export interface BillingDetailsDto {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  countryCode?: string;

  /**  */
  streetAddress?: string;

  /**  */
  city?: string;

  /**  */
  stateCode?: string;

  /**  */
  zipCode?: string;
}

export interface BoatRenewalDto {
  /**  */
  id: number;

  /**  */
  longBoatNumber?: string;

  /**  */
  hullIdNumber?: string;

  /**  */
  amount: number;

  /**  */
  streetAddress?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zipCode?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  emailAddress?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffix?: string;

  /**  */
  combinedName?: string;

  /**  */
  isDualOwner: boolean;

  /**  */
  dateOfBirth?: Date;

  /**  */
  additionalNotes?: string;

  /**  */
  isEscalated: boolean;

  /**  */
  isEligibleForRenewal: boolean;
}

export interface BoatRenewalDtoApiResult {
  /**  */
  result?: BoatRenewalDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface BoatRenewalListItemDto {
  /**  */
  id: number;

  /**  */
  longboatNumber?: string;

  /**  */
  hullIdNumber?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  renewalDate: Date;

  /**  */
  hasRenewedYesNo?: string;

  /**  */
  elavonTransactionId?: string;

  /**  */
  amount: number;

  /**  */
  streetAddress?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zipCode?: string;

  /**  */
  isEscalatedYesNo?: string;

  /**  */
  additionalNotes?: string;
}

export interface BoatRenewalListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: BoatRenewalListItemDto[];
}

export interface BoatRenewalListItemDtoDataTablePageApiResult {
  /**  */
  result?: BoatRenewalListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface BoatRenewalLookupCommand {
  /**  */
  registrationNumber?: string;

  /**  */
  hullIdNumber?: string;
}

export interface BoatRenewalTransactionReceiptEmailCommand {
  /**  */
  boatRenewalDetails?: TransactionBoatDto;

  /**  */
  emailAddress?: string;
}

export interface BoatRenewalUpdateDto {
  /**  */
  id: number;

  /**  */
  additionalNotes?: string;

  /**  */
  isEscalated: boolean;
}

export interface BooleanApiResult {
  /**  */
  result: boolean;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface BooleanNullableApiResult {
  /**  */
  result?: boolean;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CanLoggedInUserRegisterForEventQueryResponse {
  /**  */
  eventId: number;

  /**  */
  customerId?: number;

  /**  */
  isUserLoggedIn: boolean;

  /**  */
  eventHasWaitlist: boolean;

  /**  */
  canUserRegisterForEvent?: CanUserRegisterForEvent;

  /**  */
  canUserWaitlistForEvent?: CanUserWaitlistForEvent;
}

export interface CanLoggedInUserRegisterForEventQueryResponseApiResult {
  /**  */
  result?: CanLoggedInUserRegisterForEventQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CanUserRegisterForEvent {
  /**  */
  customerIsNotRegisteredForEvent: boolean;

  /**  */
  customerHasEmailAddress: boolean;

  /**  */
  eventIsNotAtCapacity: boolean;

  /**  */
  eventDoesNotHaveCustomersOnWaitlist: boolean;

  /**  */
  customerAlreadyRegisteredForClassEventType: boolean;

  /**  */
  customerAlreadyWaitlistedForClassEventType: boolean;
}

export interface CanUserWaitlistForEvent {
  /**  */
  eventIsAtCapacity: boolean;

  /**  */
  customerIsNotRegisteredForEvent: boolean;

  /**  */
  customerIsNotWaitlistedForEvent: boolean;

  /**  */
  customerAlreadyRegisteredForClassEventType: boolean;

  /**  */
  customerAlreadyWaitlistedForClassEventType: boolean;
}

export interface CatalogProductDto {
  /**  */
  id: number;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  staticText?: string;

  /**  */
  tagLine?: string;

  /**  */
  displayMenuId?: number;

  /**  */
  orderBy?: number;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  amountAllowedPerSeason: number;

  /**  */
  maxPerSale: number;

  /**  */
  productFeeTotal: number;

  /**  */
  minimumAge: number;

  /**  */
  maximumAge: number;

  /**  */
  licenseValidEndDate?: Date;

  /**  */
  isStartDateSelectable?: boolean;

  /**  */
  numberOfDaysAllowed?: number;

  /**  */
  isIncludable: boolean;

  /**  */
  isMilitary: boolean;

  /**  */
  isSerialized: boolean;

  /**  */
  hasTags: boolean;

  /**  */
  isPurchasable: boolean;

  /**  */
  productTypeCodeType?: string;

  /**  */
  isNative: boolean;

  /**  */
  isEligibleForAutoRenew: boolean;

  /**  */
  isEligibleForEarlyRenewal: boolean;

  /**  */
  earlyRenewalDays?: number;

  /**  */
  replacedTransactionCustomerProductId?: number;

  /**  */
  earlyExpiringTransactionCustomerProductId?: number;

  /**  */
  isDuplicateLifetimeProduct: boolean;

  /**  */
  isRolpRequired: boolean;

  /**  */
  isMailingAddressConfirmationRequired: boolean;

  /**  */
  fulfillmentTypes?: FulfillmentType[];

  /**  */
  productRules?: ProductRuleDto[];

  /**  */
  productImages?: CatalogProductImageDto[];
}

export interface CatalogProductDtoApiResult {
  /**  */
  result?: CatalogProductDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CatalogProductImageDto {
  /**  */
  id: number;

  /**  */
  productId: number;

  /**  */
  displayOrder: number;

  /**  */
  imageCode?: string;

  /**  */
  signedImageUri?: string;

  /**  */
  isArchived: boolean;
}

export interface CatalogProductImageDtoListApiResult {
  /**  */
  result?: CatalogProductImageDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CertificationCodeGetDto {
  /**  */
  description?: string;

  /**  */
  id: number;

  /**  */
  typeCode?: string;

  /**  */
  certificationType?: CertificationType;

  /**  */
  isHuntingCourseCertification: boolean;

  /**  */
  isBowCourseCertification: boolean;

  /**  */
  isBoatCourseCertification: boolean;
}

export interface CertificationCodeGetDtoApiResult {
  /**  */
  result?: CertificationCodeGetDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CertificationCodeGetDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CertificationCodeGetDto[];
}

export interface CertificationCodeGetDtoDataTablePageApiResult {
  /**  */
  result?: CertificationCodeGetDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CertificationCodeGetDtoListApiResult {
  /**  */
  result?: CertificationCodeGetDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CertificationCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  certificationNumber?: string;

  /**  */
  certificationCodeId: number;

  /**  */
  stateCode?: string;
}

export interface CertificationInfo {
  /**  */
  certificationDescription?: string;

  /**  */
  certificationNumber?: string;

  /**  */
  certificationState?: string;

  /**  */
  issuedDate: Date;

  /**  */
  severity: number;
}

export interface CertificationInstructorCertificationGroupingDto {
  /**  */
  instructorCertifications?: BaseInstructorCertificationCreateUpdateDto[];

  /**  */
  id: number;

  /**  */
  certificationNumber?: string;

  /**  */
  certificationCodeId: number;

  /**  */
  stateCode?: string;
}

export interface CertificationsReportListItemDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  certificationCodeId: number;

  /**  */
  sportsmanId?: string;

  /**  */
  name?: string;

  /**  */
  certificationCode?: string;

  /**  */
  certificationType?: string;

  /**  */
  certificationNumber?: string;

  /**  */
  certificationDateString?: string;

  /**  */
  certificationDate: Date;
}

export interface CertificationsReportListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CertificationsReportListItemDto[];
}

export interface CertificationsReportListItemDtoDataTablePageApiResult {
  /**  */
  result?: CertificationsReportListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CertificationsReportOptionsDto {
  /**  */
  certificationCodes?: OptionItemDto[];

  /**  */
  certificationTypes?: OptionItemDto[];
}

export interface CertificationsReportOptionsDtoApiResult {
  /**  */
  result?: CertificationsReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ChangeExpiredPasswordCommand {
  /**  */
  userId?: string;

  /**  */
  oldPassword?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface ChangeInstructorPasswordCommand {
  /**  */
  changePasswordType?: ChangePasswordType;

  /**  */
  userId: number;

  /**  */
  oldPassword?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface ChangeUserPasswordCommand {
  /**  */
  changePasswordType?: ChangePasswordType;

  /**  */
  userId: number;

  /**  */
  oldPassword?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface CheckoutInformationDto {
  /**  */
  shoppingCart?: ShoppingCartDto;

  /**  */
  customerInformation?: CustomerInformationDto;
}

export interface CheckoutInformationDtoApiResult {
  /**  */
  result?: CheckoutInformationDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ClassEventTypeDto {
  /**  */
  id: number;

  /**  */
  eventTypeId?: number;

  /**  */
  classTypeId?: number;

  /**  */
  name?: string;

  /**  */
  internalDescription?: string;

  /**  */
  isAgeRestricted: boolean;

  /**  */
  minAge?: number;

  /**  */
  maxAge?: number;

  /**  */
  isLocationRequired: boolean;

  /**  */
  statusCodeId?: number;

  /**  */
  issuedCertificationCodeId?: number;

  /**  */
  publicDescription?: string;

  /**  */
  requiredCertificationCodeId?: number;

  /**  */
  isSameDayRegistrationAllowed: boolean;
}

export interface ClassEventTypeDtoApiResult {
  /**  */
  result?: ClassEventTypeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ClassEventTypeListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  internalDescription?: string;

  /**  */
  classTypeName?: string;

  /**  */
  issuedCertificationDescription?: string;

  /**  */
  isAgeRestricted?: string;

  /**  */
  isLocationRequired?: string;

  /**  */
  minAge?: string;

  /**  */
  maxAge?: string;

  /**  */
  statusCodeDescription?: string;
}

export interface ClassEventTypeListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: ClassEventTypeListItemDto[];
}

export interface ClassEventTypeListItemDtoDataTablePageApiResult {
  /**  */
  result?: ClassEventTypeListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ClassEventTypeOptionsDto {
  /**  */
  eventTypeNames?: OptionItemDto[];

  /**  */
  classTypeNames?: OptionItemDto[];

  /**  */
  issuedCertificationOptions?: OptionItemDto[];

  /**  */
  requiredCertificationOptions?: OptionItemDto[];

  /**  */
  statusCodes?: OptionItemDto[];
}

export interface ClassEventTypeOptionsDtoApiResult {
  /**  */
  result?: ClassEventTypeOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ClassEventTypeSummaryReportRecord {
  /**  */
  typeId: number;

  /**  */
  classEventTypeName?: string;

  /**  */
  coursesCount: number;

  /**  */
  studentsCount: number;

  /**  */
  studentsPassedCount: number;

  /**  */
  studentsPassedPercentage: number;

  /**  */
  trainingHours: number;

  /**  */
  prepHours: number;

  /**  */
  inClassHours: number;

  /**  */
  adminHours: number;

  /**  */
  fieldHours: number;

  /**  */
  travelHours: number;

  /**  */
  totalHours: number;
}

export interface CollectedTextToTagInfoDto {
  /**  */
  customerId: number;

  /**  */
  isTurkey: boolean;

  /**  */
  speciesInfo: number;

  /**  */
  tagIndex: number;

  /**  */
  dateIndex: number;

  /**  */
  parishName?: string;

  /**  */
  landTypeIndex: number;

  /**  */
  deerAreaIndex?: number;

  /**  */
  antlerType?: AntlerCode;
}

export interface CommentDataTableRequest {
  /**  */
  customerId?: number;

  /**  */
  vendorId?: number;

  /**  */
  revocationId?: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface CommentDto {
  /**  */
  id: number;

  /**  */
  customerId?: number;

  /**  */
  vendorId?: number;

  /**  */
  revocationId?: number;

  /**  */
  text?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  lastUpdatedByUserName?: string;
}

export interface CommentDtoApiResult {
  /**  */
  result?: CommentDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CommentDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CommentDto[];
}

export interface CommentDtoDataTablePageApiResult {
  /**  */
  result?: CommentDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CommercialLicenseAccountInformationDto {
  /**  */
  licenseNumber?: string;

  /**  */
  isPaid: boolean;

  /**  */
  description?: string;

  /**  */
  companyName?: string;

  /**  */
  fullName?: string;

  /**  */
  renewalDate?: string;
}

export interface CommercialLicenseAccountInformationDtoApiResult {
  /**  */
  result?: CommercialLicenseAccountInformationDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CommercialLicenseListItemDto {
  /**  */
  id: number;

  /**  */
  licenseNumber?: string;

  /**  */
  lastLicenseYear?: string;

  /**  */
  description?: string;

  /**  */
  fees: number;
}

export interface CommercialLicenseListItemDtoListApiResult {
  /**  */
  result?: CommercialLicenseListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CommercialLicenseRenewalLookupCommand {
  /**  */
  licenseNumber?: string;

  /**  */
  socialSecurityNumberLast4?: string;
}

export interface CompleteEventCommand {
  /**  */
  eventId: number;
}

export interface CopyLotteryFormDto {
  /**  */
  id: number;

  /**  */
  copiedYear: number;

  /**  */
  copiedLotteryStatusCode?: LotteryStatusCode;

  /**  */
  copiedPreferencePointTypeId: number;

  /**  */
  copiedLotteryDisplayCategoryId: number;

  /**  */
  copiedLotteryFeeId: number;

  /**  */
  copiedResidencyCode?: ResidencyCode;

  /**  */
  copiedLotteryAwardId?: number;

  /**  */
  copiedCode?: string;

  /**  */
  copiedMinAge: number;

  /**  */
  copiedMaxAge: number;

  /**  */
  copiedMaxParty: number;

  /**  */
  copiedAllowMultipleApplications: boolean;

  /**  */
  copiedAllowedApplications: number;

  /**  */
  copiedChoicesPerApplication: number;

  /**  */
  copiedAutoDisqualifyForNonAcceptance: boolean;

  /**  */
  copiedHasRankedChoices: boolean;

  /**  */
  copiedAllowLeftovers: boolean;

  /**  */
  copiedIsFeePaidOnline: boolean;

  /**  */
  copiedAutoExecute: boolean;

  /**  */
  copyHunts: boolean;

  /**  */
  copiedApplicationInstructions?: string;

  /**  */
  copiedAdditionalInformation?: string;

  /**  */
  copiedAcceptanceDeadlineDays?: number;

  /**  */
  copiedApplicationStartDate: Date;

  /**  */
  copiedApplicationEndDate: Date;

  /**  */
  copiedDrawDate?: Date;

  /**  */
  copiedNotificationDate?: Date;

  /**  */
  copiedLeftoversStartDate?: Date;

  /**  */
  copiedLeftoversEndDate?: Date;

  /**  */
  copiedLeftoversStartTime?: Date;

  /**  */
  copiedLeftoversEndTime?: Date;

  /**  */
  copiedFeeExemptionProductCodes?: string[];

  /**  */
  copiedLotteryCustomerLicenseEligibilityCodes?: number[];

  /**  */
  copiedLotteryPchpClassTypes?: PchpClassType[];
}

export interface CorporateReportOptionsDto {
  /**  */
  achBatchOptions?: OptionItemDto[];
}

export interface CorporateReportOptionsDtoApiResult {
  /**  */
  result?: CorporateReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CorporationDataTableRequest {
  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface CorporationDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  contactName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  fax?: string;

  /**  */
  email?: string;

  /**  */
  isActive: boolean;

  /**  */
  username?: string;

  /**  */
  comment?: string;

  /**  */
  address?: AddressDto;
}

export interface CorporationDtoApiResult {
  /**  */
  result?: CorporationDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CorporationDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CorporationDto[];
}

export interface CorporationDtoDataTablePageApiResult {
  /**  */
  result?: CorporationDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CorporationOptionsDto {
  /**  */
  vendors?: OptionItemDto[];
}

export interface CorporationOptionsDtoApiResult {
  /**  */
  result?: CorporationOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CorporationReportDto {
  /**  */
  vendorName?: string;

  /**  */
  accountNumber?: string;

  /**  */
  date?: string;

  /**  */
  amount: number;

  /**  */
  vendorFeeAmount: number;

  /**  */
  grossAmount: number;

  /**  */
  batchId?: number;

  /**  */
  achBatchId: number;

  /**  */
  corporationId: number;
}

export interface CorporationReportDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CorporationReportDto[];
}

export interface CountyCodeDeerAreaDto {
  /**  */
  countyCodeId: number;

  /**  */
  deerAreaId: number;
}

export interface CourseCatalogDto {
  /**  */
  courses?: CourseDto[];
}

export interface CourseCatalogDtoApiResult {
  /**  */
  result?: CourseCatalogDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CourseDto {
  /**  */
  courseId: number;

  /**  */
  courseType?: string;

  /**  */
  courseCode?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  maxAttempts: number;

  /**  */
  courseTerms?: string;
}

export interface CourseSystemEnrollmentDetailDto {
  /**  */
  id: number;

  /**  */
  courseCode?: string;

  /**  */
  status?: string;

  /**  */
  enrollmentIdentifier?: string;

  /**  */
  courseName?: string;

  /**  */
  isExportable: boolean;

  /**  */
  purchasedDate: Date;

  /**  */
  completedDate?: Date;

  /**  */
  customerDateOfBirth: Date;
}

export interface CourseSystemEnrollmentDetailDtoListApiResult {
  /**  */
  result?: CourseSystemEnrollmentDetailDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CourseSystemEnrollmentGetDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  onlineCourseTypeId: number;

  /**  */
  transactionId?: number;

  /**  */
  enrollmentIdentifier?: string;

  /**  */
  purchasedDate: Date;

  /**  */
  completedDate?: Date;

  /**  */
  status?: string;
}

export interface CourseSystemEnrollmentGetDtoApiResult {
  /**  */
  result?: CourseSystemEnrollmentGetDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CourseSystemEnrollmentListDto {
  /**  */
  customerId: number;

  /**  */
  customerSportsmanId?: string;

  /**  */
  customerSuffixCode?: SuffixCode;

  /**  */
  suffixCodeString?: string;

  /**  */
  customerFirstName?: string;

  /**  */
  customerMiddleName?: string;

  /**  */
  customerLastName?: string;

  /**  */
  onlineCourseTypeName?: string;

  /**  */
  enrollmentIdentifier?: string;

  /**  */
  status?: string;

  /**  */
  purchasedDate: Date;

  /**  */
  purchasedStartDate: Date;

  /**  */
  purchasedEndDate: Date;

  /**  */
  purchasedDateString?: string;

  /**  */
  completedDate?: Date;

  /**  */
  completedDateString?: string;
}

export interface CourseSystemEnrollmentListDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CourseSystemEnrollmentListDto[];
}

export interface CourseSystemEnrollmentListDtoDataTablePageApiResult {
  /**  */
  result?: CourseSystemEnrollmentListDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CourseSystemEnrollmentOptionsDto {
  /**  */
  statuses?: StringOptionItemDto[];
}

export interface CourseSystemEnrollmentOptionsDtoApiResult {
  /**  */
  result?: CourseSystemEnrollmentOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CourseSystemErrorResponse {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;
}

export interface CreateBoatTransactionResultDto {
  /**  */
  id: number;

  /**  */
  amount: number;

  /**  */
  transactionNumber?: string;

  /**  */
  renewalDate: Date;

  /**  */
  tenderType?: string;
}

export interface CreateBoatTransactionResultDtoApiResult {
  /**  */
  result?: CreateBoatTransactionResultDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CreateCourseSystemEnrollmentRequest {
  /**  */
  customerId: number;

  /**  */
  transactionId?: number;

  /**  */
  courseCode?: string;
}

export interface CreateCustomerCertificationCommand {
  /**  */
  id: number;

  /**  */
  certificationDto?: CertificationCreateUpdateDto;

  /**  */
  createdTimestamp: Date;

  /**  */
  customerId: number;

  /**  */
  customerEventId?: number;
}

export interface CreateCustomerExemptionCertificationCommand {
  /**  */
  id: number;

  /**  */
  certificationDto?: CertificationCreateUpdateDto;

  /**  */
  createdTimestamp: Date;

  /**  */
  customerId: number;

  /**  */
  customerEventId?: number;
}

export interface CreateEditCustomerAutoRenewPaymentMethodDto {
  /**  */
  elavonToken?: string;

  /**  */
  creditCardType?: string;

  /**  */
  creditCardNumber?: string;

  /**  */
  creditCardExpiration?: string;

  /**  */
  address?: string;

  /**  */
  city?: string;

  /**  */
  stateCode?: string;

  /**  */
  zipCode?: string;
}

export interface CreateFulfillmentMethodCommand {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  fulfillmentType?: FulfillmentType;

  /**  */
  isActive: boolean;

  /**  */
  isRecurring: boolean;

  /**  */
  timeOfDay?: Date;

  /**  */
  daysOfWeek?: DayOfWeek[];

  /**  */
  setDates?: FulfillmentMethodScheduleDateDto[];
}

export interface CreateInstructorCommand {
  /**  */
  statusCodeId: number;

  /**  */
  vendorId?: number;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  email?: string;

  /**  */
  userName?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  comments?: string;

  /**  */
  instructorTypeId?: number;

  /**  */
  userRole: number;

  /**  */
  countyCodeId?: number;

  /**  */
  certificationInstructorCertificationGroupings?: CertificationInstructorCertificationGroupingDto[];

  /**  */
  assignedToAllEventAreas: boolean;

  /**  */
  eventAreaIds?: number[];

  /**  */
  assignedToAllEventTypes: boolean;

  /**  */
  eventTypeIds?: number[];

  /**  */
  instructorAddress?: AddressDto;

  /**  */
  instructorEmailAddress?: string;

  /**  */
  instructorPhoneNumber?: string;
}

export interface CreateLoginViewTokenCommand {
  /**  */
  customerId: number;

  /**  */
  sportsmanId?: string;

  /**  */
  routingLink?: string;

  /**  */
  expirationDays: number;
}

export interface CreateLotteryFeeCommand {
  /**  */
  feeType?: LotteryFeeType;

  /**  */
  name?: string;

  /**  */
  lotteryFeeDistribution?: LotteryFeeDistributionDto;
}

export interface CreateLotteryResponseDto {
  /**  */
  createdLotteryId: number;

  /**  */
  wasSuccessful: boolean;
}

export interface CreateLotteryResponseDtoApiResult {
  /**  */
  result?: CreateLotteryResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CreateMobileLoginCommand {
  /**  */
  sportsmanId?: string;

  /**  */
  destination?: string;
}

export interface CreateMobileProductCatalogTagsViewLoginCommand {
  /**  */
  sportsmanId?: string;

  /**  */
  destination?: string;
}

export interface CreateRoleCommand {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  claims?: string[];
}

export interface CreateTransactionCommercialResultDto {
  /**  */
  tenderType?: string;

  /**  */
  id: number;

  /**  */
  amount: number;

  /**  */
  convenienceFee: number;

  /**  */
  transactionNumber?: string;

  /**  */
  renewalDate?: string;

  /**  */
  commercialLicenses?: CommercialLicenseListItemDto[];
}

export interface CreateTransactionCommercialResultDtoApiResult {
  /**  */
  result?: CreateTransactionCommercialResultDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CreateTransactionCustomerProductStatusCommand {
  /**  */
  transactionCustomerProductId: number;

  /**  */
  transactionStatus?: TransactionStatus;

  /**  */
  notes?: string;

  /**  */
  isVoiding: boolean;

  /**  */
  isActivate: boolean;

  /**  */
  isReprint: boolean;
}

export interface CreateTransactionForCustomerCommand {
  /**  */
  customerMailingAddress?: CustomerMailingAddressDto;

  /**  */
  hipSurvey?: HipSurveyDto;

  /**  */
  customerId?: number;

  /**  */
  isFreeTransaction: boolean;

  /**  */
  isAutoRenewal: boolean;
}

export interface CreateTransactionForHqVendorCommand {
  /**  */
  customerId: number;

  /**  */
  tenderType?: TenderType;

  /**  */
  customerMailingAddress?: CustomerMailingAddressDto;

  /**  */
  hipSurvey?: HipSurveyDto;

  /**  */
  checkNumber?: string;

  /**  */
  pivNumber?: string;

  /**  */
  depositDate?: Date;
}

export interface CreateTransactionForVendorCommand {
  /**  */
  customerId: number;

  /**  */
  customerMailingAddress?: CustomerMailingAddressDto;
}

export interface CreateTransactionReturnDto {
  /**  */
  id: number;

  /**  */
  productSalesTotal: number;

  /**  */
  transactionNumber?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  tendorType?: string;

  /**  */
  checkNumber?: string;
}

export interface CreateTransactionReturnDtoApiResult {
  /**  */
  result?: CreateTransactionReturnDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CreateUpdateCustomerDeviceDto {
  /**  */
  model?: string;

  /**  */
  manufacturer?: string;

  /**  */
  name?: string;

  /**  */
  osVersion?: string;

  /**  */
  idiom?: string;

  /**  */
  platform?: string;

  /**  */
  appVersion?: string;

  /**  */
  appBuild?: string;
}

export interface CreateUserCommand {
  /**  */
  statusCodeId: number;

  /**  */
  vendorId?: number;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  email?: string;

  /**  */
  userName?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  comments?: string;

  /**  */
  instructorTypeId?: number;

  /**  */
  userRole: number;

  /**  */
  countyCodeId?: number;

  /**  */
  certificationInstructorCertificationGroupings?: CertificationInstructorCertificationGroupingDto[];

  /**  */
  assignedToAllEventAreas: boolean;

  /**  */
  eventAreaIds?: number[];

  /**  */
  assignedToAllEventTypes: boolean;

  /**  */
  eventTypeIds?: number[];

  /**  */
  instructorAddress?: AddressDto;

  /**  */
  instructorEmailAddress?: string;

  /**  */
  instructorPhoneNumber?: string;
}

export interface CreateVendorUserCommand {
  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  userName?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  userRole: number;

  /**  */
  statusCodeId: number;

  /**  */
  isAdminEdit: boolean;

  /**  */
  vendorId?: number;

  /**  */
  email?: string;
}

export interface CreatedEntity {
  /**  */
  type?: string;

  /**  */
  id: number;
}

export interface CurrentDonationDto {
  /**  */
  donationName?: string;

  /**  */
  donationProductCode?: string;

  /**  */
  donationAmount: number;

  /**  */
  donationTypeCode?: DonationTypeCode;

  /**  */
  productId: number;
}

export interface CurrentDonationsDto {
  /**  */
  currentDonations?: CurrentDonationDto[];
}

export interface CurrentDonationsDtoApiResult {
  /**  */
  result?: CurrentDonationsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerAttachmentDataTableRequest {
  /**  */
  customerId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface CustomerAttachmentDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  description?: string;

  /**  */
  fileName?: string;

  /**  */
  fileSize: number;

  /**  */
  createdByUserName?: string;

  /**  */
  documentDate?: Date;

  /**  */
  blobUrl?: string;

  /**  */
  contentType?: string;

  /**  */
  documentTypeId: number;
}

export interface CustomerAttachmentDtoApiResult {
  /**  */
  result?: CustomerAttachmentDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerAttachmentListDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  description?: string;

  /**  */
  fileName?: string;

  /**  */
  fileSize: number;

  /**  */
  createdByUserName?: string;

  /**  */
  documentDate?: Date;

  /**  */
  blobUrl?: string;

  /**  */
  contentType?: string;

  /**  */
  documentTypeId: number;

  /**  */
  documentType?: string;
}

export interface CustomerAttachmentListDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CustomerAttachmentListDto[];
}

export interface CustomerAttachmentListDtoDataTablePageApiResult {
  /**  */
  result?: CustomerAttachmentListDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerAuthWithAlternateIdDto {
  /**  */
  id?: string;

  /**  */
  country?: string;

  /**  */
  dateOfBirth: Date;
}

export interface CustomerAuthWithCustomerNumberDto {
  /**  */
  sportsmanId?: string;

  /**  */
  dateOfBirth: Date;
}

export interface CustomerAuthWithDriversLicenseDto {
  /**  */
  number?: string;

  /**  */
  state?: string;

  /**  */
  dateOfBirth: Date;
}

export interface CustomerAuthWithSsnDto {
  /**  */
  ssnLast4?: string;

  /**  */
  lastName?: string;

  /**  */
  dateOfBirth: Date;
}

export interface CustomerAutoRenewEligibleProductListItemDto {
  /**  */
  currentPrice: number;

  /**  */
  expirationDate: Date;

  /**  */
  nextRenewDate: Date;

  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  transactionId: number;

  /**  */
  licenseNumber?: string;

  /**  */
  transactionNumber?: string;

  /**  */
  description?: string;

  /**  */
  vendor?: string;

  /**  */
  productFulfillmentMailingAddress?: CustomerMailingAddressDto;

  /**  */
  createdTimestamp: Date;

  /**  */
  isRevoked: boolean;

  /**  */
  productLicenseYear?: number;

  /**  */
  productName?: string;

  /**  */
  productCode?: string;

  /**  */
  replacedProductId?: number;

  /**  */
  replacedTransactionCustomerProductId?: number;

  /**  */
  replacedProductName?: string;

  /**  */
  productIsLifetime: boolean;

  /**  */
  productIsActive: boolean;

  /**  */
  productIsLicense: boolean;

  /**  */
  productIsOnlineCourse: boolean;

  /**  */
  licenseValidStartDate: Date;

  /**  */
  licenseValidEndDate: Date;

  /**  */
  transactionDate: Date;

  /**  */
  transactionStatus?: TransactionStatus;

  /**  */
  isAutoRenew: boolean;

  /**  */
  isEarlyRenewal: boolean;

  /**  */
  hasProcessedTransactionFulfillment: boolean;

  /**  */
  hasUnProcessedTransactionFulfillment: boolean;
}

export interface CustomerAutoRenewPaymentMethodDetailsDto {
  /**  */
  creditCardType?: string;

  /**  */
  creditCardLast4?: string;

  /**  */
  creditCardExpiration?: string;

  /**  */
  address?: string;

  /**  */
  city?: string;

  /**  */
  stateCode?: string;

  /**  */
  zipCode?: string;
}

export interface CustomerAutoRenewPaymentMethodDetailsDtoApiResult {
  /**  */
  result?: CustomerAutoRenewPaymentMethodDetailsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerAutoRenewProductListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  productCode?: string;

  /**  */
  currentPrice: number;

  /**  */
  expirationDate: Date;

  /**  */
  nextRenewDate: Date;

  /**  */
  lastAttemptedRenewalTimestamp?: Date;

  /**  */
  successfulRenewalTimestamp?: Date;

  /**  */
  additionalInformation?: string;

  /**  */
  renewalWasSuccessful?: boolean;
}

export interface CustomerCatalogOptionsDto {
  /**  */
  convenienceFee: number;

  /**  */
  displayMenu?: OptionItemDto[];

  /**  */
  products?: CatalogProductDto[];

  /**  */
  previouslyPurchasedProducts?: PreviouslyPurchasedProductsDto[];

  /**  */
  customerInformation?: CustomerInformationDto;

  /**  */
  hasRevocation: boolean;

  /**  */
  isHipCertificationNeeded: boolean;

  /**  */
  hipMinAge?: number;
}

export interface CustomerCatalogOptionsDtoApiResult {
  /**  */
  result?: CustomerCatalogOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerCertificationDataTableRequest {
  /**  */
  customerId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface CustomerCertificationDto {
  /**  */
  id: number;

  /**  */
  certificationNumber?: string;

  /**  */
  stateCode?: string;

  /**  */
  certificationCodeId: number;

  /**  */
  certificationId: number;

  /**  */
  isHuntingCourseCertification: boolean;

  /**  */
  isBowCourseCertification: boolean;

  /**  */
  isBoatCourseCertification: boolean;

  /**  */
  certificationCodeDescription?: string;

  /**  */
  certificationCodeTypeCode?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  createdBy?: string;

  /**  */
  customerId: number;

  /**  */
  customerEventId?: number;
}

export interface CustomerCertificationDtoApiResult {
  /**  */
  result?: CustomerCertificationDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerCertificationDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CustomerCertificationDto[];
}

export interface CustomerCertificationDtoDataTablePageApiResult {
  /**  */
  result?: CustomerCertificationDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerCertificationDtoListApiResult {
  /**  */
  result?: CustomerCertificationDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerCertificationOptionsDto {
  /**  */
  certificationCodes?: OptionItemDto[];
}

export interface CustomerCertificationOptionsDtoApiResult {
  /**  */
  result?: CustomerCertificationOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerDashboardAccountDetailDto {
  /**  */
  id: number;

  /**  */
  statusCode?: string;

  /**  */
  sportsmanId?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  hasBirthCertificate: boolean;

  /**  */
  isHipCertificationCompleted?: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  physicalAddress?: AddressDto;
}

export interface CustomerDashboardCertificationDto {
  /**  */
  id: number;

  /**  */
  certificationNumber?: string;

  /**  */
  stateCode?: string;

  /**  */
  certificationCodeId: number;

  /**  */
  certificationCodeDescription?: string;

  /**  */
  createdTimestamp: Date;
}

export interface CustomerDashboardDto {
  /**  */
  customerAccountDetails?: CustomerDashboardAccountDetailDto;

  /**  */
  licensesAndPermits?: CustomerDashboardPermitDto[];

  /**  */
  customerCertifications?: CustomerDashboardCertificationDto[];

  /**  */
  registeredEvents?: EventListItemDto[];

  /**  */
  waitlistedEvents?: EventListItemDto[];

  /**  */
  onlineCourseSystemEnrollments?: CourseSystemEnrollmentListDto[];

  /**  */
  currentLicenseYear: number;

  /**  */
  numberOfRevocations: number;

  /**  */
  numberOfApplications: number;

  /**  */
  numberOfAttachments: number;

  /**  */
  numberOfComments: number;

  /**  */
  numberOfNotifications: number;

  /**  */
  isHipCertificationNeeded: boolean;

  /**  */
  hipMinAge?: number;
}

export interface CustomerDashboardDtoApiResult {
  /**  */
  result?: CustomerDashboardDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerDashboardLotteryDto {
  /**  */
  showLotterySms: boolean;

  /**  */
  lotteryApplications?: CustomerDashboardLotteryItemDto[];
}

export interface CustomerDashboardLotteryDtoApiResult {
  /**  */
  result?: CustomerDashboardLotteryDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerDashboardLotteryItemDto {
  /**  */
  id: number;

  /**  */
  isAward: boolean;

  /**  */
  unsuccessful: boolean;

  /**  */
  isGroupApplication: boolean;

  /**  */
  awardAccepted?: boolean;

  /**  */
  name?: string;

  /**  */
  currentLotteryApplicationStatusName?: string;

  /**  */
  drawDate: Date;

  /**  */
  huntDate?: Date;

  /**  */
  acceptanceDate?: Date;
}

export interface CustomerDashboardPermitDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  replacedProductId?: number;

  /**  */
  replacedProductName?: string;

  /**  */
  licenseYear: number;

  /**  */
  isLifetime: boolean;

  /**  */
  isOnlineCourse: boolean;

  /**  */
  status?: TransactionStatus;

  /**  */
  licenseValidEndDate: Date;

  /**  */
  productRules?: ProductRuleDto[];

  /**  */
  productCode?: string;
}

export interface CustomerDataTableRequest {
  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface CustomerDetailDto {
  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  hunterSafetyNumber?: string;

  /**  */
  hunterSafetyStateCode?: string;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface CustomerDetailDtoApiResult {
  /**  */
  result?: CustomerDetailDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerEventListDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  sportsmanId?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  customerEventState?: CustomerEventState;

  /**  */
  instructorNotes?: string;

  /**  */
  hasPassed: boolean;

  /**  */
  hasCompletedAHuntingCourse: boolean;

  /**  */
  isRepeat: boolean;

  /**  */
  isParticipant: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  emailAddress?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  lastUpdatedByUserId?: number;

  /**  */
  deletedTimestamp?: Date;

  /**  */
  isDeleted: boolean;

  /**  */
  dateOfBirth?: string;
}

export interface CustomerEventWaitlistListItemDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  eventId: number;

  /**  */
  sportsmanId?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  emailAddress?: string;

  /**  */
  dateTimeEnteredIntoWaitlist: Date;
}

export interface CustomerInfo {
  /**  */
  customerName?: string;

  /**  */
  ldwfNumber?: string;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  genderCode?: GenderCode;

  /**  */
  eyeColorCode?: string;

  /**  */
  severity: number;
}

export interface CustomerInformationDto {
  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  age: number;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;
}

export interface CustomerLicenseEligibilityCodeDto {
  /**  */
  id: number;

  /**  */
  isPchp: boolean;

  /**  */
  code?: string;

  /**  */
  description?: string;
}

export interface CustomerLicenseEligibilityCodeDtoApiResult {
  /**  */
  result?: CustomerLicenseEligibilityCodeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerLicenseEligibilityCodeDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CustomerLicenseEligibilityCodeDto[];
}

export interface CustomerLicenseEligibilityCodeDtoDataTablePageApiResult {
  /**  */
  result?: CustomerLicenseEligibilityCodeDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerListItemDto {
  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  emailAddress?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  dateOfBirthString?: string;

  /**  */
  statusCode?: StatusCodeDto;

  /**  */
  physicalAddressFullAddress?: string;

  /**  */
  physicalAddressStreet1?: string;

  /**  */
  physicalAddressStreet2?: string;

  /**  */
  physicalAddressCity?: string;

  /**  */
  physicalAddressZipCode?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  passportNumber?: string;

  /**  */
  mailingAddressFullAddress?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  hasBirthCertificate: boolean;
}

export interface CustomerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CustomerListItemDto[];
}

export interface CustomerListItemDtoDataTablePageApiResult {
  /**  */
  result?: CustomerListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerLoginResponseDto {
  /**  */
  userId: number;

  /**  */
  customerId: number;
}

export interface CustomerLoginResponseDtoApiResult {
  /**  */
  result?: CustomerLoginResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerLoginWithTokenCommand {
  /**  */
  secureToken?: string;
}

export interface CustomerLookupDto {
  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  statusCodeId: number;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  emailAddress?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;
}

export interface CustomerLookupDtoApiResult {
  /**  */
  result?: CustomerLookupDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerLookupWithAlternateIdQuery {
  /**  */
  alternateIdentifier?: string;

  /**  */
  countryOfIssueCode?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  isVendorLookup?: boolean;
}

export interface CustomerLookupWithCustomerNumberQuery {
  /**  */
  sportsmanId?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  isVendorLookup?: boolean;
}

export interface CustomerLookupWithDriversLicenseQuery {
  /**  */
  driverLicenseNumber?: string;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  isVendorLookup?: boolean;
}

export interface CustomerLookupWithSsnQuery {
  /**  */
  ssnLast4?: string;

  /**  */
  lastName?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  isVendorLookup?: boolean;
}

export interface CustomerMailingAddressDto {
  /**  */
  street1?: string;

  /**  */
  street2?: string;

  /**  */
  city?: string;

  /**  */
  zipCode?: string;

  /**  */
  plus4?: string;

  /**  */
  internationalPostalCode?: string;

  /**  */
  stateCode?: string;

  /**  */
  countryCode?: string;

  /**  */
  countyCodeId?: number;
}

export interface CustomerMailingAddressDtoApiResult {
  /**  */
  result?: CustomerMailingAddressDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerPreferencePointCreateDto {
  /**  */
  customerId: number;

  /**  */
  preferencePointTypeId: number;

  /**  */
  quantity: number;
}

export interface CustomerPreferencePointListItemDto {
  /**  */
  id: number;

  /**  */
  year: number;

  /**  */
  customerId: number;

  /**  */
  preferencePointTypeId: number;

  /**  */
  preferencePointTypeName?: string;

  /**  */
  points: number;

  /**  */
  createdTimestamp: Date;

  /**  */
  comments?: string;
}

export interface CustomerPreferencePointListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CustomerPreferencePointListItemDto[];
}

export interface CustomerPreferencePointListItemDtoDataTablePageApiResult {
  /**  */
  result?: CustomerPreferencePointListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerPreferencePointsAdminDataTableRequest {
  /**  */
  customerId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface CustomerPreferencePointsAdminListItemDto {
  /**  */
  id: number;

  /**  */
  year: number;

  /**  */
  customerId: number;

  /**  */
  preferencePointTypeId: number;

  /**  */
  preferencePointTypeName?: string;

  /**  */
  points: number;

  /**  */
  createdByUserName?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  comments?: string;
}

export interface CustomerPreferencePointsAdminListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CustomerPreferencePointsAdminListItemDto[];
}

export interface CustomerPreferencePointsAdminListItemDtoDataTablePageApiResult {
  /**  */
  result?: CustomerPreferencePointsAdminListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerPreferencePointsBeforeAndAfterDto {
  /**  */
  before: number;

  /**  */
  after: number;

  /**  */
  preferencePointTypeId: number;
}

export interface CustomerPreferencePointsBeforeAndAfterDtoApiResult {
  /**  */
  result?: CustomerPreferencePointsBeforeAndAfterDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerPreferencePointsDataTableRequest {
  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface CustomerPreviousAddressDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  previousAddress?: AddressDto;

  /**  */
  addressType?: AddressType;

  /**  */
  dateEdited: Date;
}

export interface CustomerPreviousAddressDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CustomerPreviousAddressDto[];
}

export interface CustomerPreviousAddressDtoDataTablePageApiResult {
  /**  */
  result?: CustomerPreviousAddressDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerPreviousAddressesDataTableRequest {
  /**  */
  customerId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface CustomerProductListItemDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  transactionId: number;

  /**  */
  vendorId: number;

  /**  */
  vendorStatusCodeId: number;

  /**  */
  licenseNumber?: string;

  /**  */
  transactionNumber?: string;

  /**  */
  customer?: string;

  /**  */
  sportsmanId?: string;

  /**  */
  vendor?: string;

  /**  */
  vendorStatus?: string;

  /**  */
  product?: string;

  /**  */
  productCode?: string;

  /**  */
  transactionStatus?: string;

  /**  */
  dateIssued: Date;

  /**  */
  isAutoRenew?: string;

  /**  */
  isEarlyRenewal?: string;
}

export interface CustomerProductListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CustomerProductListItemDto[];
}

export interface CustomerProductListItemDtoDataTablePageApiResult {
  /**  */
  result?: CustomerProductListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerProductTransactionTagListItemDto {
  /**  */
  id: number;

  /**  */
  transactionCustomerProductId: number;

  /**  */
  licenseNumber?: string;

  /**  */
  transactionId: number;

  /**  */
  transactionNumber?: string;

  /**  */
  customerId: number;

  /**  */
  customerName?: string;

  /**  */
  sportsmanId?: string;

  /**  */
  dateIssued: Date;

  /**  */
  licenseYear?: string;

  /**  */
  tagType?: string;

  /**  */
  tagNumber?: string;

  /**  */
  confirmationNumber?: string;

  /**  */
  validationDate?: Date;

  /**  */
  validationMethod?: ValidationMethod;
}

export interface CustomerProductTransactionTagListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: CustomerProductTransactionTagListItemDto[];
}

export interface CustomerProductTransactionTagListItemDtoDataTablePageApiResult {
  /**  */
  result?: CustomerProductTransactionTagListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerProductsOptionsDto {
  /**  */
  products?: StringOptionItemDto[];
}

export interface CustomerProductsOptionsDtoApiResult {
  /**  */
  result?: CustomerProductsOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface CustomerTextToTagInfoDto {
  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;
}

export interface CustomerTextToTagInfoDtoApiResult {
  /**  */
  result?: CustomerTextToTagInfoDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DataTableColumn {
  /**  */
  propertyName?: string;

  /**  */
  sortDirection?: string;

  /**  */
  searchTerm?: string;
}

export interface DataTableRequest {
  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface DateTimeOffsetApiResult {
  /**  */
  result: Date;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DecimalApiResult {
  /**  */
  result: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DeerAreaTextToTagListItemDto {
  /**  */
  id: number;

  /**  */
  deerAreaCode?: string;
}

export interface DeerAreaTextToTagListItemDtoListApiResult {
  /**  */
  result?: DeerAreaTextToTagListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DeploymentInfoSettings {
  /**  */
  versionNumber?: string;

  /**  */
  deploymentDate?: string;

  /**  */
  commitHash?: string;
}

export interface DeploymentInfoSettingsApiResult {
  /**  */
  result?: DeploymentInfoSettings;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DisplayLotteriesDto {
  /**  */
  lotteryCustomerApplications?: LotteryApplicationListItemDto[];

  /**  */
  availableLotteries?: DisplayLotteryItemDto[];

  /**  */
  leftoverLotteries?: DisplayLotteryItemDto[];

  /**  */
  unavailableLotteries?: DisplayLotteryItemDto[];

  /**  */
  species?: LotteryDisplayCategoryDto[];
}

export interface DisplayLotteriesDtoApiResult {
  /**  */
  result?: DisplayLotteriesDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DisplayLotteryItemDto {
  /**  */
  id: number;

  /**  */
  isGroupApplication: boolean;

  /**  */
  hasHuntSlotsAvailable: boolean;

  /**  */
  hasDeclined: boolean;

  /**  */
  isIneligible: boolean;

  /**  */
  isFeePaidOnline: boolean;

  /**  */
  isApplicationFeeWaived: boolean;

  /**  */
  name?: string;

  /**  */
  price: number;

  /**  */
  leftoverTotalPrice: number;

  /**  */
  drawDate: Date;

  /**  */
  applicationStartDate: Date;

  /**  */
  applicationEndDate: Date;

  /**  */
  leftoversStartDate?: Date;

  /**  */
  leftoversEndDate?: Date;

  /**  */
  preferencePointType?: PreferencePointTypeDto;
}

export interface DisplayMenuDto {
  /**  */
  id: number;

  /**  */
  displayMenuValue?: string;

  /**  */
  displayOrder: number;
}

export interface DisplayMenuDtoApiResult {
  /**  */
  result?: DisplayMenuDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DisplayMenuDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: DisplayMenuDto[];
}

export interface DisplayMenuDtoDataTablePageApiResult {
  /**  */
  result?: DisplayMenuDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DocumentTypeDto {
  /**  */
  id: number;

  /**  */
  description?: string;
}

export interface DocumentTypeDtoApiResult {
  /**  */
  result?: DocumentTypeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DocumentTypeDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: DocumentTypeDto[];
}

export interface DocumentTypeDtoDataTablePageApiResult {
  /**  */
  result?: DocumentTypeDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface DocumentTypeOptionsDto {
  /**  */
  documentTypes?: OptionItemDto[];
}

export interface DocumentTypeOptionsDtoApiResult {
  /**  */
  result?: DocumentTypeOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ECitationCustomerDetailDto {
  /**  */
  header?: HeaderInfo;

  /**  */
  customer?: CustomerInfo;

  /**  */
  licenses?: LicenseInfo[];

  /**  */
  tags?: TagInfo[];

  /**  */
  certifications?: CertificationInfo[];

  /**  */
  revocations?: RevocationInfo[];
}

export interface ECitationCustomerDetailDtoListApiResult {
  /**  */
  result?: ECitationCustomerDetailDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ECitationCustomerInfoRequest {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  dateOfBirth?: Date;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  ldwfNumber?: string;
}

export interface ElavonTransactionTokenResponse {
  /**  */
  token?: string;
}

export interface ElavonTransactionTokenResponseApiResult {
  /**  */
  result?: ElavonTransactionTokenResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EmailCustomerCertificateToOwnerCommand {
  /**  */
  customerCertificationId: number;
}

export interface EmailCustomerCertificateToOwnerResponse {
  /**  */
  emailSucceeded: boolean;

  /**  */
  recipientEmailAddress?: string;
}

export interface EmailCustomerCertificateToOwnerResponseApiResult {
  /**  */
  result?: EmailCustomerCertificateToOwnerResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EmailCustomerEventsCommand {
  /**  */
  eventId: number;

  /**  */
  emailSubject?: string;

  /**  */
  emailBody?: string;
}

export interface EmailEventInstructorsCommand {
  /**  */
  eventId: number;

  /**  */
  emailSubject?: string;

  /**  */
  emailBody?: string;
}

export interface EmailEventLinkCommand {
  /**  */
  emailRecipient?: string;

  /**  */
  emailSubject?: string;

  /**  */
  emailBody?: string;
}

export interface EmailInstructorCertificationToOwnerCommand {
  /**  */
  instructorCertificationId: number;
}

export interface EmailInstructorCertificationToOwnerResponse {
  /**  */
  recipientEmailAddress?: string;
}

export interface EmailInstructorCertificationToOwnerResponseApiResult {
  /**  */
  result?: EmailInstructorCertificationToOwnerResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EmailInstructorCommand {
  /**  */
  instructorId: number;

  /**  */
  eventId: number;

  /**  */
  fromName?: string;

  /**  */
  fromEmail?: string;

  /**  */
  fromPhoneNumber?: string;

  /**  */
  emailBody?: string;
}

export interface EmailInstructorsRequest {
  /**  */
  take: number;

  /**  */
  emailSubject?: string;

  /**  */
  emailBody?: string;

  /**  */
  countyCodes?: string[];

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface EmailNotificationOptionsDto {
  /**  */
  lotteries?: OptionItemDto[];

  /**  */
  lotteryYears?: OptionItemDto[];

  /**  */
  hunts?: OptionItemDto[];

  /**  */
  huntLocations?: OptionItemDto[];

  /**  */
  lotteryApplicationStatus?: OptionItemDto[];

  /**  */
  preferencePointTypes?: OptionItemDto[];
}

export interface EmailNotificationOptionsDtoApiResult {
  /**  */
  result?: EmailNotificationOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EnableSmsTaggingCommand {
  /**  */
  enableSmsTextToTag: boolean;

  /**  */
  customerId?: number;

  /**  */
  phoneNumber?: string;
}

export interface EventAreaDto {
  /**  */
  id: number;

  /**  */
  fieldOffice?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface EventAreaDtoApiResult {
  /**  */
  result?: EventAreaDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventAreaListDto {
  /**  */
  id: number;

  /**  */
  fieldOffice?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface EventAreaListDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: EventAreaListDto[];
}

export interface EventAreaListDtoDataTablePageApiResult {
  /**  */
  result?: EventAreaListDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventCalendarDto {
  /**  */
  id: number;

  /**  */
  title?: string;

  /**  */
  start?: Date;

  /**  */
  end?: Date;
}

export interface EventCalendarDtoListApiResult {
  /**  */
  result?: EventCalendarDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventCertificationDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  certificationNumber?: string;

  /**  */
  certificationCodeDescription?: string;
}

export interface EventDateTimeDto {
  /**  */
  id: number;

  /**  */
  eventDate: Date;

  /**  */
  startTime: Date;

  /**  */
  endTime: Date;

  /**  */
  eventId?: number;
}

export interface EventDateTimeDtoListApiResult {
  /**  */
  result?: EventDateTimeDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventDetailDto {
  /**  */
  id: number;

  /**  */
  classEventTypeId?: number;

  /**  */
  classEventTypeName?: string;

  /**  */
  classEventType?: ClassEventTypeDto;

  /**  */
  countyCodeId?: number;

  /**  */
  eventLocationId?: number;

  /**  */
  displayStartDate: Date;

  /**  */
  displayEndDate: Date;

  /**  */
  capacity?: number;

  /**  */
  showOnline: boolean;

  /**  */
  eventStatusCodeId?: number;

  /**  */
  eventStatusCode?: EventStatusCodeDto;

  /**  */
  isPrivate: boolean;

  /**  */
  privatePasscode?: string;

  /**  */
  hasWaitList: boolean;

  /**  */
  description?: string;

  /**  */
  internalNotes?: string;

  /**  */
  address?: AddressDto;

  /**  */
  location?: string;

  /**  */
  city?: string;

  /**  */
  isCancelled: boolean;

  /**  */
  createdTimestamp: Date;

  /**  */
  createdByUserUserName?: string;

  /**  */
  numberOfStudentsInClass: number;

  /**  */
  isInstructorClass: boolean;

  /**  */
  instructorHasEmail: boolean;

  /**  */
  eventDateTimes?: EventDateTimeDto[];

  /**  */
  eventInstructors?: EventInstructorListItemDto[];

  /**  */
  students?: CustomerEventListDto[];

  /**  */
  waitlistedStudents?: CustomerEventWaitlistListItemDto[];
}

export interface EventDetailDtoApiResult {
  /**  */
  result?: EventDetailDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventDto {
  /**  */
  id: number;

  /**  */
  classEventTypeId?: number;

  /**  */
  countyCodeId?: number;

  /**  */
  eventLocationId?: number;

  /**  */
  displayStartDate: Date;

  /**  */
  displayEndDate: Date;

  /**  */
  capacity?: number;

  /**  */
  showOnline: boolean;

  /**  */
  eventStatusCodeId?: number;

  /**  */
  isPrivate: boolean;

  /**  */
  privatePasscode?: string;

  /**  */
  hasWaitList: boolean;

  /**  */
  description?: string;

  /**  */
  internalNotes?: string;

  /**  */
  address?: string;

  /**  */
  locationArea?: string;

  /**  */
  eventDateTimes?: EventDateTimeDto[];
}

export interface EventDtoApiResult {
  /**  */
  result?: EventDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventInstructorActivitiesForEventInstructorDto {
  /**  */
  id: number;

  /**  */
  eventInstructorActivities?: EventInstructorActivityDto[];
}

export interface EventInstructorActivitiesForEventInstructorDtoApiResult {
  /**  */
  result?: EventInstructorActivitiesForEventInstructorDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventInstructorActivityDto {
  /**  */
  id: number;

  /**  */
  eventInstructorId: number;

  /**  */
  eventInstructorActivityTypeId: number;

  /**  */
  activityDate: Date;

  /**  */
  hours: number;
}

export interface EventInstructorListItemDto {
  /**  */
  id: number;

  /**  */
  userId: number;

  /**  */
  isVerified: boolean;

  /**  */
  isPrimary: boolean;

  /**  */
  instructorId: number;

  /**  */
  eventId: number;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  fullName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  totalActivityTime: number;
}

export interface EventInstructorListItemDtoListApiResult {
  /**  */
  result?: EventInstructorListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventListItemDto {
  /**  */
  id: number;

  /**  */
  description?: string;

  /**  */
  classEventType?: string;

  /**  */
  classType?: string;

  /**  */
  classTypeId: number;

  /**  */
  eventType?: string;

  /**  */
  eventTypeId: number;

  /**  */
  countyCode?: string;

  /**  */
  location?: string;

  /**  */
  displayStartDate: Date;

  /**  */
  displayEndDate: Date;

  /**  */
  eventDateTimes?: EventDateTimeDto[];

  /**  */
  capacity?: number;

  /**  */
  numberOfStudentsInClass?: number;

  /**  */
  eventStatusCode?: string;

  /**  */
  city?: string;

  /**  */
  address?: AddressDto;

  /**  */
  customersCanOnlyApplyToWaitlist: boolean;

  /**  */
  eventRequiresAttention: boolean;

  /**  */
  eventInstructors?: EventInstructorListItemDto[];
}

export interface EventListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: EventListItemDto[];
}

export interface EventListItemDtoDataTablePageApiResult {
  /**  */
  result?: EventListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventLocationDto {
  /**  */
  id: number;

  /**  */
  statusCodeId?: number;

  /**  */
  address?: AddressDto;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  drivingDirections?: string;

  /**  */
  capacity: number;

  /**  */
  contactName?: string;

  /**  */
  contactPhone?: string;

  /**  */
  isHandicapAccessible: boolean;

  /**  */
  notes?: string;

  /**  */
  eventAreaId: number;
}

export interface EventLocationDtoApiResult {
  /**  */
  result?: EventLocationDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventLocationListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  statusCode?: string;

  /**  */
  address?: string;

  /**  */
  city?: string;

  /**  */
  parish?: string;

  /**  */
  zipCode?: string;

  /**  */
  capacity?: number;
}

export interface EventLocationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: EventLocationListItemDto[];
}

export interface EventLocationListItemDtoDataTablePageApiResult {
  /**  */
  result?: EventLocationListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventLocationListItemDtoListApiResult {
  /**  */
  result?: EventLocationListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventOptionsDto {
  /**  */
  eventStatusCodesActiveInactive?: OptionItemDto[];

  /**  */
  eventStatusCodes?: OptionItemDto[];

  /**  */
  eventDepartments?: OptionItemDto[];

  /**  */
  counties?: OptionItemDto[];

  /**  */
  eventLocations?: OptionItemDto[];

  /**  */
  classEventTypes?: OptionItemDto[];

  /**  */
  classTypes?: OptionItemDto[];

  /**  */
  eventTypes?: OptionItemDto[];

  /**  */
  city?: OptionItemDto[];

  /**  */
  locationFullAddresses?: OptionItemDto[];

  /**  */
  eventAreas?: OptionItemDto[];

  /**  */
  locationEventAreaMappings?: LocationEventAreaMappingDto[];

  /**  */
  eventInstructors?: OptionItemDto[];

  /**  */
  eventDates?: OptionItemDto[];
}

export interface EventOptionsDtoApiResult {
  /**  */
  result?: EventOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventStatusCodeDto {
  /**  */
  id: number;

  /**  */
  statusCode?: string;

  /**  */
  description?: string;
}

export interface EventSummary {
  /**  */
  eventId: number;

  /**  */
  certificationCodeId?: number;

  /**  */
  classEventTypeId: number;

  /**  */
  classEventTypeName?: string;

  /**  */
  beginDate: Date;

  /**  */
  endDate: Date;

  /**  */
  beginDateString?: string;

  /**  */
  endDateString?: string;

  /**  */
  eventLocationId?: number;

  /**  */
  eventLocationName?: string;

  /**  */
  address?: string;

  /**  */
  countyCodeId?: number;

  /**  */
  countyCode?: string;

  /**  */
  primaryInstructorId?: number;

  /**  */
  primaryInstructorName?: string;

  /**  */
  primaryInstructorType?: string;

  /**  */
  primaryInstructorNumber?: string;

  /**  */
  primaryInstructorCertificationNumber?: string;

  /**  */
  adminHours: number;

  /**  */
  fieldHours: number;

  /**  */
  inClassHours: number;

  /**  */
  prepHours: number;

  /**  */
  trainingHours: number;

  /**  */
  travelHours: number;

  /**  */
  totalHours: number;

  /**  */
  capacity?: number;

  /**  */
  registeredStudentsCount: number;

  /**  */
  passedStudentsCount: number;

  /**  */
  failedStudentsCount: number;

  /**  */
  repeatStudentsCount: number;

  /**  */
  passPercentage: number;
}

export interface EventSummaryOptionsDto {
  /**  */
  eventTypes?: OptionItemDto[];

  /**  */
  classEventTypes?: OptionItemDto[];

  /**  */
  eventInstructors?: OptionItemDto[];

  /**  */
  counties?: OptionItemDto[];
}

export interface EventSummaryOptionsDtoApiResult {
  /**  */
  result?: EventSummaryOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventTypeDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  sortOrder: number;

  /**  */
  eventDepartmentId: number;

  /**  */
  statusCodeId: number;
}

export interface EventTypeDtoApiResult {
  /**  */
  result?: EventTypeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface EventTypeListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  eventDepartment?: string;

  /**  */
  statusCode?: string;

  /**  */
  sortOrder: number;
}

export interface EventTypeListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: EventTypeListItemDto[];
}

export interface EventTypeListItemDtoDataTablePageApiResult {
  /**  */
  result?: EventTypeListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface FailCustomerEventBody {
  /**  */
  instructorNotes?: string;
}

export interface FeeDistributionCreateDto {
  /**  */
  accountDistributionCodeId: number;

  /**  */
  feeAmount: number;
}

export interface FeeDistributionListItemDto {
  /**  */
  id: number;

  /**  */
  accountDistributionCodeId: number;

  /**  */
  accountDistributionDescription?: string;

  /**  */
  fund?: string;

  /**  */
  feeAmount: number;

  /**  */
  productFeeId: number;
}

export interface FileResult {
  /**  */
  contents?: string;

  /**  */
  contentType?: string;

  /**  */
  inline: boolean;

  /**  */
  fileName?: string;

  /**  */
  extension?: string;

  /**  */
  fileNameWithExtension?: string;
}

export interface FileResultListApiResult {
  /**  */
  result?: FileResult[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ForgotPasswordCommand {
  /**  */
  username?: string;
}

export interface FormOptionsDto {
  /**  */
  statusCodes?: OptionItemDto[];

  /**  */
  eyeColorCodes?: OptionItemDto[];

  /**  */
  hairColorCodes?: OptionItemDto[];

  /**  */
  licenseEligibilities?: OptionItemDto[];

  /**  */
  lotteryApplicationFeePrices?: OptionItemDto[];

  /**  */
  lotteryAwardFeePrices?: OptionItemDto[];

  /**  */
  vendors?: OptionItemDto[];

  /**  */
  roles?: OptionItemDto[];

  /**  */
  eventsAdminAssignableRoles?: OptionItemDto[];

  /**  */
  licenseYears?: OptionItemDto[];

  /**  */
  lotteryYears?: OptionItemDto[];

  /**  */
  hunts?: OptionItemDto[];

  /**  */
  huntLocations?: OptionItemDto[];

  /**  */
  lotteries?: OptionItemDto[];

  /**  */
  licenseCodes?: StringFormOptionItemDto[];

  /**  */
  lotteryDisplayCategories?: OptionItemDto[];

  /**  */
  lotteryAwards?: OptionItemDto[];

  /**  */
  lotteryApplicationFees?: OptionItemDto[];

  /**  */
  lotteryAwardFees?: OptionItemDto[];

  /**  */
  usableLotteryApplicationStatuses?: OptionItemDto[];

  /**  */
  allLotteryApplicationStatuses?: OptionItemDto[];

  /**  */
  preferencePointTypes?: OptionItemDto[];

  /**  */
  instructorCertificationTypes?: OptionItemDto[];

  /**  */
  eventAreas?: OptionItemDto[];

  /**  */
  eventTypes?: OptionItemDto[];

  /**  */
  instructorTypes?: OptionItemDto[];

  /**  */
  counties?: OptionItemDto[];

  /**  */
  instructorRoleId: number;
}

export interface FormOptionsDtoApiResult {
  /**  */
  result?: FormOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface FulfillmentMethodListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  fulfillmentType?: FulfillmentType;

  /**  */
  isActive: boolean;

  /**  */
  isRecurring: boolean;

  /**  */
  timeOfDay: Date;

  /**  */
  daysOfWeek?: DayOfWeek[];

  /**  */
  setDates?: FulfillmentMethodScheduleDateDto[];
}

export interface FulfillmentMethodListItemDtoApiResult {
  /**  */
  result?: FulfillmentMethodListItemDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface FulfillmentMethodListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: FulfillmentMethodListItemDto[];
}

export interface FulfillmentMethodListItemDtoDataTablePageApiResult {
  /**  */
  result?: FulfillmentMethodListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface FulfillmentMethodScheduleDateDto {
  /**  */
  id: number;

  /**  */
  fulfillmentDate: Date;

  /**  */
  transactionStartDate?: Date;

  /**  */
  transactionEndDate: Date;

  /**  */
  fulfillmentMethodId: number;
}

export interface FundSplitListItemDto {
  /**  */
  accountDistributionCodeDescription?: string;

  /**  */
  amount: number;
}

export interface FundSplitListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: FundSplitListItemDto[];
}

export interface FundSplitListItemDtoDataTablePageApiResult {
  /**  */
  result?: FundSplitListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GenerateCertificationForStudentCommand {
  /**  */
  eventId: number;

  /**  */
  customerId: number;
}

export interface GetAchBalanceDataTableRequest {
  /**  */
  createdTimestampStart: Date;

  /**  */
  createdTimestampEnd: Date;

  /**  */
  transactionType?: string;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetAchReturnsDataTableRequest {
  /**  */
  vendorId?: number;

  /**  */
  achReturnCodeId?: number;

  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetAutoRenewalsDataTableRequest {
  /**  */
  productCode?: string;

  /**  */
  productName?: string;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetBoatRenewalTransactionTokenQuery {
  /**  */
  billingDetails?: BillingDetailsDto;

  /**  */
  boatRenewalId: number;
}

export interface GetBoatRenewalsDataTableRequest {
  /**  */
  longBoatNumber?: string;

  /**  */
  hullIdNumber?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  renewalDate?: Date;

  /**  */
  hasRenewedYesNo?: boolean;

  /**  */
  isEscalatedYesNo?: boolean;

  /**  */
  hasNotes?: boolean;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetBoatTransactionsDataTableRequest {
  /**  */
  renewalStartDate?: Date;

  /**  */
  renewalEndDate?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetCertificationsReportDataTableRequest {
  /**  */
  certificationCodeIds?: number[];

  /**  */
  certificationTypes?: number[];

  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetCertificationsReportExcelFileQuery {
  /**  */
  certificationCodeIds?: number[];

  /**  */
  certificationTypes?: number[];

  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetClassEventTypeSummaryReportExcelFileQuery {
  /**  */
  endingOnOrAfter?: Date;

  /**  */
  endingOnOrBefore?: Date;

  /**  */
  typeNames?: string[];
}

export interface GetClassEventTypeSummaryReportOptionsDto {
  /**  */
  typeNames?: StringOptionItemDto[];
}

export interface GetClassEventTypeSummaryReportOptionsDtoApiResult {
  /**  */
  result?: GetClassEventTypeSummaryReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetClassEventTypeSummaryReportQuery {
  /**  */
  endingOnOrAfter?: Date;

  /**  */
  endingOnOrBefore?: Date;

  /**  */
  typeNames?: string[];
}

export interface GetClassEventTypeSummaryReportQueryResponse {
  /**  */
  totalFilteredClassEventTypes: number;

  /**  */
  classEventTypeSummaryReportRecords?: ClassEventTypeSummaryReportRecord[];
}

export interface GetClassEventTypeSummaryReportQueryResponseApiResult {
  /**  */
  result?: GetClassEventTypeSummaryReportQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetCommercialLicenseRenewalTransactionTokenQuery {
  /**  */
  billingDetails?: BillingDetailsDto;

  /**  */
  commercialLicenses?: CommercialLicenseListItemDto[];
}

export interface GetCommercialLicenseTransactionsDataTableRequest {
  /**  */
  transactionStartDate?: Date;

  /**  */
  transactionEndDate?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetCorporationReportDataTableRequest {
  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetCorporationReportDataTableResponse {
  /**  */
  dataTablePage?: CorporationReportDtoDataTablePage;

  /**  */
  amountTotal: number;

  /**  */
  vendorFeeAmountTotal: number;

  /**  */
  grossAmountTotal: number;
}

export interface GetCorporationReportDataTableResponseApiResult {
  /**  */
  result?: GetCorporationReportDataTableResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetCourseSystemEnrollmentDataTableReportQuery {
  /**  */
  purchaseStartDate?: Date;

  /**  */
  purchaseEndDate?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetCourseSystemEnrollmentDataTableRequest {
  /**  */
  purchaseStartDate?: Date;

  /**  */
  purchaseEndDate?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetCourseSystemEnrollmentLoginUrlResponseDto {
  /**  */
  enrollmentIdentifier?: string;

  /**  */
  loginUrl?: string;
}

export interface GetCourseSystemEnrollmentLoginUrlResponseDtoListApiResult {
  /**  */
  result?: GetCourseSystemEnrollmentLoginUrlResponseDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetCustomerCertificatePdfForLoggedInUserQuery {
  /**  */
  customerCertificationId: number;
}

export interface GetCustomerCertificatePdfQuery {
  /**  */
  customerCertificationId: number;
}

export interface GetCustomerLicenseExportPdfQuery {
  /**  */
  transactionCustomerProductIds?: number[];

  /**  */
  viewInNewTab: boolean;
}

export interface GetCustomerProductCustomDataTableQuery {
  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetCustomerTagsExportPdfQuery {
  /**  */
  customerId: number;

  /**  */
  viewInNewTab: boolean;
}

export interface GetDailyDuckStampPurchasesExportCsvFileQuery {
  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;
}

export interface GetEmailNotificationsTableRequest {
  /**  */
  year?: number;

  /**  */
  lotteryIds?: number[];

  /**  */
  preferencePointTypeIds?: number[];

  /**  */
  isEnabled?: boolean;

  /**  */
  actionType?: LotteryNotificationActions;

  /**  */
  sendWhen?: NotificationSendingOptions;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetEventByMonthQuery {
  /**  */
  startDate: Date;

  /**  */
  endDate: Date;
}

export interface GetEventInstructorActivityOptionsDto {
  /**  */
  activityTypes?: OptionItemDto[];
}

export interface GetEventInstructorActivityOptionsDtoApiResult {
  /**  */
  result?: GetEventInstructorActivityOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetEventResultsSummaryExcelFileQuery {
  /**  */
  showAllInstructorCourses?: boolean;

  /**  */
  eventId?: number;

  /**  */
  eventTypeIds?: number[];

  /**  */
  instructorIds?: number[];

  /**  */
  countyCodeIds?: number[];

  /**  */
  classEventTypeIds?: number[];

  /**  */
  zipCode?: string;

  /**  */
  beginDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  skip: number;

  /**  */
  take: number;
}

export interface GetEventResultsSummaryQuery {
  /**  */
  showAllInstructorCourses?: boolean;

  /**  */
  eventId?: number;

  /**  */
  eventTypeIds?: number[];

  /**  */
  instructorIds?: number[];

  /**  */
  countyCodeIds?: number[];

  /**  */
  classEventTypeIds?: number[];

  /**  */
  zipCode?: string;

  /**  */
  beginDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  skip: number;

  /**  */
  take: number;
}

export interface GetEventResultsSummaryQueryResponse {
  /**  */
  totalFilteredEvents: number;

  /**  */
  eventsSummary?: EventSummary[];
}

export interface GetEventResultsSummaryQueryResponseApiResult {
  /**  */
  result?: GetEventResultsSummaryQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetEventRosterExportExcelFileQuery {
  /**  */
  eventId: number;
}

export interface GetEventRosterExportPdfFileQuery {
  /**  */
  eventId: number;
}

export interface GetEventStudentsQueryResponse {
  /**  */
  customerEvents?: CustomerEventListDto[];

  /**  */
  certifications?: EventCertificationDto[];

  /**  */
  customerEventWaitlist?: CustomerEventWaitlistListItemDto[];
}

export interface GetEventStudentsQueryResponseApiResult {
  /**  */
  result?: GetEventStudentsQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetEventsForCustomerQueryResponse {
  /**  */
  registeredEvents?: IspEventListItemDto[];

  /**  */
  waitlistedEvents?: IspEventListItemDto[];
}

export interface GetEventsForCustomerQueryResponseApiResult {
  /**  */
  result?: GetEventsForCustomerQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetFilteredEventsForExportQuery {
  /**  */
  resultCountToShow: number;

  /**  */
  eventId?: number;

  /**  */
  city?: string;

  /**  */
  eventAvailability?: string;

  /**  */
  eventStartDate?: Date;

  /**  */
  eventEndDate?: Date;

  /**  */
  statusCodes?: string[];

  /**  */
  eventInstructorsIds?: number[];

  /**  */
  eventAreaIds?: number[];

  /**  */
  countyCodeIds?: number[];

  /**  */
  eventLocationIds?: number[];

  /**  */
  classEventTypeIds?: number[];
}

export interface GetFilteredEventsQuery {
  /**  */
  eventId?: number;

  /**  */
  countPerPage?: number;

  /**  */
  pageNumber?: number;

  /**  */
  city?: string;

  /**  */
  eventAvailability?: string;

  /**  */
  eventStartDate?: Date;

  /**  */
  eventEndDate?: Date;

  /**  */
  statusCodes?: string[];

  /**  */
  eventInstructorsIds?: number[];

  /**  */
  eventAreaIds?: number[];

  /**  */
  countyCodeIds?: number[];

  /**  */
  eventLocationIds?: number[];

  /**  */
  classEventTypeIds?: number[];
}

export interface GetFilteredEventsQueryResponse {
  /**  */
  eventListItemDtos?: EventListItemDto[];

  /**  */
  totalNumberOfPages: number;

  /**  */
  resetPageNumber: boolean;
}

export interface GetFilteredEventsQueryResponseApiResult {
  /**  */
  result?: GetFilteredEventsQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetFulfillmentMethodTableRequest {
  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetFundSplitDataTableRequest {
  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetHardCardDataTableRequest {
  /**  */
  createdTimestampStart: Date;

  /**  */
  createdTimestampEnd: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetHarvestDto {
  /**  */
  id: number;

  /**  */
  transactionTagId: number;

  /**  */
  confirmationNumber?: string;

  /**  */
  dateKilled: Date;

  /**  */
  countyCodeId: number;

  /**  */
  landTypeCode?: LandTypeCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  antlerCode?: AntlerCode;

  /**  */
  deerAreaId?: number;

  /**  */
  validationMethod?: ValidationMethod;

  /**  */
  turkeyMaturityCode?: TurkeyMaturityCode;

  /**  */
  harvestImageUri?: string;
}

export interface GetHarvestDtoApiResult {
  /**  */
  result?: GetHarvestDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetHarvestDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: GetHarvestDto[];
}

export interface GetHarvestDtoDataTablePageApiResult {
  /**  */
  result?: GetHarvestDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetHarvestsForCustomerDataTableRequest {
  /**  */
  customerId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetHipFileExportTxtFileQuery {
  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;
}

export interface GetImportedCertificationsReportDataTableRequest {
  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetImportedCertificationsReportExcelFileQuery {
  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetInstructorCertDateReportExcelFileQuery {
  /**  */
  createdOnOrAfterDate?: Date;

  /**  */
  createdOnOrBeforeDate?: Date;

  /**  */
  certificationCodeIds?: number[];

  /**  */
  instructorCountyCodeIds?: number[];

  /**  */
  certificationStatus?: boolean;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  sortBy?: InstructorCertReportSortBy;
}

export interface GetInstructorCertDateReportQuery {
  /**  */
  createdOnOrAfterDate?: Date;

  /**  */
  createdOnOrBeforeDate?: Date;

  /**  */
  certificationCodeIds?: number[];

  /**  */
  instructorCountyCodeIds?: number[];

  /**  */
  certificationStatus?: boolean;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  sortBy?: InstructorCertReportSortBy;
}

export interface GetInstructorCertDateReportQueryResponse {
  /**  */
  totalFilteredInstructorCerts: number;

  /**  */
  instructorCertDateReportRecords?: InstructorCertDateReportRecord[];
}

export interface GetInstructorCertDateReportQueryResponseApiResult {
  /**  */
  result?: GetInstructorCertDateReportQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetInstructorCertificatePdfQuery {
  /**  */
  instructorCertificationId: number;
}

export interface GetInstructorExpirationExcelReportQuery {
  /**  */
  month?: number;

  /**  */
  year?: number;

  /**  */
  countyCodeIds?: number[];

  /**  */
  areaIds?: number[];

  /**  */
  instructorCertificationCodeIds?: number[];

  /**  */
  skip: number;

  /**  */
  take: number;
}

export interface GetInstructorExpirationQuery {
  /**  */
  month?: number;

  /**  */
  year?: number;

  /**  */
  countyCodeIds?: number[];

  /**  */
  areaIds?: number[];

  /**  */
  instructorCertificationCodeIds?: number[];

  /**  */
  skip: number;

  /**  */
  take: number;
}

export interface GetInstructorExpirationQueryResponse {
  /**  */
  recordsCount: number;

  /**  */
  records?: InstructorExpirationSummary[];
}

export interface GetInstructorExpirationQueryResponseApiResult {
  /**  */
  result?: GetInstructorExpirationQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetInstructorsExcelFileQuery {
  /**  */
  take: number;

  /**  */
  countyCodes?: string[];

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetInstructorsSummaryExcelFileQuery {
  /**  */
  eventDepartmentIds?: number[];

  /**  */
  instructorCountyCodeIds?: number[];

  /**  */
  instructorTypeIds?: number[];

  /**  */
  certificationCodeIds?: number[];

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  beginDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  eventParishIds?: number[];

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  sortBy?: InstructorSummarySortBy;
}

export interface GetInstructorsSummaryQuery {
  /**  */
  eventDepartmentIds?: number[];

  /**  */
  instructorCountyCodeIds?: number[];

  /**  */
  instructorTypeIds?: number[];

  /**  */
  certificationCodeIds?: number[];

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  beginDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  eventParishIds?: number[];

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  sortBy?: InstructorSummarySortBy;
}

export interface GetInstructorsSummaryQueryResponse {
  /**  */
  totalFilteredInstructors: number;

  /**  */
  instructorsSummary?: InstructorSummary[];
}

export interface GetInstructorsSummaryQueryResponseApiResult {
  /**  */
  result?: GetInstructorsSummaryQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetJournalDistributionsDataTableRequest {
  /**  */
  journalId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetJournalTransactionsDataTableRequest {
  /**  */
  journalId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetJournalsByVendorDataTableRequest {
  /**  */
  vendorId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetJournalsDataTableRequest {
  /**  */
  vendorId?: number;

  /**  */
  journalId?: number;

  /**  */
  journalTypeId?: number;

  /**  */
  statusCodeId?: number;

  /**  */
  createdTimestamp?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetLifetimeLicenseDataTableRequest {
  /**  */
  ldwfNumber?: string;

  /**  */
  includeExported: boolean;

  /**  */
  endDate?: Date;

  /**  */
  startDate?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetLocationClassSalesDataTableRequest {
  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetLotteryApplicantTableRequest {
  /**  */
  awardAccepted?: boolean;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  lotteryId?: number;

  /**  */
  feePaid?: boolean;

  /**  */
  groupCode?: string;

  /**  */
  onlyShowLeftovers?: boolean;

  /**  */
  onlyShowApplicantsEligibleToDisqualify?: boolean;

  /**  */
  statusIds?: number[];

  /**  */
  chosenHuntIds?: number[];

  /**  */
  awardedHuntIds?: number[];

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetLotteryEmailNotificationsForCustomerTableRequest {
  /**  */
  customerId: number;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetLotteryTransactionSumsByAchBatchQuery {
  /**  */
  achBatchId: number;

  /**  */
  fundId: number;

  /**  */
  month: number;

  /**  */
  year: number;

  /**  */
  monthYear?: string;
}

export interface GetLotteryTransactionSumsByBatchResult {
  /**  */
  applicationTotal: number;

  /**  */
  awardTotal: number;
}

export interface GetLotteryTransactionSumsByBatchResultApiResult {
  /**  */
  result?: GetLotteryTransactionSumsByBatchResult;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetLotteryTransactionsByAchBatchDataTableRequest {
  /**  */
  achBatchId: number;

  /**  */
  fundId: number;

  /**  */
  month: number;

  /**  */
  year: number;

  /**  */
  monthYear?: string;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetMonthlyAchDataTableRequest {
  /**  */
  monthYear?: string;

  /**  */
  month: number;

  /**  */
  year: number;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetNonAchDataTableRequest {
  /**  */
  createdTimestampStart: Date;

  /**  */
  createdTimestampEnd: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetNonEftSalesRecDataTableRequest {
  /**  */
  createdTimestampStart: Date;

  /**  */
  createdTimestampEnd: Date;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetOnlineCourseProductByCourseCodeQuery {
  /**  */
  courseCode?: string;
}

export interface GetParticipantsReportExcelFileQuery {
  /**  */
  beginDateRange?: Date;

  /**  */
  endDateRange?: Date;

  /**  */
  grade?: ParticipantsReportGradeStatus;

  /**  */
  classEventTypeAndOnlineCourseTypeNames?: string[];

  /**  */
  eventCountyCodeIds?: number[];

  /**  */
  skip: number;

  /**  */
  take: number;
}

export interface GetParticipantsReportQuery {
  /**  */
  beginDateRange?: Date;

  /**  */
  endDateRange?: Date;

  /**  */
  grade?: ParticipantsReportGradeStatus;

  /**  */
  classEventTypeAndOnlineCourseTypeNames?: string[];

  /**  */
  eventCountyCodeIds?: number[];

  /**  */
  skip: number;

  /**  */
  take: number;
}

export interface GetParticipantsReportQueryResponse {
  /**  */
  totalFilteredItems: number;

  /**  */
  participantsReportRecords?: ParticipantsReportRecord[];
}

export interface GetParticipantsReportQueryResponseApiResult {
  /**  */
  result?: GetParticipantsReportQueryResponse;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetParticipationCertificatePdfQuery {
  /**  */
  customerEventId: number;
}

export interface GetPrivilegeFeeReportExportExcelFileQuery {
  /**  */
  startDate: Date;

  /**  */
  endDate: Date;
}

export interface GetProductSalesVolumeDataTableRequest {
  /**  */
  productCodes?: string[];

  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetProofOfOnlineCourseCompletionPdfExportQuery {
  /**  */
  courseSystemEnrollmentId: number;
}

export interface GetSeasonalHarvestDataTableRequest {
  /**  */
  species?: HarvestReportSpecies;

  /**  */
  ldwfNumber?: string;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  isDmapFile: boolean;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetSingleHuntFieldReportFileQuery {
  /**  */
  id?: number;
}

export interface GetStatementJournalsDataTableRequest {
  /**  */
  statementId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetStatementsDataTableRequest {
  /**  */
  vendorId?: number;

  /**  */
  statementId?: number;

  /**  */
  statusCodeId?: number;

  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetStatewideSalesDetailsDataTableRequest {
  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetTransactionCustomerDataTableRequest {
  /**  */
  customerId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetTransactionCustomerLicenseExportPdfQuery {
  /**  */
  transactionId: number;

  /**  */
  viewInNewTab: boolean;
}

export interface GetTransactionCustomerProductByIdDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  transactionId: number;

  /**  */
  customerName?: string;

  /**  */
  sportsmanId?: string;

  /**  */
  licenseYear: number;

  /**  */
  productName?: string;

  /**  */
  productCode?: string;

  /**  */
  isLifetime: boolean;

  /**  */
  wasExported: boolean;

  /**  */
  dateIssued: Date;

  /**  */
  licenseValidStartDate: Date;

  /**  */
  licenseValidEndDate: Date;

  /**  */
  permitNumber?: string;

  /**  */
  stateFee: number;

  /**  */
  totalFee: number;

  /**  */
  vendor?: string;

  /**  */
  vendorUserName?: string;

  /**  */
  journalId: number;

  /**  */
  transactionStatus?: string;

  /**  */
  transactionNumber?: string;

  /**  */
  isStartDateSelectable: boolean;

  /**  */
  hasPssFufillmentMethod: boolean;

  /**  */
  statusDtos?: TransactionCustomerProductStatusDto[];

  /**  */
  tags?: TransactionTagListItemDto[];
}

export interface GetTransactionCustomerProductByIdDtoApiResult {
  /**  */
  result?: GetTransactionCustomerProductByIdDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetTransactionDataTableRequest {
  /**  */
  customerId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetTransactionFulfillmentTableRequest {
  /**  */
  fulfillmentMethodId?: number;

  /**  */
  processedDate?: Date;

  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  processType?: ProcessType;

  /**  */
  ldwfNumber?: string;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetTransactionTagDataTableRequest {
  /**  */
  sportsmanId?: string;

  /**  */
  dateIssued?: Date;

  /**  */
  validationDate?: Date;

  /**  */
  tagNumber?: string;

  /**  */
  tagType?: string;

  /**  */
  licenseYear?: number;

  /**  */
  validationMethod?: ValidationMethod;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetUnpaidCommercialLicensesByEnteredLicenseNumberQuery {
  /**  */
  licenseNumber?: string;
}

export interface GetVendorClerkSalesReportPdfFileQuery {
  /**  */
  vendorId?: number;

  /**  */
  beginDate: Date;

  /**  */
  endDate: Date;
}

export interface GetVendorFundSplitDataTableRequest {
  /**  */
  vendorTypeCodeType?: string;

  /**  */
  vendorId?: number;

  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetVendorMySalesReportPdfFileQuery {
  /**  */
  clerkId?: number;

  /**  */
  vendorId?: number;

  /**  */
  beginDate: Date;

  /**  */
  endDate: Date;
}

export interface GetVendorPivQuery {
  /**  */
  vendorId: number;
}

export interface GetVendorPivReturnDto {
  /**  */
  pivNumber?: string;

  /**  */
  depositDate: Date;
}

export interface GetVendorPivReturnDtoApiResult {
  /**  */
  result?: GetVendorPivReturnDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface GetVendorReportClerkRequest {
  /**  */
  clerkUserId?: number;

  /**  */
  vendorReportName?: string;

  /**  */
  beginDate: Date;

  /**  */
  endDate: Date;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetVendorReportRequest {
  /**  */
  beginDate: Date;

  /**  */
  endDate: Date;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetVendorSaleDailySummariesDataTableRequest {
  /**  */
  vendorId: number;

  /**  */
  createdTimestampStart?: Date;

  /**  */
  createdTimestampEnd?: Date;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetVendorSalesByDateDataTableRequest {
  /**  */
  vendorId: number;

  /**  */
  createdTimestampDate: Date;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GetVendorSalesReportPdfFileQuery {
  /**  */
  vendorId?: number;

  /**  */
  beginDate: Date;

  /**  */
  endDate: Date;
}

export interface GetVendorVoidSalesReportPdfFileQuery {
  /**  */
  vendorId?: number;

  /**  */
  beginDate: Date;

  /**  */
  endDate: Date;
}

export interface GetWeeklyAchDataTableRequest {
  /**  */
  achBatchId: number;

  /**  */
  vendorId?: number;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface GroupApplicationDetailsDto {
  /**  */
  code?: string;

  /**  */
  leader?: string;

  /**  */
  hunts?: HuntListItemDto[];
}

export interface GroupApplicationDetailsDtoApiResult {
  /**  */
  result?: GroupApplicationDetailsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HardCardSalesListItemDto {
  /**  */
  productCode?: string;

  /**  */
  productYear?: number;

  /**  */
  imageCode?: string;

  /**  */
  amountSold: number;
}

export interface HardCardSalesListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: HardCardSalesListItemDto[];
}

export interface HardCardSalesListItemDtoDataTablePageApiResult {
  /**  */
  result?: HardCardSalesListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HarvestCustomer {
  /**  */
  id: number;

  /**  */
  ldwfNumber?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  tagCount: number;

  /**  */
  thisYearTagCount: number;

  /**  */
  monthsSinceLastTransaction: number;

  /**  */
  yearsSinceLastTransaction: number;

  /**  */
  age: number;
}

export interface HarvestCustomerListApiResult {
  /**  */
  result?: HarvestCustomer[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HarvestExportDto {
  /**  */
  species?: string;

  /**  */
  licenseYear: number;

  /**  */
  ldwfNumber?: string;

  /**  */
  countyCodeName?: string;

  /**  */
  landTypeCode?: string;

  /**  */
  deerAreaDescription?: string;

  /**  */
  genderCode?: string;

  /**  */
  turkeyMaturityCode?: string;

  /**  */
  isTurkey: boolean;
}

export interface HarvestExportDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: HarvestExportDto[];
}

export interface HarvestExportDtoDataTablePageApiResult {
  /**  */
  result?: HarvestExportDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HarvestOptionsDto {
  /**  */
  counties?: OptionItemDto[];

  /**  */
  deerAreas?: OptionItemDto[];

  /**  */
  countyCodeDeerAreas?: CountyCodeDeerAreaDto[];
}

export interface HarvestOptionsDtoApiResult {
  /**  */
  result?: HarvestOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HarvestSeasonCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  isActive: boolean;

  /**  */
  licenseYear: number;

  /**  */
  harvestSpecies?: HarvestSpecies;

  /**  */
  seasonStartDate: Date;

  /**  */
  seasonEndDate: Date;
}

export interface HarvestSeasonDataTableRequest {
  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface HarvestSeasonListItemDto {
  /**  */
  id: number;

  /**  */
  isActive: boolean;

  /**  */
  licenseYear: number;

  /**  */
  harvestSpecies?: HarvestSpecies;

  /**  */
  seasonStartDate: Date;

  /**  */
  seasonEndDate: Date;
}

export interface HarvestSeasonListItemDtoApiResult {
  /**  */
  result?: HarvestSeasonListItemDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HarvestSeasonListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: HarvestSeasonListItemDto[];
}

export interface HarvestSeasonListItemDtoDataTablePageApiResult {
  /**  */
  result?: HarvestSeasonListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HeaderInfo {
  /**  */
  count: number;

  /**  */
  message?: string;

  /**  */
  severity: number;
}

export interface HipSurveyDto {
  /**  */
  id: number;

  /**  */
  licenseYear: number;

  /**  */
  isHuntingBirds: boolean;

  /**  */
  ducksBagged: number;

  /**  */
  geeseBagged: number;

  /**  */
  dovesBagged: number;

  /**  */
  woodcockHunted: number;

  /**  */
  cootsAndSnipeHunted: number;

  /**  */
  railsAndGallinulesHunted: number;

  /**  */
  customerId: number;

  /**  */
  transactionId: number;

  /**  */
  transactionCustomerId: number;
}

export interface HuntChoiceDto {
  /**  */
  huntDisplayName?: string;

  /**  */
  huntId: number;

  /**  */
  rank: number;
}

export interface HuntCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  lotteryId: number;

  /**  */
  huntLocationId: number;

  /**  */
  isHuntFillAvailable: boolean;

  /**  */
  quota: number;

  /**  */
  code?: string;

  /**  */
  description?: string;

  /**  */
  additionalInformation?: string;

  /**  */
  huntStatusCode?: HuntStatusCode;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;
}

export interface HuntCreateUpdateDtoApiResult {
  /**  */
  result?: HuntCreateUpdateDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HuntFillRequestDto {
  /**  */
  newAcceptanceDeadline?: Date;

  /**  */
  huntId: number;
}

export interface HuntListItemDto {
  /**  */
  id: number;

  /**  */
  isSuccessful: boolean;

  /**  */
  rank: number;

  /**  */
  name?: string;

  /**  */
  lotteryId: number;

  /**  */
  lotteryName?: string;

  /**  */
  description?: string;

  /**  */
  huntLocation?: HuntLocationListItemDto;

  /**  */
  huntLocationName?: string;

  /**  */
  quota: number;

  /**  */
  applicantCount: number;

  /**  */
  remainingSpotsCount?: number;

  /**  */
  code?: string;

  /**  */
  huntStatusCode?: HuntStatusCode;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;
}

export interface HuntListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: HuntListItemDto[];
}

export interface HuntListItemDtoDataTablePageApiResult {
  /**  */
  result?: HuntListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HuntLocationCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  huntLocationStatusCode?: HuntLocationStatusCode;

  /**  */
  name?: string;

  /**  */
  directions?: string;

  /**  */
  notes?: string;

  /**  */
  contactName?: string;

  /**  */
  contactPhone?: string;

  /**  */
  contactEmail?: string;

  /**  */
  address?: AddressDto;
}

export interface HuntLocationCreateUpdateDtoApiResult {
  /**  */
  result?: HuntLocationCreateUpdateDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HuntLocationListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  huntLocationStatusCode?: HuntLocationStatusCode;

  /**  */
  streetAddress1?: string;

  /**  */
  countyCode?: string;

  /**  */
  city?: string;

  /**  */
  zipCode?: string;

  /**  */
  plus4?: string;
}

export interface HuntLocationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: HuntLocationListItemDto[];
}

export interface HuntLocationListItemDtoDataTablePageApiResult {
  /**  */
  result?: HuntLocationListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HuntSelectionDto {
  /**  */
  lotteryId: number;

  /**  */
  quantity: number;

  /**  */
  isFeePaidOnline: boolean;

  /**  */
  selectedHunts?: HuntSelectionItemDto[];
}

export interface HuntSelectionItemDto {
  /**  */
  huntId: number;

  /**  */
  rank: number;
}

export interface HuntSelectionItemDtoListApiResult {
  /**  */
  result?: HuntSelectionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface HuntSelectionListItemDto {
  /**  */
  id: number;

  /**  */
  huntId: number;

  /**  */
  quota: number;

  /**  */
  availableSlots: number;

  /**  */
  rank: number;

  /**  */
  code?: string;

  /**  */
  description?: string;

  /**  */
  locationName?: string;

  /**  */
  additionalInformation?: string;

  /**  */
  applicantCount?: string;

  /**  */
  previousYearQuota?: string;

  /**  */
  previousYearApplicantCount?: string;

  /**  */
  previousYearSuccessRate?: string;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;
}

export interface ImportedCertificationsReportListItemDto {
  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  fullName?: string;

  /**  */
  certificationCodeDescription?: string;

  /**  */
  certificationDateString?: string;
}

export interface ImportedCertificationsReportListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: ImportedCertificationsReportListItemDto[];
}

export interface ImportedCertificationsReportListItemDtoDataTablePageApiResult {
  /**  */
  result?: ImportedCertificationsReportListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ImportedCertificationsReportOptionsDto {
  /**  */
  certificationCodes?: OptionItemDto[];
}

export interface ImportedCertificationsReportOptionsDtoApiResult {
  /**  */
  result?: ImportedCertificationsReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface InstructorCertDateReportRecord {
  /**  */
  certificationNumber?: string;

  /**  */
  certificationCodeDescription?: string;

  /**  */
  certificationStatus?: string;

  /**  */
  name?: string;

  /**  */
  instructorAddressOneLine?: string;

  /**  */
  instructorAddressStreet1?: string;

  /**  */
  instructorAddressStreet2?: string;

  /**  */
  instructorAddressCity?: string;

  /**  */
  instructorAddressZipCode?: string;

  /**  */
  instructorAddressState?: string;

  /**  */
  instructorParish?: string;

  /**  */
  instructorPhone?: string;

  /**  */
  instructorEmail?: string;

  /**  */
  certificationDate?: Date;

  /**  */
  dateLastTaught?: Date;

  /**  */
  certificationDateFormatted?: string;

  /**  */
  dateLastTaughtFormatted?: string;

  /**  */
  coursesCount: number;

  /**  */
  trainingHours: number;

  /**  */
  prepHours: number;

  /**  */
  inClassHours: number;

  /**  */
  adminHours: number;

  /**  */
  fieldHours: number;

  /**  */
  travelHours: number;

  /**  */
  totalHours: number;
}

export interface InstructorCertReportOptionsDto {
  /**  */
  counties?: OptionItemDto[];

  /**  */
  certificationTypes?: OptionItemDto[];
}

export interface InstructorCertReportOptionsDtoApiResult {
  /**  */
  result?: InstructorCertReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface InstructorCertificationDetailDto {
  /**  */
  certificationNumber?: string;

  /**  */
  certificationCodeId: number;

  /**  */
  stateCode?: string;

  /**  */
  description?: string;

  /**  */
  id: number;

  /**  */
  instructorId: number;

  /**  */
  certificationId: number;

  /**  */
  renewalDate?: Date;

  /**  */
  isExpired: boolean;

  /**  */
  certificationDate?: Date;

  /**  */
  dateLastTaught?: Date;

  /**  */
  isActive: boolean;
}

export interface InstructorCertificationListDto {
  /**  */
  id: number;

  /**  */
  userId: number;

  /**  */
  certificationCodeDescription?: string;

  /**  */
  certificationCodeId: number;

  /**  */
  certificationNumber?: string;

  /**  */
  certificationDate?: Date;

  /**  */
  renewalDate?: Date;

  /**  */
  dateLastTaught?: Date;

  /**  */
  isActive: boolean;
}

export interface InstructorDataTableRequest {
  /**  */
  take: number;

  /**  */
  countyCodes?: string[];

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface InstructorExpirationOptionsDto {
  /**  */
  months?: OptionItemDto[];

  /**  */
  years?: OptionItemDto[];

  /**  */
  counties?: OptionItemDto[];

  /**  */
  areas?: OptionItemDto[];

  /**  */
  instructorCertificationCodes?: OptionItemDto[];
}

export interface InstructorExpirationOptionsDtoApiResult {
  /**  */
  result?: InstructorExpirationOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface InstructorExpirationSummary {
  /**  */
  instructorNumber?: string;

  /**  */
  instructorName?: string;

  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  addressLine3?: string;

  /**  */
  addressSingleLine?: string;

  /**  */
  parish?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  certificationType?: string;

  /**  */
  certificationDate?: string;

  /**  */
  dateLastTaught?: string;

  /**  */
  renewalDate?: string;
}

export interface InstructorListItemDto {
  /**  */
  id: number;

  /**  */
  userId: number;

  /**  */
  userName?: string;

  /**  */
  fullName?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  instructorStatusCode?: string;

  /**  */
  role?: string;

  /**  */
  countyCodes?: string[];

  /**  */
  createdTimestamp: Date;

  /**  */
  email?: string;

  /**  */
  instructorType?: string;

  /**  */
  lastAccess?: Date;

  /**  */
  instructorCertification?: string;

  /**  */
  instructorNumber?: string;

  /**  */
  parishName?: string;

  /**  */
  instructorCertifications?: InstructorCertificationListDto[];
}

export interface InstructorListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: InstructorListItemDto[];
}

export interface InstructorListItemDtoDataTablePageApiResult {
  /**  */
  result?: InstructorListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface InstructorListItemDtoListApiResult {
  /**  */
  result?: InstructorListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface InstructorSummary {
  /**  */
  instructorId: number;

  /**  */
  instructorNumber?: string;

  /**  */
  name?: string;

  /**  */
  instructorType?: string;

  /**  */
  certificationCodeDescription?: string;

  /**  */
  certificationNumber?: string;

  /**  */
  instructorAddressOneLine?: string;

  /**  */
  instructorAddressStreet1?: string;

  /**  */
  instructorAddressStreet2?: string;

  /**  */
  instructorAddressCity?: string;

  /**  */
  instructorAddressZipCode?: string;

  /**  */
  instructorAddressState?: string;

  /**  */
  instructorParish?: string;

  /**  */
  instructorPhone?: string;

  /**  */
  instructorEmail?: string;

  /**  */
  coursesCount: number;

  /**  */
  coursesPrimaryCount: number;

  /**  */
  studentsCount: number;

  /**  */
  studentsPassedCount: number;

  /**  */
  studentsPassedPercentage: number;

  /**  */
  trainingHours: number;

  /**  */
  prepHours: number;

  /**  */
  inClassHours: number;

  /**  */
  adminHours: number;

  /**  */
  fieldHours: number;

  /**  */
  travelHours: number;

  /**  */
  totalHours: number;
}

export interface InstructorSummaryOptionsDto {
  /**  */
  eventDepartments?: OptionItemDto[];

  /**  */
  counties?: OptionItemDto[];

  /**  */
  instructorTypes?: OptionItemDto[];

  /**  */
  certificationTypes?: OptionItemDto[];
}

export interface InstructorSummaryOptionsDtoApiResult {
  /**  */
  result?: InstructorSummaryOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface InstructorTypeDto {
  /**  */
  id: number;

  /**  */
  name?: string;
}

export interface InstructorTypeDtoApiResult {
  /**  */
  result?: InstructorTypeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface InstructorTypeListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;
}

export interface InstructorTypeListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: InstructorTypeListItemDto[];
}

export interface InstructorTypeListItemDtoDataTablePageApiResult {
  /**  */
  result?: InstructorTypeListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface InstructorUserDto {
  /**  */
  id: number;

  /**  */
  statusCodeId: number;

  /**  */
  customerId?: number;

  /**  */
  vendorId?: number;

  /**  */
  vendor?: string;

  /**  */
  emailConfirmed: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  countyCodeId?: number;

  /**  */
  email?: string;

  /**  */
  userName?: string;

  /**  */
  comments?: string;

  /**  */
  userRole?: number;

  /**  */
  instructorTypeId?: number;

  /**  */
  instructorId?: number;

  /**  */
  instructorNumber?: string;

  /**  */
  instructorAddress?: AddressDto;

  /**  */
  instructorEmailAddress?: string;

  /**  */
  instructorPhoneNumber?: string;

  /**  */
  instructorCertifications?: InstructorCertificationDetailDto[];

  /**  */
  assignedToAllEventAreas: boolean;

  /**  */
  eventAreaIds?: number[];

  /**  */
  assignedToAllEventTypes: boolean;

  /**  */
  eventTypeIds?: number[];

  /**  */
  claims?: string[];

  /**  */
  role?: string;
}

export interface InstructorUserDtoApiResult {
  /**  */
  result?: InstructorUserDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface Int32ApiResult {
  /**  */
  result: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface IspAndVendorUpdateSmsPreferencesCommand {
  /**  */
  enableSmsNotifications: boolean;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  legalConfirmation: boolean;
}

export interface IspCreateCustomerCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface IspCustomerEventListDto {
  /**  */
  id: number;

  /**  */
  isDeleted: boolean;
}

export interface IspCustomerEventWaitlistListItemDto {
  /**  */
  id: number;
}

export interface IspEventDetailDto {
  /**  */
  id: number;

  /**  */
  classEventTypeId?: number;

  /**  */
  classEventTypeName?: string;

  /**  */
  classEventType?: ClassEventTypeDto;

  /**  */
  countyCodeId?: number;

  /**  */
  eventLocationId?: number;

  /**  */
  displayStartDate: Date;

  /**  */
  displayEndDate: Date;

  /**  */
  capacity?: number;

  /**  */
  showOnline: boolean;

  /**  */
  eventStatusCodeId?: number;

  /**  */
  eventStatusCode?: EventStatusCodeDto;

  /**  */
  isPrivate: boolean;

  /**  */
  privatePasscode?: string;

  /**  */
  hasWaitList: boolean;

  /**  */
  description?: string;

  /**  */
  internalNotes?: string;

  /**  */
  address?: AddressDto;

  /**  */
  location?: string;

  /**  */
  city?: string;

  /**  */
  isCancelled: boolean;

  /**  */
  numberOfStudentsInClass: number;

  /**  */
  isInstructorClass: boolean;

  /**  */
  eventDateTimes?: EventDateTimeDto[];

  /**  */
  eventInstructors?: IspEventInstructorListItemDto[];

  /**  */
  students?: IspCustomerEventListDto[];

  /**  */
  waitlistedStudents?: IspCustomerEventWaitlistListItemDto[];
}

export interface IspEventDetailDtoApiResult {
  /**  */
  result?: IspEventDetailDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface IspEventInstructorListItemDto {
  /**  */
  isVerified: boolean;

  /**  */
  isPrimary: boolean;

  /**  */
  instructorId: number;

  /**  */
  eventId: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  fullName?: string;

  /**  */
  hasEmail: boolean;
}

export interface IspEventListItemDto {
  /**  */
  id: number;

  /**  */
  description?: string;

  /**  */
  classEventType?: string;

  /**  */
  classType?: string;

  /**  */
  classTypeId: number;

  /**  */
  eventType?: string;

  /**  */
  eventTypeId: number;

  /**  */
  countyCode?: string;

  /**  */
  location?: string;

  /**  */
  displayStartDate: Date;

  /**  */
  displayEndDate: Date;

  /**  */
  eventDateTimes?: EventDateTimeDto[];

  /**  */
  capacity?: number;

  /**  */
  numberOfStudentsInClass?: number;

  /**  */
  eventStatusCode?: string;

  /**  */
  city?: string;

  /**  */
  address?: AddressDto;

  /**  */
  customersCanOnlyApplyToWaitlist: boolean;

  /**  */
  eventInstructors?: IspEventInstructorListItemDto[];
}

export interface IspEventListItemDtoListApiResult {
  /**  */
  result?: IspEventListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface IspUpdateCustomerCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface IspUpdateCustomerWithResidencyVerificationCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface IspValidateUpdateCustomerWithResidencyVerificationCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface IspVerifyCustomerCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface IssueDuplicateDto {
  /**  */
  pastTransactions?: IssueDuplicateTransactionDto[];

  /**  */
  replacementProduct?: CatalogProductDto;

  /**  */
  duplicateLifetimeProduct?: CatalogProductDto;

  /**  */
  hasRevocation: boolean;
}

export interface IssueDuplicateDtoApiResult {
  /**  */
  result?: IssueDuplicateDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface IssueDuplicateProductDto {
  /**  */
  transactionCustomerProductId: number;

  /**  */
  productId: number;

  /**  */
  name?: string;

  /**  */
  code?: string;

  /**  */
  vendor?: string;

  /**  */
  isLifetime: boolean;

  /**  */
  createdTimestamp: Date;

  /**  */
  licenseValidStartDate?: Date;

  /**  */
  licenseValidEndDate?: Date;
}

export interface IssueDuplicateTransactionDto {
  /**  */
  id: number;

  /**  */
  transactionNumber?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  products?: IssueDuplicateProductDto[];
}

export interface JournalACHBatchDetailDto {
  /**  */
  amount: number;

  /**  */
  accountNumber?: string;

  /**  */
  bankAccountType?: string;

  /**  */
  routingNumber?: string;

  /**  */
  createdTimestamp: Date;
}

export interface JournalDetailsDto {
  /**  */
  id: number;

  /**  */
  vendorName?: string;

  /**  */
  statementId?: number;

  /**  */
  accountDistributionCodeId?: string;

  /**  */
  journalType?: string;

  /**  */
  status?: string;

  /**  */
  amount: number;

  /**  */
  outstandingAmount: number;

  /**  */
  commission?: number;

  /**  */
  gross: number;

  /**  */
  comment?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  achProblem?: ACHProblemDto;
}

export interface JournalDetailsDtoApiResult {
  /**  */
  result?: JournalDetailsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface JournalDistributionListItemDto {
  /**  */
  accountDistributionCodeDescription?: string;

  /**  */
  distributionAmount: number;
}

export interface JournalDistributionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: JournalDistributionListItemDto[];
}

export interface JournalDistributionListItemDtoDataTablePageApiResult {
  /**  */
  result?: JournalDistributionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface JournalListItemDto {
  /**  */
  id: number;

  /**  */
  vendorId: number;

  /**  */
  vendorName?: string;

  /**  */
  status?: string;

  /**  */
  journalType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  achBatchDetailId?: number;

  /**  */
  amount: number;

  /**  */
  commission: number;

  /**  */
  outstandingAmount: number;
}

export interface JournalListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: JournalListItemDto[];
}

export interface JournalListItemDtoDataTablePageApiResult {
  /**  */
  result?: JournalListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface JournalOptionsDto {
  /**  */
  journalTypes?: OptionItemDto[];

  /**  */
  statusCodes?: OptionItemDto[];
}

export interface JournalOptionsDtoApiResult {
  /**  */
  result?: JournalOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface JournalTransactionListItemDto {
  /**  */
  id: number;

  /**  */
  customerName?: string;

  /**  */
  permitNumber?: string;

  /**  */
  productCode?: string;

  /**  */
  status?: TransactionStatus;

  /**  */
  dateIssued: Date;

  /**  */
  amount: number;

  /**  */
  commission?: number;
}

export interface JournalTransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: JournalTransactionListItemDto[];
}

export interface JournalTransactionListItemDtoDataTablePageApiResult {
  /**  */
  result?: JournalTransactionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface KlaviyoProfilePropertiesDto {
  /**  */
  id: number;

  /**  */
  emailAddress?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  sportsmanId?: string;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  dateOfBirth: Date;

  /**  */
  activeProductCodes?: string[];

  /**  */
  productCodesEnrolledInAutoRenew?: string[];
}

export interface KlaviyoProfilePropertiesDtoApiResult {
  /**  */
  result?: KlaviyoProfilePropertiesDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface KlaviyoSettingsDto {
  /**  */
  klaviyoEnabled: boolean;

  /**  */
  klaviyoTestModeEnabled: boolean;
}

export interface KlaviyoSettingsDtoApiResult {
  /**  */
  result?: KlaviyoSettingsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LicenseInfo {
  /**  */
  productName?: string;

  /**  */
  dateOfPurchase: Date;

  /**  */
  permitNumber?: string;

  /**  */
  productCode?: string;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  licenseStatus?: TransactionStatus;

  /**  */
  severity: number;
}

export interface LicenseSalesByDateListItemDto {
  /**  */
  salesDate: Date;

  /**  */
  permitsCount: number;

  /**  */
  vendorFee: number;

  /**  */
  stateFee: number;

  /**  */
  totalSales: number;
}

export interface LicenseSalesByDateListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LicenseSalesByDateListItemDto[];
}

export interface LicenseSalesByDateListItemDtoDataTablePageApiResult {
  /**  */
  result?: LicenseSalesByDateListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LicenseTypeSalesListItemDto {
  /**  */
  product?: string;

  /**  */
  count: number;

  /**  */
  vendorFee: number;

  /**  */
  stateFee: number;

  /**  */
  total: number;
}

export interface LicenseTypeSalesListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LicenseTypeSalesListItemDto[];
}

export interface LicenseTypeSalesListItemDtoDataTablePageApiResult {
  /**  */
  result?: LicenseTypeSalesListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LifetimeLicenesesDataTableDto {
  /**  */
  transactionCustomerProductId: number;

  /**  */
  ldwfNumber?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  dateOfBirth?: string;

  /**  */
  transactionDate?: string;

  /**  */
  productName?: string;

  /**  */
  middleName?: string;

  /**  */
  suffix?: string;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  code?: string;

  /**  */
  heNumber?: string;

  /**  */
  status?: string;
}

export interface LifetimeLicenesesDataTableDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LifetimeLicenesesDataTableDto[];
}

export interface LifetimeLicenesesDataTableDtoDataTablePageApiResult {
  /**  */
  result?: LifetimeLicenesesDataTableDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LocationClassSalesListItemDto {
  /**  */
  vendorTypeCodeDescription?: string;

  /**  */
  transactionCount: number;

  /**  */
  amount: number;

  /**  */
  percentageOfTotalAmount: number;
}

export interface LocationClassSalesListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LocationClassSalesListItemDto[];
}

export interface LocationClassSalesListItemDtoDataTablePageApiResult {
  /**  */
  result?: LocationClassSalesListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LocationEventAreaMappingDto {
  /**  */
  locationId: number;

  /**  */
  eventAreaId: number;
}

export interface LotteryApplicationListItemDto {
  /**  */
  id: number;

  /**  */
  lotteryId: number;

  /**  */
  displayCategoryId: number;

  /**  */
  isAward: boolean;

  /**  */
  isApplicationFeePaid: boolean;

  /**  */
  hasAwardFee: boolean;

  /**  */
  isAwardFeePaid: boolean;

  /**  */
  isPaidOnline: boolean;

  /**  */
  isApplicationFeeWaived: boolean;

  /**  */
  isCanceled: boolean;

  /**  */
  awardAccepted?: boolean;

  /**  */
  isLeftover: boolean;

  /**  */
  quantity: number;

  /**  */
  name?: string;

  /**  */
  status?: string;

  /**  */
  price: number;

  /**  */
  awardPrice?: string;

  /**  */
  groupCode?: string;

  /**  */
  hasGroupMembers: boolean;

  /**  */
  groupLeaderCustomerId: number;

  /**  */
  groupLeader?: string;

  /**  */
  isGroupLeader: boolean;

  /**  */
  isGroupLottery: boolean;

  /**  */
  drawDate?: Date;

  /**  */
  acceptAwardByDate?: Date;

  /**  */
  applicationStartDate: Date;

  /**  */
  applicationEndDate: Date;

  /**  */
  leftoversStartDate?: Date;

  /**  */
  leftoversEndDate?: Date;

  /**  */
  preferencePointType?: PreferencePointTypeDto;

  /**  */
  hunts?: HuntListItemDto[];

  /**  */
  feeExemptionProductCodes?: string[];
}

export interface LotteryApplicationOptionsDto {
  /**  */
  hunts?: OptionItemDto[];
}

export interface LotteryApplicationOptionsDtoApiResult {
  /**  */
  result?: LotteryApplicationOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryAwardCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  lotteryFeeId: number;

  /**  */
  name?: string;

  /**  */
  productCode?: string;

  /**  */
  year: number;

  /**  */
  additionalInfo?: string;
}

export interface LotteryAwardCreateUpdateDtoApiResult {
  /**  */
  result?: LotteryAwardCreateUpdateDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryAwardListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  productCode?: string;

  /**  */
  year: number;
}

export interface LotteryAwardListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LotteryAwardListItemDto[];
}

export interface LotteryAwardListItemDtoDataTablePageApiResult {
  /**  */
  result?: LotteryAwardListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryCreateDto {
  /**  */
  preferencePointTypeName?: string;

  /**  */
  hasApplicants: boolean;

  /**  */
  isGroupLottery: boolean;

  /**  */
  leftoversStartTime?: Date;

  /**  */
  leftoversEndTime?: Date;

  /**  */
  feeExemptionProductCodes?: string[];

  /**  */
  lotteryCustomerLicenseEligibilityCodes?: number[];

  /**  */
  lotteryPchpClassTypes?: PchpClassType[];

  /**  */
  id: number;

  /**  */
  year: number;

  /**  */
  lotteryStatusCode?: LotteryStatusCode;

  /**  */
  preferencePointTypeId: number;

  /**  */
  lotteryDisplayCategoryId: number;

  /**  */
  lotteryFeeId: number;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  lotteryAwardId?: number;

  /**  */
  code?: string;

  /**  */
  minAge: number;

  /**  */
  maxAge: number;

  /**  */
  maxParty: number;

  /**  */
  allowMultipleApplications: boolean;

  /**  */
  allowedApplications: number;

  /**  */
  choicesPerApplication: number;

  /**  */
  autoDisqualifyForNonAcceptance: boolean;

  /**  */
  hasRankedChoices: boolean;

  /**  */
  allowLeftovers: boolean;

  /**  */
  isFeePaidOnline: boolean;

  /**  */
  autoExecute: boolean;

  /**  */
  isResultsConfirmed: boolean;

  /**  */
  applicationInstructions?: string;

  /**  */
  additionalInformation?: string;

  /**  */
  acceptanceDeadlineDays?: number;

  /**  */
  drawDate: Date;

  /**  */
  applicationStartDate: Date;

  /**  */
  applicationEndDate: Date;

  /**  */
  notificationDate?: Date;

  /**  */
  leftoversStartDate?: Date;

  /**  */
  leftoversEndDate?: Date;

  /**  */
  resultNotificationsSent: boolean;

  /**  */
  leftoversNotificationsSent: boolean;

  /**  */
  isPhysicallyChallenged: boolean;
}

export interface LotteryCustomerApplicationDataTableRequest {
  /**  */
  customerId?: number;

  /**  */
  activeOnly: boolean;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface LotteryCustomerApplicationListItemDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  year: number;

  /**  */
  quantity: number;

  /**  */
  drawCode?: string;

  /**  */
  name?: string;

  /**  */
  status?: string;

  /**  */
  groupCode?: string;

  /**  */
  groupLeader?: string;

  /**  */
  prefPointsBefore?: string;

  /**  */
  prefPointsAfter?: string;

  /**  */
  huntChoices?: string[];

  /**  */
  awarded?: string[];

  /**  */
  isAwardFeePaid: boolean;

  /**  */
  isApplicationFeePaid: boolean;

  /**  */
  awardAccepted?: string;

  /**  */
  lotteryAcceptanceDeadlineDays?: number;

  /**  */
  lotteryNotificationDate?: Date;

  /**  */
  acceptanceDeadline?: Date;
}

export interface LotteryCustomerApplicationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LotteryCustomerApplicationListItemDto[];
}

export interface LotteryCustomerApplicationListItemDtoDataTablePageApiResult {
  /**  */
  result?: LotteryCustomerApplicationListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryDisplayCategoryDto {
  /**  */
  id: number;

  /**  */
  code?: string;
}

export interface LotteryDisplayCategoryDtoApiResult {
  /**  */
  result?: LotteryDisplayCategoryDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryDisplayCategoryDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LotteryDisplayCategoryDto[];
}

export interface LotteryDisplayCategoryDtoDataTablePageApiResult {
  /**  */
  result?: LotteryDisplayCategoryDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryDto {
  /**  */
  preferencePointTypeName?: string;

  /**  */
  hasApplicants: boolean;

  /**  */
  isGroupLottery: boolean;

  /**  */
  leftoversStartTime?: Date;

  /**  */
  leftoversEndTime?: Date;

  /**  */
  feeExemptionProductCodes?: string[];

  /**  */
  lotteryCustomerLicenseEligibilityCodes?: number[];

  /**  */
  lotteryPchpClassTypes?: PchpClassType[];

  /**  */
  id: number;

  /**  */
  year: number;

  /**  */
  lotteryStatusCode?: LotteryStatusCode;

  /**  */
  preferencePointTypeId: number;

  /**  */
  lotteryDisplayCategoryId: number;

  /**  */
  lotteryFeeId: number;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  lotteryAwardId?: number;

  /**  */
  code?: string;

  /**  */
  minAge: number;

  /**  */
  maxAge: number;

  /**  */
  maxParty: number;

  /**  */
  allowMultipleApplications: boolean;

  /**  */
  allowedApplications: number;

  /**  */
  choicesPerApplication: number;

  /**  */
  autoDisqualifyForNonAcceptance: boolean;

  /**  */
  hasRankedChoices: boolean;

  /**  */
  allowLeftovers: boolean;

  /**  */
  isFeePaidOnline: boolean;

  /**  */
  autoExecute: boolean;

  /**  */
  isResultsConfirmed: boolean;

  /**  */
  applicationInstructions?: string;

  /**  */
  additionalInformation?: string;

  /**  */
  acceptanceDeadlineDays?: number;

  /**  */
  drawDate: Date;

  /**  */
  applicationStartDate: Date;

  /**  */
  applicationEndDate: Date;

  /**  */
  notificationDate?: Date;

  /**  */
  leftoversStartDate?: Date;

  /**  */
  leftoversEndDate?: Date;

  /**  */
  resultNotificationsSent: boolean;

  /**  */
  leftoversNotificationsSent: boolean;

  /**  */
  isPhysicallyChallenged: boolean;
}

export interface LotteryDtoApiResult {
  /**  */
  result?: LotteryDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryEmailNotificationAttachmentDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  removeAttachment: boolean;
}

export interface LotteryEmailNotificationCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  subject?: string;

  /**  */
  emailBody?: string;

  /**  */
  actionTypes?: LotteryNotificationActions[];

  /**  */
  huntLocationIds?: number[];

  /**  */
  years?: number[];

  /**  */
  lotteryIds?: number[];

  /**  */
  huntIds?: number[];

  /**  */
  applicationStatusIds?: number[];

  /**  */
  preferencePointTypeIds?: number[];

  /**  */
  selectRecipients?: LotteryNotificationOnlyOnceSelectRecipientDto[];

  /**  */
  huntStartDate?: Date;

  /**  */
  huntEndDate?: Date;

  /**  */
  isEnabled: boolean;

  /**  */
  isSelectRecipientsOnly: boolean;

  /**  */
  sendWhen?: NotificationSendingOptions;

  /**  */
  files?: any[];

  /**  */
  attachmentIds?: number[];

  /**  */
  attachmentNames?: LotteryEmailNotificationAttachmentDto[];
}

export interface LotteryEmailNotificationCreateUpdateDtoApiResult {
  /**  */
  result?: LotteryEmailNotificationCreateUpdateDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryEmailNotificationListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  toEmail?: string;

  /**  */
  sentDate?: Date;

  /**  */
  lotteryDisplayName?: string;

  /**  */
  huntDisplayName?: string;

  /**  */
  notificationType?: string;

  /**  */
  subject?: string;

  /**  */
  body?: string;
}

export interface LotteryEmailNotificationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LotteryEmailNotificationListItemDto[];
}

export interface LotteryEmailNotificationListItemDtoDataTablePageApiResult {
  /**  */
  result?: LotteryEmailNotificationListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryEmailNotificationTemplateListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  actionTypes?: LotteryNotificationActions[];

  /**  */
  lottery?: string[];

  /**  */
  year?: number[];

  /**  */
  lastSent?: Date;

  /**  */
  enabled: boolean;

  /**  */
  sendWhen?: NotificationSendingOptions;

  /**  */
  preferencePointType?: string[];
}

export interface LotteryEmailNotificationTemplateListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LotteryEmailNotificationTemplateListItemDto[];
}

export interface LotteryEmailNotificationTemplateListItemDtoDataTablePageApiResult {
  /**  */
  result?: LotteryEmailNotificationTemplateListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryFeeDistributionDto {
  /**  */
  amount: number;

  /**  */
  accountDistributionCodeId: number;

  /**  */
  effectiveDate: Date;
}

export interface LotteryFeeDistributionListItemDto {
  /**  */
  id: number;

  /**  */
  isActive: boolean;

  /**  */
  effectiveDate: Date;

  /**  */
  fund?: string;

  /**  */
  description?: string;

  /**  */
  amount: number;
}

export interface LotteryFeeDistributionListItemDtoListApiResult {
  /**  */
  result?: LotteryFeeDistributionListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryFeeDistributionOptionsDto {
  /**  */
  accountDistributionCodes?: OptionItemDto[];
}

export interface LotteryFeeDistributionOptionsDtoApiResult {
  /**  */
  result?: LotteryFeeDistributionOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryFeeListDto {
  /**  */
  id: number;

  /**  */
  feeType?: LotteryFeeType;

  /**  */
  name?: string;
}

export interface LotteryFeeListDtoApiResult {
  /**  */
  result?: LotteryFeeListDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryFeeListDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LotteryFeeListDto[];
}

export interface LotteryFeeListDtoDataTablePageApiResult {
  /**  */
  result?: LotteryFeeListDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  year: number;

  /**  */
  lotteryDisplayCategoryId: number;

  /**  */
  lotteryDisplayCategoryName?: string;

  /**  */
  lotteryStatusCode?: LotteryStatusCode;

  /**  */
  applicationStartDate: Date;

  /**  */
  applicationEndDate: Date;
}

export interface LotteryListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LotteryListItemDto[];
}

export interface LotteryListItemDtoDataTablePageApiResult {
  /**  */
  result?: LotteryListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryNotificationOnlyOnceSelectRecipientDto {
  /**  */
  id: number;

  /**  */
  fullName?: string;

  /**  */
  sportsmanId?: string;
}

export interface LotteryNotificationOnlyOnceSelectRecipientDtoListApiResult {
  /**  */
  result?: LotteryNotificationOnlyOnceSelectRecipientDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryOptionsDto {
  /**  */
  hunts?: OptionItemDto[];
}

export interface LotteryOptionsDtoApiResult {
  /**  */
  result?: LotteryOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryPreferencePointTypeOptionsDto {
  /**  */
  preferencePointTypes?: OptionItemDto[];
}

export interface LotteryPreferencePointTypeOptionsDtoApiResult {
  /**  */
  result?: LotteryPreferencePointTypeOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotterySMSNotificationEditDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  body?: string;
}

export interface LotterySMSNotificationEditDtoApiResult {
  /**  */
  result?: LotterySMSNotificationEditDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotterySMSNotificationListItemDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  actionType?: LotteryNotificationActions;

  /**  */
  body?: string;
}

export interface LotterySMSNotificationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LotterySMSNotificationListItemDto[];
}

export interface LotterySMSNotificationListItemDtoDataTablePageApiResult {
  /**  */
  result?: LotterySMSNotificationListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotterySelectionDto {
  /**  */
  id: number;

  /**  */
  allowedApplications: number;

  /**  */
  choicesPerApplication: number;

  /**  */
  hasRankedChoices: boolean;

  /**  */
  isLeftover: boolean;

  /**  */
  isFeePaidOnline: boolean;

  /**  */
  isNewLottery: boolean;

  /**  */
  name?: string;

  /**  */
  applicationInstructions?: string;

  /**  */
  hunts?: HuntSelectionListItemDto[];
}

export interface LotterySelectionDtoApiResult {
  /**  */
  result?: LotterySelectionDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryShoppingCartItemDto {
  /**  */
  isAward: boolean;

  /**  */
  isLeftover: boolean;

  /**  */
  isApplicationFeeWaived: boolean;

  /**  */
  lotteryName?: string;

  /**  */
  groupCode?: string;

  /**  */
  drawDate: Date;

  /**  */
  hunts?: HuntListItemDto[];

  /**  */
  id: number;

  /**  */
  lotteryId?: number;

  /**  */
  lotteryCustomerApplicationId?: number;

  /**  */
  productId?: number;

  /**  */
  replacedTransactionCustomerProductId?: number;

  /**  */
  earlyExpiringTransactionCustomerProductId?: number;

  /**  */
  quantity: number;

  /**  */
  transactionFee: number;

  /**  */
  itemTotal: number;

  /**  */
  donationAmount?: number;

  /**  */
  isAutoRenewEnabled: boolean;

  /**  */
  isEarlyRenewal: boolean;

  /**  */
  donationTypeCode?: DonationTypeCode;

  /**  */
  startDates?: string[];

  /**  */
  serialNumbers?: string[];

  /**  */
  productImageIds?: number[];
}

export interface LotteryTransactionListItemDto {
  /**  */
  ldwfNumber?: string;

  /**  */
  customerLastName?: string;

  /**  */
  customerFirstName?: string;

  /**  */
  preferencePointTypeName?: string;

  /**  */
  lotteryFeeType?: LotteryFeeType;

  /**  */
  fund?: string;

  /**  */
  lotteryFeeDistributionAmount: number;
}

export interface LotteryTransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: LotteryTransactionListItemDto[];
}

export interface LotteryTransactionListItemDtoDataTablePageApiResult {
  /**  */
  result?: LotteryTransactionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryTransactionsReportOptionsDto {
  /**  */
  achBatches?: OptionItemDto[];

  /**  */
  funds?: OptionItemDto[];
}

export interface LotteryTransactionsReportOptionsDtoApiResult {
  /**  */
  result?: LotteryTransactionsReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface LotteryUpdateDto {
  /**  */
  preferencePointTypeName?: string;

  /**  */
  hasApplicants: boolean;

  /**  */
  isGroupLottery: boolean;

  /**  */
  leftoversStartTime?: Date;

  /**  */
  leftoversEndTime?: Date;

  /**  */
  feeExemptionProductCodes?: string[];

  /**  */
  lotteryCustomerLicenseEligibilityCodes?: number[];

  /**  */
  lotteryPchpClassTypes?: PchpClassType[];

  /**  */
  id: number;

  /**  */
  year: number;

  /**  */
  lotteryStatusCode?: LotteryStatusCode;

  /**  */
  preferencePointTypeId: number;

  /**  */
  lotteryDisplayCategoryId: number;

  /**  */
  lotteryFeeId: number;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  lotteryAwardId?: number;

  /**  */
  code?: string;

  /**  */
  minAge: number;

  /**  */
  maxAge: number;

  /**  */
  maxParty: number;

  /**  */
  allowMultipleApplications: boolean;

  /**  */
  allowedApplications: number;

  /**  */
  choicesPerApplication: number;

  /**  */
  autoDisqualifyForNonAcceptance: boolean;

  /**  */
  hasRankedChoices: boolean;

  /**  */
  allowLeftovers: boolean;

  /**  */
  isFeePaidOnline: boolean;

  /**  */
  autoExecute: boolean;

  /**  */
  isResultsConfirmed: boolean;

  /**  */
  applicationInstructions?: string;

  /**  */
  additionalInformation?: string;

  /**  */
  acceptanceDeadlineDays?: number;

  /**  */
  drawDate: Date;

  /**  */
  applicationStartDate: Date;

  /**  */
  applicationEndDate: Date;

  /**  */
  notificationDate?: Date;

  /**  */
  leftoversStartDate?: Date;

  /**  */
  leftoversEndDate?: Date;

  /**  */
  resultNotificationsSent: boolean;

  /**  */
  leftoversNotificationsSent: boolean;

  /**  */
  isPhysicallyChallenged: boolean;
}

export interface MergeCustomersCommand {
  /**  */
  customerOneId: number;

  /**  */
  customerTwoId: number;

  /**  */
  selectedMergeCustomer?: SelectedMergeCustomerDto;

  /**  */
  transactionIds?: number[];

  /**  */
  lotteryCustomerApplicationIds?: number[];

  /**  */
  skipLotteryMerge: boolean;
}

export interface MobileCountyCodeDeerAreaDto {
  /**  */
  countyCodeId: number;

  /**  */
  deerAreaId: number;
}

export interface MobileCreateHarvestDto {
  /**  */
  harvestSpecies?: HarvestSpecies;

  /**  */
  id: number;

  /**  */
  transactionTagId: number;

  /**  */
  confirmationNumber?: string;

  /**  */
  dateKilled: Date;

  /**  */
  countyCodeId: number;

  /**  */
  landTypeCode?: LandTypeCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  deerAreaId?: number;

  /**  */
  validationMethod?: ValidationMethod;

  /**  */
  turkeyMaturityCode?: TurkeyMaturityCode;

  /**  */
  harvestImageUri?: string;

  /**  */
  antlerCode?: AntlerCode;
}

export interface MobileCustomerDetailDto {
  /**  */
  hairColor?: string;

  /**  */
  eyeColor?: string;

  /**  */
  dateOfBirthString?: string;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  hunterSafetyNumber?: string;

  /**  */
  hunterSafetyStateCode?: string;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface MobileCustomerDetailDtoApiResult {
  /**  */
  result?: MobileCustomerDetailDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface MobileHarvestOptionDto {
  /**  */
  label?: string;

  /**  */
  value: number;
}

export interface MobileHarvestOptionsDto {
  /**  */
  species?: MobileHarvestOptionDto[];

  /**  */
  genders?: MobileHarvestStringOptionDto[];

  /**  */
  turkeyMaturityLevels?: MobileHarvestOptionDto[];

  /**  */
  harvestCounties?: MobileHarvestOptionDto[];

  /**  */
  landTypes?: MobileHarvestOptionDto[];

  /**  */
  deerAreas?: MobileHarvestOptionDto[];

  /**  */
  countyCodeDeerAreas?: MobileCountyCodeDeerAreaDto[];
}

export interface MobileHarvestOptionsDtoApiResult {
  /**  */
  result?: MobileHarvestOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface MobileHarvestStringOptionDto {
  /**  */
  label?: string;

  /**  */
  value?: string;
}

export interface MobileIspUpdateCustomerCommand {
  /**  */
  hairColor?: string;

  /**  */
  eyeColor?: string;

  /**  */
  dateOfBirthString?: string;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  hunterSafetyNumber?: string;

  /**  */
  hunterSafetyStateCode?: string;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface MobileNotificationSettings {
  /**  */
  notificationRepeatInterval: string;

  /**  */
  notificationMaxDuration?: string;
}

export interface MobileNotificationSettingsApiResult {
  /**  */
  result?: MobileNotificationSettings;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface MobileTransactionTagListItemDto {
  /**  */
  id: number;

  /**  */
  licenseYear: number;

  /**  */
  species?: string;

  /**  */
  speciesCodeId: number;

  /**  */
  tagNumber?: string;

  /**  */
  confirmationNumber?: string;

  /**  */
  harvestImageUri?: string;

  /**  */
  validationMethod?: string;

  /**  */
  wasHarvested: boolean;

  /**  */
  dateKilled?: Date;

  /**  */
  harvestCreatedTimestamp?: Date;

  /**  */
  transactionCreatedTimestamp: Date;

  /**  */
  genderCode?: GenderCode;

  /**  */
  landTypeCode?: LandTypeCode;

  /**  */
  turkeyMaturityCode?: TurkeyMaturityCode;

  /**  */
  deerAreaId?: number;

  /**  */
  countyCodeId: number;

  /**  */
  antlerCode?: AntlerCode;
}

export interface MobileTransactionTagListItemDtoListApiResult {
  /**  */
  result?: MobileTransactionTagListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface MonthlyAchListItemDto {
  /**  */
  achBatchId: number;

  /**  */
  achBatchSweptDate?: string;

  /**  */
  achBatchSweptFrom?: string;

  /**  */
  achBatchSweptTo?: string;

  /**  */
  conFund1201: number;

  /**  */
  conFund1201A: number;

  /**  */
  dCrab: number;

  /**  */
  fedDuck: number;

  /**  */
  h4H5808: number;

  /**  */
  laDuck: number;

  /**  */
  collectorStamp: number;

  /**  */
  laFur: number;

  /**  */
  laTurkey: number;

  /**  */
  nha: number;

  /**  */
  salt: number;

  /**  */
  wHab: number;

  /**  */
  boatComm: number;

  /**  */
  charter: number;

  /**  */
  laAlligator1313: number;

  /**  */
  laAlligator1313A: number;

  /**  */
  charterBoatFishing: number;

  /**  */
  ldwfFoundationEscrow: number;

  /**  */
  wlfms1000022: number;

  /**  */
  w23W2300Licensing: number;

  /**  */
  nsfFee: number;

  /**  */
  appliedCredit: number;

  /**  */
  licensingTotal: number;

  /**  */
  alligatorLottery: number;

  /**  */
  whiteLakeLottery: number;

  /**  */
  whiteLakeHuntFees: number;

  /**  */
  wmaLottery: number;

  /**  */
  atchafalayaDelta: number;

  /**  */
  w23W2300Lottery: number;

  /**  */
  lotteryTotal: number;

  /**  */
  hunterEd: number;

  /**  */
  boaterEd: number;

  /**  */
  total: number;
}

export interface MonthlyAchListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: MonthlyAchListItemDto[];
}

export interface MonthlyAchListItemDtoDataTablePageApiResult {
  /**  */
  result?: MonthlyAchListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface MySalesReportOptionsDto {
  /**  */
  clerks?: OptionItemDto[];
}

export interface MySalesReportOptionsDtoApiResult {
  /**  */
  result?: MySalesReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface NonAchListItemDto {
  /**  */
  vendorId: number;

  /**  */
  vendorAccountNumber?: string;

  /**  */
  vendorName?: string;

  /**  */
  transactionDate?: string;

  /**  */
  charter: number;

  /**  */
  stateCharge: number;

  /**  */
  conFund: number;

  /**  */
  llCon: number;

  /**  */
  llEnd: number;

  /**  */
  dCrab: number;

  /**  */
  charterBoatFishing: number;

  /**  */
  h4H1305: number;

  /**  */
  salt: number;

  /**  */
  wHab: number;

  /**  */
  laAlligator1313: number;

  /**  */
  laDuck: number;

  /**  */
  collectorStamp: number;

  /**  */
  nha: number;

  /**  */
  laTurkey: number;

  /**  */
  laFur: number;

  /**  */
  postage: number;

  /**  */
  fedDuck: number;

  /**  */
  fedDuckOrig: number;

  /**  */
  ldwfFoundationEscrow: number;

  /**  */
  wlfms1000022: number;

  /**  */
  w23W2300: number;

  /**  */
  h4H5808: number;

  /**  */
  ldon: number;

  /**  */
  total: number;
}

export interface NonAchListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: NonAchListItemDto[];
}

export interface NonAchListItemDtoDataTablePageApiResult {
  /**  */
  result?: NonAchListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface NonEftSalesRecFundListItemDto {
  /**  */
  fund?: string;

  /**  */
  cash: number;

  /**  */
  check: number;

  /**  */
  subtotal: number;

  /**  */
  creditCard: number;

  /**  */
  total: number;
}

export interface NonEftSalesRecFundListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: NonEftSalesRecFundListItemDto[];
}

export interface NonEftSalesRecFundListItemDtoDataTablePageApiResult {
  /**  */
  result?: NonEftSalesRecFundListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface NonEftSalesRecPivListItemDto {
  /**  */
  saleDate: Date;

  /**  */
  pivNumber?: string;

  /**  */
  tenderType?: string;

  /**  */
  amount: number;

  /**  */
  depositDate: Date;
}

export interface NonEftSalesRecPivListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: NonEftSalesRecPivListItemDto[];
}

export interface NonEftSalesRecPivListItemDtoDataTablePageApiResult {
  /**  */
  result?: NonEftSalesRecPivListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface NonEftSalesRecTenderListItemDto {
  /**  */
  tenderType?: string;

  /**  */
  amount: number;
}

export interface NonEftSalesRecTenderListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: NonEftSalesRecTenderListItemDto[];
}

export interface NonEftSalesRecTenderListItemDtoDataTablePageApiResult {
  /**  */
  result?: NonEftSalesRecTenderListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface NonEftSalesRecTransactionDetailListItemDto {
  /**  */
  transactionNumber?: string;

  /**  */
  customerSportsmanId?: string;

  /**  */
  customerName?: string;

  /**  */
  productCodeAndName?: string;

  /**  */
  productAmount: number;

  /**  */
  tenderType?: TenderType;

  /**  */
  transactionDate: Date;
}

export interface NonEftSalesRecTransactionDetailListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: NonEftSalesRecTransactionDetailListItemDto[];
}

export interface NonEftSalesRecTransactionDetailListItemDtoDataTablePageApiResult {
  /**  */
  result?: NonEftSalesRecTransactionDetailListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface OptionItemDto {
  /**  */
  value: number;

  /**  */
  label?: string;

  /**  */
  sortOrder?: number;

  /**  */
  metadata?: any | null;
}

export interface OptionItemDtoListApiResult {
  /**  */
  result?: OptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ParticipantsReportOptionsDto {
  /**  */
  classEventTypeAndOnlineCourseTypeNames?: StringOptionItemDto[];

  /**  */
  counties?: OptionItemDto[];
}

export interface ParticipantsReportOptionsDtoApiResult {
  /**  */
  result?: ParticipantsReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ParticipantsReportRecord {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  dateOfBirth?: Date;

  /**  */
  dateOfBirthFormatted?: string;

  /**  */
  classEventTypeName?: string;

  /**  */
  eventLocationName?: string;

  /**  */
  eventParishName?: string;

  /**  */
  beginDate?: Date;

  /**  */
  beginDateFormatted?: string;

  /**  */
  grade?: string;

  /**  */
  eventId?: number;
}

export interface PassCustomerEventCommand {
  /**  */
  customerEventId: number;
}

export interface PasswordLoginCommand {
  /**  */
  username?: string;

  /**  */
  password?: string;
}

export interface PastTransactionDto {
  /**  */
  id: number;

  /**  */
  transactionNumber?: string;

  /**  */
  status?: string;

  /**  */
  vendorName?: string;

  /**  */
  vendorId: number;

  /**  */
  customerId: number;

  /**  */
  customerName?: string;

  /**  */
  customerPhysicalAddress?: string;

  /**  */
  sportsmanId?: string;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  elavonTransactionId?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  createdTimestamp: Date;

  /**  */
  products?: PastTransactionProductDto[];

  /**  */
  canVoid: boolean;

  /**  */
  canReprint: boolean;

  /**  */
  isVoid: boolean;

  /**  */
  hasEarlyRenewedProducts: boolean;
}

export interface PastTransactionDtoApiResult {
  /**  */
  result?: PastTransactionDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface PastTransactionProductDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  permitNumber?: string;

  /**  */
  code?: string;

  /**  */
  replacedProductName?: string;

  /**  */
  replacedProductCode?: string;

  /**  */
  productFeeTotal: number;

  /**  */
  canDuplicate: boolean;

  /**  */
  isExpired: boolean;

  /**  */
  licenseValidEndDate: Date;

  /**  */
  wasEarlyRenewal: boolean;

  /**  */
  wasAutoRenewed: boolean;
}

export interface PreferencePointIndividualTotalsDto {
  /**  */
  items?: PreferencePointTypeTotal[];
}

export interface PreferencePointIndividualTotalsDtoApiResult {
  /**  */
  result?: PreferencePointIndividualTotalsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface PreferencePointTypeDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  lotteryCode?: string;
}

export interface PreferencePointTypeDtoApiResult {
  /**  */
  result?: PreferencePointTypeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface PreferencePointTypeDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: PreferencePointTypeDto[];
}

export interface PreferencePointTypeDtoDataTablePageApiResult {
  /**  */
  result?: PreferencePointTypeDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface PreferencePointTypeTotal {
  /**  */
  id: number;

  /**  */
  preferencePointTypeName?: string;

  /**  */
  quantity: number;
}

export interface PreviouslyPurchasedProductsDto {
  /**  */
  id: number;

  /**  */
  transactionId: number;

  /**  */
  productId: number;

  /**  */
  earlyRenewalDays?: number;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  licenseValidEndDate: Date;

  /**  */
  createdTimestamp: Date;

  /**  */
  isOnlineCourse: boolean;

  /**  */
  isHip: boolean;

  /**  */
  isLifetime: boolean;

  /**  */
  isEligibleForEarlyRenewal: boolean;

  /**  */
  canDuplicateOnline: boolean;

  /**  */
  productRules?: ProductRuleDto[];

  /**  */
  productImages?: CatalogProductImageDto[];
}

export interface ProcessCourseSystemWebhookRequest {
  /**  */
  webhookIdentifier?: string;

  /**  */
  data?: WebhookEventDto;

  /**  */
  hasErrors: boolean;

  /**  */
  errors?: CourseSystemErrorResponse[];

  /**  */
  statusCode?: HttpStatusCode;
}

export interface ProductAncillaryInfoDto {
  /**  */
  shoppingCartItemId: number;

  /**  */
  quantity: number;

  /**  */
  startDates?: string[];

  /**  */
  serialNumbers?: string[];

  /**  */
  productImageIds?: number[];
}

export interface ProductCreateDto {
  /**  */
  id: number;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  tagLine?: string;

  /**  */
  isActive: boolean;

  /**  */
  isLicense: boolean;

  /**  */
  isIncludable: boolean;

  /**  */
  productTypeCodeId: number;

  /**  */
  productVendorTypes?: number[];

  /**  */
  productEarlyRenewalVendorTypes?: number[];

  /**  */
  productFulfillmentMethods?: number[];

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  certificationCodeId?: number;

  /**  */
  customerLicenseEligibilityCodeId?: number;

  /**  */
  minimumAge?: number;

  /**  */
  maximumAge?: number;

  /**  */
  bornBeforeDate?: Date;

  /**  */
  bornAfterDate?: Date;

  /**  */
  licenseValidStartDate?: Date;

  /**  */
  licenseValidEndDate?: Date;

  /**  */
  licenseValidStartTime?: Date;

  /**  */
  licenseValidEndTime?: Date;

  /**  */
  salesStartDate?: Date;

  /**  */
  salesEndDate?: Date;

  /**  */
  salesStartTime?: Date;

  /**  */
  salesEndTime?: Date;

  /**  */
  isStartDateSelectable: boolean;

  /**  */
  numberOfDaysAllowed?: number;

  /**  */
  amountAllowedPerSeason?: number;

  /**  */
  amountAllowedPerAccount?: number;

  /**  */
  earlyRenewalDays?: number;

  /**  */
  licenseYear?: number;

  /**  */
  subjectToVendorFee: boolean;

  /**  */
  subjectToPrivilegeFee: boolean;

  /**  */
  orderBy?: number;

  /**  */
  isNative: boolean;

  /**  */
  isMilitaryCertRequired: boolean;

  /**  */
  isSerialized: boolean;

  /**  */
  canDuplicate: boolean;

  /**  */
  maxPerSale?: number;

  /**  */
  displayMenuId?: number;

  /**  */
  isFreeReprintsAllowed: boolean;

  /**  */
  staticText?: string;

  /**  */
  isLifetime: boolean;

  /**  */
  isEligibleForAutoRenew: boolean;

  /**  */
  isEligibleForEarlyRenewal: boolean;

  /**  */
  isKlaviyoProduct: boolean;

  /**  */
  klaviyoProductType?: KlaviyoProductType;
}

export interface ProductDataTableRequest {
  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface ProductDto {
  /**  */
  id: number;

  /**  */
  createdTimestamp: Date;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  tagLine?: string;

  /**  */
  isActive: boolean;

  /**  */
  isLicense: boolean;

  /**  */
  isIncludable: boolean;

  /**  */
  isEligibleForAutoRenew: boolean;

  /**  */
  isEligibleForEarlyRenewal: boolean;

  /**  */
  earlyRenewalDays?: number;

  /**  */
  productTypeCodeId: number;

  /**  */
  productVendorTypes?: number[];

  /**  */
  productEarlyRenewalVendorTypes?: number[];

  /**  */
  productFulfillmentMethods?: number[];

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  certificationCodeId?: number;

  /**  */
  customerLicenseEligibilityCodeId?: number;

  /**  */
  minimumAge?: number;

  /**  */
  maximumAge?: number;

  /**  */
  bornBeforeDate?: Date;

  /**  */
  bornAfterDate?: Date;

  /**  */
  licenseValidStartDate?: Date;

  /**  */
  licenseValidEndDate?: Date;

  /**  */
  licenseValidStartTime?: Date;

  /**  */
  licenseValidEndTime?: Date;

  /**  */
  hasRestrictedDates: boolean;

  /**  */
  salesStartDate?: Date;

  /**  */
  salesEndDate?: Date;

  /**  */
  salesStartTime?: Date;

  /**  */
  salesEndTime?: Date;

  /**  */
  isStartDateSelectable: boolean;

  /**  */
  numberOfDaysAllowed?: number;

  /**  */
  amountAllowedPerSeason?: number;

  /**  */
  amountAllowedPerAccount?: number;

  /**  */
  licenseYear?: number;

  /**  */
  subjectToVendorFee: boolean;

  /**  */
  subjectToPrivilegeFee: boolean;

  /**  */
  orderBy?: number;

  /**  */
  isNative: boolean;

  /**  */
  isMilitaryCertRequired: boolean;

  /**  */
  isSerialized: boolean;

  /**  */
  canDuplicate: boolean;

  /**  */
  maxPerSale?: number;

  /**  */
  displayMenuId?: number;

  /**  */
  isFreeReprintsAllowed: boolean;

  /**  */
  staticText?: string;

  /**  */
  isKlaviyoProduct: boolean;

  /**  */
  klaviyoProductType?: KlaviyoProductType;
}

export interface ProductDtoApiResult {
  /**  */
  result?: ProductDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductFeeCreateDto {
  /**  */
  productId: number;

  /**  */
  feeAmount: number;

  /**  */
  vendorFee: number;

  /**  */
  dateEffective: Date;

  /**  */
  feeDistributions?: FeeDistributionCreateDto[];
}

export interface ProductFeeDataTableRequest {
  /**  */
  productId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface ProductFeeListItemDto {
  /**  */
  id: number;

  /**  */
  productId: number;

  /**  */
  feeAmount: number;

  /**  */
  vendorFee: number;

  /**  */
  isActive: boolean;

  /**  */
  dateEffective: Date;

  /**  */
  createdTimestamp: Date;

  /**  */
  feeDistributions?: FeeDistributionListItemDto[];
}

export interface ProductFeeListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: ProductFeeListItemDto[];
}

export interface ProductFeeListItemDtoDataTablePageApiResult {
  /**  */
  result?: ProductFeeListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductFeeOptionsDto {
  /**  */
  accountDistributionCodes?: OptionItemDto[];
}

export interface ProductFeeOptionsDtoApiResult {
  /**  */
  result?: ProductFeeOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductImageCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  productId: number;

  /**  */
  imageCode?: string;

  /**  */
  imageUri?: string;

  /**  */
  image?: any;

  /**  */
  displayOrder: number;

  /**  */
  isArchived: boolean;
}

export interface ProductImageCreateUpdateDtoApiResult {
  /**  */
  result?: ProductImageCreateUpdateDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductImageDataTableRequest {
  /**  */
  productId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface ProductImageDto {
  /**  */
  id: number;

  /**  */
  productId: number;

  /**  */
  imageCode?: string;

  /**  */
  imageUri?: string;

  /**  */
  displayOrder: number;

  /**  */
  createdTimestamp: Date;

  /**  */
  isArchived: boolean;
}

export interface ProductImageDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: ProductImageDto[];
}

export interface ProductImageDtoDataTablePageApiResult {
  /**  */
  result?: ProductImageDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductListItemDto {
  /**  */
  id: number;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  productTypeCodeId: number;

  /**  */
  productTypeCode?: string;

  /**  */
  licenseYear: number;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  isActive: boolean;
}

export interface ProductListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: ProductListItemDto[];
}

export interface ProductListItemDtoDataTablePageApiResult {
  /**  */
  result?: ProductListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductOptionsDto {
  /**  */
  defaultLicenseYear: number;

  /**  */
  productTypes?: OptionItemDto[];

  /**  */
  vendorTypeCodes?: OptionItemDto[];

  /**  */
  fulfillmentMethods?: OptionItemDto[];

  /**  */
  certificationCodes?: OptionItemDto[];

  /**  */
  customerLicenseEligibilityCodes?: OptionItemDto[];

  /**  */
  displayMenus?: OptionItemDto[];
}

export interface ProductOptionsDtoApiResult {
  /**  */
  result?: ProductOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductRuleCreateDto {
  /**  */
  productId: number;

  /**  */
  productRules?: ProductRuleDto[];
}

export interface ProductRuleDto {
  /**  */
  productRuleTypeCode?: ProductRuleTypeCode;

  /**  */
  appliedProductCode?: string;
}

export interface ProductRuleDtoListApiResult {
  /**  */
  result?: ProductRuleDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductRuleOptionsDto {
  /**  */
  products?: StringOptionItemDto[];
}

export interface ProductRuleOptionsDtoApiResult {
  /**  */
  result?: ProductRuleOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductSalesVolumeListItemDto {
  /**  */
  productName?: string;

  /**  */
  ldwfNumber?: string;

  /**  */
  customerName?: string;

  /**  */
  customerEmail?: string;

  /**  */
  customerAddress?: string;

  /**  */
  customerCity?: string;

  /**  */
  customerStateCode?: string;

  /**  */
  customerZipCode?: string;

  /**  */
  dateIssued: Date;

  /**  */
  status?: TransactionStatus;

  /**  */
  statusDescription?: string;
}

export interface ProductSalesVolumeListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: ProductSalesVolumeListItemDto[];
}

export interface ProductSalesVolumeListItemDtoDataTablePageApiResult {
  /**  */
  result?: ProductSalesVolumeListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductSalesVolumeReportOptionsDto {
  /**  */
  products?: StringOptionItemDto[];
}

export interface ProductSalesVolumeReportOptionsDtoApiResult {
  /**  */
  result?: ProductSalesVolumeReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductShoppingCartItemDto {
  /**  */
  product?: CatalogProductDto;

  /**  */
  replacedTransactionCustomerProduct?: ReplacedTransactionCustomerProductDto;

  /**  */
  id: number;

  /**  */
  lotteryId?: number;

  /**  */
  lotteryCustomerApplicationId?: number;

  /**  */
  productId?: number;

  /**  */
  replacedTransactionCustomerProductId?: number;

  /**  */
  earlyExpiringTransactionCustomerProductId?: number;

  /**  */
  quantity: number;

  /**  */
  transactionFee: number;

  /**  */
  itemTotal: number;

  /**  */
  donationAmount?: number;

  /**  */
  isAutoRenewEnabled: boolean;

  /**  */
  isEarlyRenewal: boolean;

  /**  */
  donationTypeCode?: DonationTypeCode;

  /**  */
  startDates?: string[];

  /**  */
  serialNumbers?: string[];

  /**  */
  productImageIds?: number[];
}

export interface ProductTagCreateUpdateDto {
  /**  */
  id: number;

  /**  */
  productId: number;

  /**  */
  speciesCodeId: number;

  /**  */
  createdTimestamp: Date;
}

export interface ProductTagDataTableRequest {
  /**  */
  productId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface ProductTagDto {
  /**  */
  id: number;

  /**  */
  speciesCodeId: number;

  /**  */
  speciesCode?: string;

  /**  */
  createdTimestamp: Date;
}

export interface ProductTagDtoApiResult {
  /**  */
  result?: ProductTagDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductTagDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: ProductTagDto[];
}

export interface ProductTagDtoDataTablePageApiResult {
  /**  */
  result?: ProductTagDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductTagOptionsDto {
  /**  */
  speciesCodes?: OptionItemDto[];
}

export interface ProductTagOptionsDtoApiResult {
  /**  */
  result?: ProductTagOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ProductUpdateDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  tagLine?: string;

  /**  */
  isActive: boolean;

  /**  */
  isLicense: boolean;

  /**  */
  isIncludable: boolean;

  /**  */
  productTypeCodeId: number;

  /**  */
  productVendorTypes?: number[];

  /**  */
  productEarlyRenewalVendorTypes?: number[];

  /**  */
  productFulfillmentMethods?: number[];

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  certificationCodeId?: number;

  /**  */
  customerLicenseEligibilityCodeId?: number;

  /**  */
  minimumAge?: number;

  /**  */
  maximumAge?: number;

  /**  */
  bornBeforeDate?: Date;

  /**  */
  bornAfterDate?: Date;

  /**  */
  licenseValidStartDate?: Date;

  /**  */
  licenseValidEndDate?: Date;

  /**  */
  licenseValidStartTime?: Date;

  /**  */
  licenseValidEndTime?: Date;

  /**  */
  salesStartDate?: Date;

  /**  */
  salesEndDate?: Date;

  /**  */
  salesStartTime?: Date;

  /**  */
  salesEndTime?: Date;

  /**  */
  isStartDateSelectable: boolean;

  /**  */
  numberOfDaysAllowed?: number;

  /**  */
  amountAllowedPerSeason?: number;

  /**  */
  amountAllowedPerAccount?: number;

  /**  */
  earlyRenewalDays?: number;

  /**  */
  licenseYear?: number;

  /**  */
  subjectToVendorFee: boolean;

  /**  */
  subjectToPrivilegeFee: boolean;

  /**  */
  orderBy?: number;

  /**  */
  isNative: boolean;

  /**  */
  isMilitaryCertRequired: boolean;

  /**  */
  isSerialized: boolean;

  /**  */
  canDuplicate: boolean;

  /**  */
  maxPerSale?: number;

  /**  */
  displayMenuId?: number;

  /**  */
  isFreeReprintsAllowed: boolean;

  /**  */
  staticText?: string;

  /**  */
  isLifetime: boolean;

  /**  */
  isEligibleForAutoRenew: boolean;

  /**  */
  isEligibleForEarlyRenewal: boolean;

  /**  */
  isKlaviyoProduct: boolean;

  /**  */
  klaviyoProductType?: KlaviyoProductType;
}

export interface RecentLeftoverNotificationJobStatusApiResult {
  /**  */
  result?: RecentLeftoverNotificationJobStatus;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RegisterLoggedInUserToEventCommand {
  /**  */
  eventId: number;
}

export interface RegisterLoggedInUserToEventResultDto {
  /**  */
  customerId: number;

  /**  */
  eventId: number;

  /**  */
  emailDidSend: boolean;
}

export interface RegisterLoggedInUserToEventResultDtoApiResult {
  /**  */
  result?: RegisterLoggedInUserToEventResultDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RemoveCustomerEventFromWaitlistCommand {
  /**  */
  eventId: number;
}

export interface RemoveCustomerEventFromWaitlistResultDto {
  /**  */
  wasUserRemovedFromEvent: boolean;

  /**  */
  emailDidSend: boolean;
}

export interface RemoveCustomerEventFromWaitlistResultDtoApiResult {
  /**  */
  result?: RemoveCustomerEventFromWaitlistResultDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ReplacedTransactionCustomerProductDto {
  /**  */
  id: number;

  /**  */
  replacedProductId: number;

  /**  */
  replacedProductName?: string;

  /**  */
  replacedProductCode?: string;

  /**  */
  replacedProductType?: string;

  /**  */
  isLifetime: boolean;

  /**  */
  replacedProductLicenseValidStartDate: Date;

  /**  */
  replacedProductLicenseValidEndDate: Date;
}

export interface RequestAccountDeletionCommand {
  /**  */
  reason?: string;
}

export interface ResendLotteryTransactionReceiptEmailCommand {
  /**  */
  shoppingCart?: ShoppingCartDto;
}

export interface ResetPasswordCommand {
  /**  */
  username?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  code?: string;
}

export interface RevocationCreateDto {
  /**  */
  customerId: number;

  /**  */
  id: number;

  /**  */
  revocationTypeId?: number;

  /**  */
  violationDate?: Date;

  /**  */
  revocationBeginDate: Date;

  /**  */
  revocationEndDate: Date;

  /**  */
  citationNumber?: string;

  /**  */
  caseNumber?: string;

  /**  */
  description?: string;

  /**  */
  compactIdentifier?: string;

  /**  */
  citationAgency?: string;
}

export interface RevocationDataTableRequest {
  /**  */
  customerId: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface RevocationDto {
  /**  */
  id: number;

  /**  */
  revocationTypeId?: number;

  /**  */
  violationDate?: Date;

  /**  */
  revocationBeginDate: Date;

  /**  */
  revocationEndDate: Date;

  /**  */
  citationNumber?: string;

  /**  */
  caseNumber?: string;

  /**  */
  description?: string;

  /**  */
  compactIdentifier?: string;

  /**  */
  citationAgency?: string;
}

export interface RevocationDtoApiResult {
  /**  */
  result?: RevocationDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RevocationInfo {
  /**  */
  revocationType?: string;

  /**  */
  revocationBeginDate: Date;

  /**  */
  revocationEndDate: Date;

  /**  */
  severity: number;
}

export interface RevocationListItemDto {
  /**  */
  id: number;

  /**  */
  revocationType?: string;

  /**  */
  revocationBeginDate: Date;

  /**  */
  revocationEndDate: Date;

  /**  */
  numberOfComments: number;
}

export interface RevocationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: RevocationListItemDto[];
}

export interface RevocationListItemDtoDataTablePageApiResult {
  /**  */
  result?: RevocationListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RevocationListItemDtoListApiResult {
  /**  */
  result?: RevocationListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RevocationOptionsDto {
  /**  */
  revocationTypes?: OptionItemDto[];
}

export interface RevocationOptionsDtoApiResult {
  /**  */
  result?: RevocationOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RevocationTypeDto {
  /**  */
  id: number;

  /**  */
  description?: string;

  /**  */
  allowSales: boolean;
}

export interface RevocationTypeDtoApiResult {
  /**  */
  result?: RevocationTypeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RevocationTypeDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: RevocationTypeDto[];
}

export interface RevocationTypeDtoDataTablePageApiResult {
  /**  */
  result?: RevocationTypeDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RoleDto {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  claims?: string[];
}

export interface RoleDtoApiResult {
  /**  */
  result?: RoleDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RoleDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: RoleDto[];
}

export interface RoleDtoDataTablePageApiResult {
  /**  */
  result?: RoleDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface RolpCustomerLicenseSearchRequest {
  /**  */
  dateOfBirth?: string;

  /**  */
  ldwfNumber?: string;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  last4Ssn?: string;

  /**  */
  lastName?: string;
}

export interface RolpCustomerLicenseSearchResponse {
  /**  */
  issueDate: Date;

  /**  */
  expirationDate: Date;

  /**  */
  ldwfNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  suffix?: string;

  /**  */
  gender?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  physicalAddressLine1?: string;

  /**  */
  physicalAddressLine2?: string;

  /**  */
  physicalAddressCity?: string;

  /**  */
  physicalAddressState?: string;

  /**  */
  physicalAddressZipcode?: string;

  /**  */
  physicalAddressCountry?: string;
}

export interface RolpCustomerLicenseSearchResponseListApiResult {
  /**  */
  result?: RolpCustomerLicenseSearchResponse[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface SelectedMergeCustomerDto {
  /**  */
  usePrimaryCustomerName: boolean;

  /**  */
  usePrimaryCustomerPhysicalAddress: boolean;

  /**  */
  usePrimaryCustomerMailAddress: boolean;

  /**  */
  usePrimaryCustomerPhone: boolean;

  /**  */
  usePrimaryCustomerSecondaryPhone: boolean;

  /**  */
  usePrimaryCustomerEmail: boolean;

  /**  */
  usePrimaryCustomerDob: boolean;

  /**  */
  usePrimaryCustomerDriversLicense: boolean;

  /**  */
  usePrimaryCustomerSsnStatus: boolean;

  /**  */
  usePrimaryCustomerAlternateId: boolean;

  /**  */
  usePrimaryCustomerBirthCertificate: boolean;
}

export interface SendConfirmAccountEmailCommand {
  /**  */
  username?: string;
}

export interface SendTransactionReceiptEmailCommand {
  /**  */
  transactionId: number;

  /**  */
  customerMailingAddress?: CustomerMailingAddressDto;
}

export interface SettingDto {
  /**  */
  id: number;

  /**  */
  key?: string;

  /**  */
  value?: string;
}

export interface SettingDtoApiResult {
  /**  */
  result?: SettingDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface SettingDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: SettingDto[];
}

export interface SettingDtoDataTablePageApiResult {
  /**  */
  result?: SettingDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ShoppingCartDto {
  /**  */
  id: number;

  /**  */
  total: number;

  /**  */
  fee: number;

  /**  */
  expirationTimestamp?: Date;

  /**  */
  lotteries?: LotteryShoppingCartItemDto[];

  /**  */
  products?: ProductShoppingCartItemDto[];

  /**  */
  donations?: ProductShoppingCartItemDto[];
}

export interface ShoppingCartDtoApiResult {
  /**  */
  result?: ShoppingCartDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ShoppingCartProductDto {
  /**  */
  quantity: number;

  /**  */
  startDates?: string[];

  /**  */
  serialNumbers?: string[];

  /**  */
  productImageIds?: number[];

  /**  */
  id: number;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  staticText?: string;

  /**  */
  tagLine?: string;

  /**  */
  displayMenuId?: number;

  /**  */
  orderBy?: number;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  amountAllowedPerSeason: number;

  /**  */
  maxPerSale: number;

  /**  */
  productFeeTotal: number;

  /**  */
  minimumAge: number;

  /**  */
  maximumAge: number;

  /**  */
  licenseValidEndDate?: Date;

  /**  */
  isStartDateSelectable?: boolean;

  /**  */
  numberOfDaysAllowed?: number;

  /**  */
  isIncludable: boolean;

  /**  */
  isMilitary: boolean;

  /**  */
  isSerialized: boolean;

  /**  */
  hasTags: boolean;

  /**  */
  isPurchasable: boolean;

  /**  */
  productTypeCodeType?: string;

  /**  */
  isNative: boolean;

  /**  */
  isEligibleForAutoRenew: boolean;

  /**  */
  isEligibleForEarlyRenewal: boolean;

  /**  */
  earlyRenewalDays?: number;

  /**  */
  replacedTransactionCustomerProductId?: number;

  /**  */
  earlyExpiringTransactionCustomerProductId?: number;

  /**  */
  isDuplicateLifetimeProduct: boolean;

  /**  */
  isRolpRequired: boolean;

  /**  */
  isMailingAddressConfirmationRequired: boolean;

  /**  */
  fulfillmentTypes?: FulfillmentType[];

  /**  */
  productRules?: ProductRuleDto[];

  /**  */
  productImages?: CatalogProductImageDto[];
}

export interface StatementACHBatchDetailDto {
  /**  */
  amount: number;

  /**  */
  accountNumber?: string;

  /**  */
  bankAccountType?: string;

  /**  */
  routingNumber?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  achProblems?: ACHProblemDto[];
}

export interface StatementDetailsDto {
  /**  */
  id: number;

  /**  */
  vendorId: number;

  /**  */
  vendorName?: string;

  /**  */
  amount: number;

  /**  */
  status?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  achBatchDetail?: StatementACHBatchDetailDto;
}

export interface StatementDetailsDtoApiResult {
  /**  */
  result?: StatementDetailsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface StatementJournalListItemDto {
  /**  */
  id: number;

  /**  */
  status?: string;

  /**  */
  journalType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  achBatchDetailId?: number;

  /**  */
  amount: number;

  /**  */
  commission: number;

  /**  */
  outstandingAmount: number;
}

export interface StatementJournalListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: StatementJournalListItemDto[];
}

export interface StatementJournalListItemDtoDataTablePageApiResult {
  /**  */
  result?: StatementJournalListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface StatementListItemDto {
  /**  */
  id: number;

  /**  */
  vendorId: number;

  /**  */
  vendorName?: string;

  /**  */
  amount: number;

  /**  */
  status?: string;

  /**  */
  createdTimestamp: Date;
}

export interface StatementListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: StatementListItemDto[];
}

export interface StatementListItemDtoDataTablePageApiResult {
  /**  */
  result?: StatementListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface StatementOptionsDto {
  /**  */
  statusCodes?: OptionItemDto[];
}

export interface StatementOptionsDtoApiResult {
  /**  */
  result?: StatementOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface StatewideSalesDetailsListItemDto {
  /**  */
  productName?: string;

  /**  */
  count: number;

  /**  */
  amount: number;

  /**  */
  commission: number;

  /**  */
  totalAmount: number;
}

export interface StatewideSalesDetailsListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: StatewideSalesDetailsListItemDto[];
}

export interface StatewideSalesDetailsListItemDtoDataTablePageApiResult {
  /**  */
  result?: StatewideSalesDetailsListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface StatusCodeDto {
  /**  */
  id: number;

  /**  */
  status?: string;

  /**  */
  description?: string;
}

export interface StatusResult {
  /**  */
  name?: string;

  /**  */
  status?: string;
}

export interface StatusResultListApiResult {
  /**  */
  result?: StatusResult[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface StringApiResult {
  /**  */
  result?: string;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface StringFormOptionItemDto {
  /**  */
  value?: string;

  /**  */
  label?: string;

  /**  */
  sortOrder?: number;

  /**  */
  metadata?: any | null;
}

export interface StringOptionItemDto {
  /**  */
  value?: string;

  /**  */
  label?: string;

  /**  */
  sortOrder?: number;

  /**  */
  metadata?: any | null;
}

export interface SubmitEventResultsCommand {
  /**  */
  eventId: number;
}

export interface SubscribeToLotterySMSUpdatesCommand {
  /**  */
  phoneNumber?: string;
}

export interface SwapCustomerRegistrationCommand {
  /**  */
  eventId: number;
}

export interface SwapCustomerWaitlistCommand {
  /**  */
  eventId: number;
}

export interface TagInfo {
  /**  */
  speciesCode?: string;

  /**  */
  tagNumber?: string;

  /**  */
  validationDate?: Date;

  /**  */
  confirmationNumber?: string;

  /**  */
  parish?: string;

  /**  */
  landTypeCode?: string;

  /**  */
  harvestDate?: Date;

  /**  */
  severity: number;
}

export interface TagsInTextListItemDto {
  /**  */
  id: number;

  /**  */
  licenseYear: number;

  /**  */
  species?: string;

  /**  */
  isTurkey: boolean;

  /**  */
  isAntleredDeer: boolean;

  /**  */
  isAntlerlessDeer: boolean;

  /**  */
  isEitherSexDeer: boolean;

  /**  */
  tagNumber?: string;

  /**  */
  wasHarvested: boolean;

  /**  */
  confirmationNumber?: string;

  /**  */
  dateKilled?: Date;

  /**  */
  createdTimestamp: Date;
}

export interface TagsInTextListItemDtoApiResult {
  /**  */
  result?: TagsInTextListItemDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TagsInTextListItemDtoListApiResult {
  /**  */
  result?: TagsInTextListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TenderTypeSalesListItemDto {
  /**  */
  clerk?: string;

  /**  */
  cash: number;

  /**  */
  check: number;

  /**  */
  creditCard: number;

  /**  */
  creditCardFee: number;

  /**  */
  total: number;
}

export interface TenderTypeSalesListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: TenderTypeSalesListItemDto[];
}

export interface TenderTypeSalesListItemDtoDataTablePageApiResult {
  /**  */
  result?: TenderTypeSalesListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionBoatDto {
  /**  */
  transactionId: number;

  /**  */
  boatRenewalId?: number;

  /**  */
  longBoatNumber?: string;

  /**  */
  hullIdNumber?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffix?: string;

  /**  */
  isDualOwner: boolean;

  /**  */
  combinedName?: string;

  /**  */
  streetAddress?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zipCode?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  amount: number;

  /**  */
  convenienceFee: number;

  /**  */
  emailAddress?: string;

  /**  */
  licenseYear: number;

  /**  */
  dateOfBirth?: Date;

  /**  */
  renewalDate?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  createdByUserId?: number;
}

export interface TransactionBoatDtoApiResult {
  /**  */
  result?: TransactionBoatDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionBoatListItemDto {
  /**  */
  transactionDate: Date;

  /**  */
  registrationNumber?: string;

  /**  */
  elavonTransactionId?: string;

  /**  */
  amount: number;

  /**  */
  convenienceFee: number;

  /**  */
  total: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  streetAddress?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zipCode?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  emailAddress?: string;
}

export interface TransactionBoatListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: TransactionBoatListItemDto[];
}

export interface TransactionBoatListItemDtoDataTablePageApiResult {
  /**  */
  result?: TransactionBoatListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionCommercialDto {
  /**  */
  id: number;

  /**  */
  amount: number;

  /**  */
  convenienceFee: number;

  /**  */
  transactionNumber?: string;

  /**  */
  renewalDate?: string;

  /**  */
  commercialLicenses?: CommercialLicenseListItemDto[];
}

export interface TransactionCommercialDtoApiResult {
  /**  */
  result?: TransactionCommercialDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionCommercialListItemDto {
  /**  */
  transactionDate: Date;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  licenseNumber?: string;

  /**  */
  description?: string;

  /**  */
  licenseClassCode?: string;

  /**  */
  amount: number;

  /**  */
  convenienceFee: number;

  /**  */
  total: number;
}

export interface TransactionCommercialListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: TransactionCommercialListItemDto[];
}

export interface TransactionCommercialListItemDtoDataTablePageApiResult {
  /**  */
  result?: TransactionCommercialListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionCustomerListItemDto {
  /**  */
  transactionId: number;

  /**  */
  transactionNumber?: string;

  /**  */
  vendor?: string;

  /**  */
  productNames?: string[];

  /**  */
  createdTimestamp: Date;
}

export interface TransactionCustomerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: TransactionCustomerListItemDto[];
}

export interface TransactionCustomerListItemDtoDataTablePageApiResult {
  /**  */
  result?: TransactionCustomerListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionCustomerProductListItemDto {
  /**  */
  id: number;

  /**  */
  customerId: number;

  /**  */
  transactionId: number;

  /**  */
  licenseNumber?: string;

  /**  */
  transactionNumber?: string;

  /**  */
  description?: string;

  /**  */
  vendor?: string;

  /**  */
  productFulfillmentMailingAddress?: CustomerMailingAddressDto;

  /**  */
  createdTimestamp: Date;

  /**  */
  isRevoked: boolean;

  /**  */
  productLicenseYear?: number;

  /**  */
  productName?: string;

  /**  */
  productCode?: string;

  /**  */
  replacedProductId?: number;

  /**  */
  replacedTransactionCustomerProductId?: number;

  /**  */
  replacedProductName?: string;

  /**  */
  productIsLifetime: boolean;

  /**  */
  productIsActive: boolean;

  /**  */
  productIsLicense: boolean;

  /**  */
  productIsOnlineCourse: boolean;

  /**  */
  licenseValidStartDate: Date;

  /**  */
  licenseValidEndDate: Date;

  /**  */
  transactionDate: Date;

  /**  */
  transactionStatus?: TransactionStatus;

  /**  */
  isAutoRenew: boolean;

  /**  */
  isEarlyRenewal: boolean;

  /**  */
  hasProcessedTransactionFulfillment: boolean;

  /**  */
  hasUnProcessedTransactionFulfillment: boolean;
}

export interface TransactionCustomerProductListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: TransactionCustomerProductListItemDto[];
}

export interface TransactionCustomerProductListItemDtoDataTablePageApiResult {
  /**  */
  result?: TransactionCustomerProductListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionCustomerProductListItemDtoListApiResult {
  /**  */
  result?: TransactionCustomerProductListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionCustomerProductStatusDto {
  /**  */
  id: number;

  /**  */
  transactionCustomerProductId: number;

  /**  */
  journalId?: number;

  /**  */
  createdByUserId?: number;

  /**  */
  notes?: string;

  /**  */
  createdByUserName?: string;

  /**  */
  transactionStatus?: TransactionStatus;

  /**  */
  createdTimestamp: Date;
}

export interface TransactionDetailsDto {
  /**  */
  billingDetails?: BillingDetailsDto;

  /**  */
  donationAmount: number;

  /**  */
  updateAutoRenewalPaymentInfo?: boolean;
}

export interface TransactionFulfillmentListItemDto {
  /**  */
  id: number;

  /**  */
  productName?: string;

  /**  */
  licenseId?: string;

  /**  */
  licenseNumber?: string;

  /**  */
  ldwfNumber?: string;

  /**  */
  customerFirstName?: string;

  /**  */
  customerLastName?: string;

  /**  */
  fulfillmentMethodName?: string;

  /**  */
  processType?: ProcessType;

  /**  */
  createdTimestamp: Date;

  /**  */
  processedDate?: Date;
}

export interface TransactionFulfillmentListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: TransactionFulfillmentListItemDto[];
}

export interface TransactionFulfillmentListItemDtoDataTablePageApiResult {
  /**  */
  result?: TransactionFulfillmentListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionReceiptDetails {
  /**  */
  transactionId: number;

  /**  */
  tenderType?: TenderType;

  /**  */
  customerMailingAddress?: CustomerMailingAddressDto;
}

export interface TransactionRecordListItemDto {
  /**  */
  transactionId: number;

  /**  */
  transactionNumber?: string;

  /**  */
  customerName?: string;

  /**  */
  sportsmanId?: string;

  /**  */
  clerk?: string;

  /**  */
  tenderType?: string;

  /**  */
  transactionStatus?: string;

  /**  */
  products?: string;

  /**  */
  vendorFee: number;

  /**  */
  stateFee: number;

  /**  */
  total: number;

  /**  */
  issued: Date;
}

export interface TransactionRecordListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: TransactionRecordListItemDto[];
}

export interface TransactionRecordListItemDtoDataTablePageApiResult {
  /**  */
  result?: TransactionRecordListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface TransactionTagListItemDto {
  /**  */
  id: number;

  /**  */
  licenseYear: number;

  /**  */
  speciesCodeId: number;

  /**  */
  species?: string;

  /**  */
  speciesCode?: string;

  /**  */
  tagNumber?: string;

  /**  */
  wasHarvested: boolean;

  /**  */
  confirmationNumber?: string;

  /**  */
  harvestImageUri?: string;

  /**  */
  validationMethod?: string;

  /**  */
  dateKilled?: Date;

  /**  */
  harvestCreatedTimestamp?: Date;

  /**  */
  transactionCreatedTimestamp: Date;

  /**  */
  genderCode?: GenderCode;

  /**  */
  landTypeCode?: LandTypeCode;

  /**  */
  turkeyMaturityCode?: TurkeyMaturityCode;

  /**  */
  antlerCode?: AntlerCode;

  /**  */
  deerAreaId?: number;

  /**  */
  countyCodeId: number;
}

export interface TransactionTagListItemDtoListApiResult {
  /**  */
  result?: TransactionTagListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface UnregisterLoggedInUserFromEventCommand {
  /**  */
  eventId: number;
}

export interface UnregisterLoggedInUserFromEventResultDto {
  /**  */
  wasUserRemovedFromEvent: boolean;

  /**  */
  emailDidSend: boolean;
}

export interface UnregisterLoggedInUserFromEventResultDtoApiResult {
  /**  */
  result?: UnregisterLoggedInUserFromEventResultDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface UpdateApplicationGroupCodeDto {
  /**  */
  lotteryCustomerApplicationId: number;

  /**  */
  lotteryId: number;

  /**  */
  newGroupCode?: string;
}

export interface UpdateCustomerApplicationStatusDto {
  /**  */
  lotteryCustomerApplicationId: number;

  /**  */
  isAwardFeePaid?: boolean;

  /**  */
  isApplicationFeePaid?: boolean;

  /**  */
  status?: string;

  /**  */
  comments?: string;
}

export interface UpdateCustomerCertificationCommand {
  /**  */
  id: number;

  /**  */
  certificationDto?: CertificationCreateUpdateDto;

  /**  */
  createdTimestamp: Date;

  /**  */
  customerId: number;

  /**  */
  customerEventId?: number;
}

export interface UpdateEventCommand {
  /**  */
  id: number;

  /**  */
  classEventTypeId?: number;

  /**  */
  countyCodeId?: number;

  /**  */
  eventLocationId?: number;

  /**  */
  displayStartDate: Date;

  /**  */
  displayEndDate: Date;

  /**  */
  capacity?: number;

  /**  */
  showOnline: boolean;

  /**  */
  eventStatusCodeId?: number;

  /**  */
  isPrivate: boolean;

  /**  */
  privatePasscode?: string;

  /**  */
  hasWaitList: boolean;

  /**  */
  description?: string;

  /**  */
  internalNotes?: string;

  /**  */
  address?: string;

  /**  */
  locationArea?: string;

  /**  */
  eventDateTimes?: EventDateTimeDto[];
}

export interface UpdateEventInstructorActivitiesForEventInstructorCommand {
  /**  */
  eventId: number;

  /**  */
  id: number;

  /**  */
  eventInstructorActivities?: EventInstructorActivityDto[];
}

export interface UpdateEventStatusCommand {
  /**  */
  eventStatusCodeId: number;

  /**  */
  eventId: number;
}

export interface UpdateFulfillmentMethodCommand {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  fulfillmentType?: FulfillmentType;

  /**  */
  isActive: boolean;

  /**  */
  isRecurring: boolean;

  /**  */
  timeOfDay?: Date;

  /**  */
  daysOfWeek?: DayOfWeek[];

  /**  */
  setDates?: FulfillmentMethodScheduleDateDto[];
}

export interface UpdateHarvestDto {
  /**  */
  harvestSpecies?: HarvestSpecies;

  /**  */
  id: number;

  /**  */
  transactionTagId: number;

  /**  */
  confirmationNumber?: string;

  /**  */
  dateKilled: Date;

  /**  */
  countyCodeId: number;

  /**  */
  landTypeCode?: LandTypeCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  antlerCode?: AntlerCode;

  /**  */
  deerAreaId?: number;

  /**  */
  validationMethod?: ValidationMethod;

  /**  */
  turkeyMaturityCode?: TurkeyMaturityCode;

  /**  */
  harvestImageUri?: string;
}

export interface UpdateInstructorCommand {
  /**  */
  id: number;

  /**  */
  statusCodeId: number;

  /**  */
  vendorId?: number;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  comments?: string;

  /**  */
  instructorTypeId?: number;

  /**  */
  userRole: number;

  /**  */
  countyCodeId?: number;

  /**  */
  certificationInstructorCertificationGroupings?: CertificationInstructorCertificationGroupingDto[];

  /**  */
  assignedToAllEventAreas: boolean;

  /**  */
  eventAreaIds?: number[];

  /**  */
  assignedToAllEventTypes: boolean;

  /**  */
  eventTypeIds?: number[];

  /**  */
  instructorAddress?: AddressDto;

  /**  */
  instructorEmailAddress?: string;

  /**  */
  instructorPhoneNumber?: string;
}

export interface UpdateLoggedInUsersEmailCommand {
  /**  */
  emailAddress?: string;
}

export interface UpdateLotteryFeeCommand {
  /**  */
  id: number;

  /**  */
  feeType?: LotteryFeeType;

  /**  */
  name?: string;

  /**  */
  lotteryFeeDistribution?: LotteryFeeDistributionDto;
}

export interface UpdateMailingAddressForTransactionFulfillmentCommand {
  /**  */
  transactionId: number;

  /**  */
  customerMailingAddress?: CustomerMailingAddressDto;
}

export interface UpdateProductImageDisplayOrderDto {
  /**  */
  id: number;

  /**  */
  productId: number;

  /**  */
  displayOrder: number;
}

export interface UpdateRoleCommand {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  claims?: string[];
}

export interface UpdateTransactionCustomerProductTripDatesCommand {
  /**  */
  transactionCustomerProductId: number;

  /**  */
  licenseValidStartDate: Date;
}

export interface UpdateUserCommand {
  /**  */
  id: number;

  /**  */
  statusCodeId: number;

  /**  */
  vendorId?: number;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  comments?: string;

  /**  */
  instructorTypeId?: number;

  /**  */
  userRole: number;

  /**  */
  countyCodeId?: number;

  /**  */
  certificationInstructorCertificationGroupings?: CertificationInstructorCertificationGroupingDto[];

  /**  */
  assignedToAllEventAreas: boolean;

  /**  */
  eventAreaIds?: number[];

  /**  */
  assignedToAllEventTypes: boolean;

  /**  */
  eventTypeIds?: number[];

  /**  */
  instructorAddress?: AddressDto;

  /**  */
  instructorEmailAddress?: string;

  /**  */
  instructorPhoneNumber?: string;
}

export interface UpdateVendorUserCommand {
  /**  */
  id: number;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  userRole: number;

  /**  */
  statusCodeId: number;

  /**  */
  isAdminEdit: boolean;

  /**  */
  email?: string;
}

export interface UpdateVendorUserPasswordCommand {
  /**  */
  id: number;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface UserDto {
  /**  */
  id: number;

  /**  */
  statusCodeId: number;

  /**  */
  customerId?: number;

  /**  */
  vendorId?: number;

  /**  */
  vendor?: string;

  /**  */
  emailConfirmed: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  countyCodeId?: number;

  /**  */
  email?: string;

  /**  */
  userName?: string;

  /**  */
  comments?: string;

  /**  */
  userRole?: number;

  /**  */
  instructorTypeId?: number;

  /**  */
  instructorId?: number;

  /**  */
  instructorNumber?: string;

  /**  */
  instructorAddress?: AddressDto;

  /**  */
  instructorEmailAddress?: string;

  /**  */
  instructorPhoneNumber?: string;

  /**  */
  instructorCertifications?: InstructorCertificationDetailDto[];

  /**  */
  assignedToAllEventAreas: boolean;

  /**  */
  eventAreaIds?: number[];

  /**  */
  assignedToAllEventTypes: boolean;

  /**  */
  eventTypeIds?: number[];

  /**  */
  claims?: string[];

  /**  */
  role?: string;
}

export interface UserDtoApiResult {
  /**  */
  result?: UserDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface UserListItemDto {
  /**  */
  id: number;

  /**  */
  vendorId: number;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  fullName?: string;

  /**  */
  suffixCode?: string;

  /**  */
  email?: string;

  /**  */
  userName?: string;

  /**  */
  statusCode?: string;

  /**  */
  vendor?: string;

  /**  */
  lastActivityTimestamp?: Date;

  /**  */
  createdTimestamp: Date;

  /**  */
  userRoles?: string[];
}

export interface UserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: UserListItemDto[];
}

export interface UserListItemDtoDataTablePageApiResult {
  /**  */
  result?: UserListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface ValidationError {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;
}

export interface VenderDashboardUserDto {
  /**  */
  id: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  status?: string;
}

export interface VendorAccountInformationDto {
  /**  */
  accountNumber?: string;

  /**  */
  fullName?: string;

  /**  */
  address?: AddressDto;

  /**  */
  primaryPhone?: string;

  /**  */
  emailAddress?: string;
}

export interface VendorCatalogOptionsDto {
  /**  */
  stateOfficeCreditCardFeePercent: number;

  /**  */
  displayMenu?: OptionItemDto[];

  /**  */
  products?: CatalogProductDto[];

  /**  */
  previouslyPurchasedProducts?: PreviouslyPurchasedProductsDto[];

  /**  */
  customerInformation?: CustomerInformationDto;

  /**  */
  hasRevocation: boolean;

  /**  */
  isHipCertificationNeeded: boolean;

  /**  */
  hipMinAge?: number;
}

export interface VendorCatalogOptionsDtoApiResult {
  /**  */
  result?: VendorCatalogOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorCreateCustomerCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface VendorDashboardDto {
  /**  */
  accountInformation?: VendorAccountInformationDto;

  /**  */
  pastTransactions?: PastTransactionDto[];

  /**  */
  notifications?: VendorNotificationDto[];

  /**  */
  vendorUsers?: VenderDashboardUserDto[];

  /**  */
  vendorIsLocked: boolean;
}

export interface VendorDashboardDtoApiResult {
  /**  */
  result?: VendorDashboardDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorDataTableRequest {
  /**  */
  city?: string;

  /**  */
  zipCode?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  take: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface VendorDto {
  /**  */
  id: number;

  /**  */
  accountNumber?: string;

  /**  */
  name?: string;

  /**  */
  federalTaxIdentificationNumber?: string;

  /**  */
  bankName?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankRoutingNumber?: string;

  /**  */
  contactName?: string;

  /**  */
  contactPhoneNumber?: string;

  /**  */
  contactAlternatePhoneNumber?: string;

  /**  */
  contactEmail?: string;

  /**  */
  isInternetAgent: boolean;

  /**  */
  isLotteryAgent: boolean;

  /**  */
  createdTimestamp: Date;

  /**  */
  closedDate?: Date;

  /**  */
  trainedDate?: Date;

  /**  */
  bankAccountType?: BankAccountType;

  /**  */
  isDurableStockPrint: boolean;

  /**  */
  equipmentTypeCode?: EquipmentTypeCode;

  /**  */
  vendorTypeCodeId: number;

  /**  */
  corporationId?: number;

  /**  */
  statusCodeId: number;

  /**  */
  settlementTypeCodeId: number;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;
}

export interface VendorDtoApiResult {
  /**  */
  result?: VendorDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorFundSplitListItemDto {
  /**  */
  vendorName?: string;

  /**  */
  accountDistributionCodeDescription?: string;

  /**  */
  amount: number;
}

export interface VendorFundSplitListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: VendorFundSplitListItemDto[];
}

export interface VendorFundSplitListItemDtoDataTablePageApiResult {
  /**  */
  result?: VendorFundSplitListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorFundSplitReportOptionsDto {
  /**  */
  vendorTypeCodeTypes?: StringOptionItemDto[];

  /**  */
  vendors?: OptionItemDto[];
}

export interface VendorFundSplitReportOptionsDtoApiResult {
  /**  */
  result?: VendorFundSplitReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorJournalListItemDto {
  /**  */
  id: number;

  /**  */
  status?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  achBatchDetailId?: number;

  /**  */
  amount: number;

  /**  */
  commission: number;

  /**  */
  outstandingAmount: number;
}

export interface VendorJournalListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: VendorJournalListItemDto[];
}

export interface VendorJournalListItemDtoDataTablePageApiResult {
  /**  */
  result?: VendorJournalListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorListItemDto {
  /**  */
  id: number;

  /**  */
  idString?: string;

  /**  */
  name?: string;

  /**  */
  physicalAddressCity?: string;

  /**  */
  physicalAddressZipCode?: string;

  /**  */
  contactName?: string;

  /**  */
  statusCode?: string;
}

export interface VendorListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: VendorListItemDto[];
}

export interface VendorListItemDtoDataTablePageApiResult {
  /**  */
  result?: VendorListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorLoginCommand {
  /**  */
  username?: string;

  /**  */
  password?: string;
}

export interface VendorNotificationDto {
  /**  */
  id: number;

  /**  */
  subject?: string;

  /**  */
  message?: string;

  /**  */
  displayDate: Date;

  /**  */
  expirationDate: Date;

  /**  */
  vendorId?: number;

  /**  */
  vendorTypeCodeId?: number;

  /**  */
  isGlobal: boolean;

  /**  */
  createdTimestamp: Date;
}

export interface VendorNotificationDtoApiResult {
  /**  */
  result?: VendorNotificationDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorNotificationDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: VendorNotificationDto[];
}

export interface VendorNotificationDtoDataTablePageApiResult {
  /**  */
  result?: VendorNotificationDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorNotificationsDataTableRequest {
  /**  */
  vendorId?: number;

  /**  */
  tableEcho: number;

  /**  */
  skip: number;

  /**  */
  take: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns: boolean;
}

export interface VendorOptionsDto {
  /**  */
  vendorCodes?: OptionItemDto[];

  /**  */
  statusCodes?: OptionItemDto[];

  /**  */
  corporationCodes?: OptionItemDto[];

  /**  */
  vendors?: OptionItemDto[];
}

export interface VendorOptionsDtoApiResult {
  /**  */
  result?: VendorOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorSaleDaySummaryListItemDto {
  /**  */
  createdTimestampDate: Date;

  /**  */
  quantity: number;

  /**  */
  totalGross: number;

  /**  */
  totalCommission?: number;

  /**  */
  totalAmount: number;
}

export interface VendorSaleDaySummaryListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: VendorSaleDaySummaryListItemDto[];
}

export interface VendorSaleDaySummaryListItemDtoDataTablePageApiResult {
  /**  */
  result?: VendorSaleDaySummaryListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorSaleListItemDto {
  /**  */
  createdTimestamp: Date;

  /**  */
  permitNumber?: string;

  /**  */
  customerName?: string;

  /**  */
  customerId?: number;

  /**  */
  vendorId: number;

  /**  */
  status?: TransactionStatus;

  /**  */
  amount: number;

  /**  */
  commission?: number;

  /**  */
  gross: number;
}

export interface VendorSaleListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: VendorSaleListItemDto[];
}

export interface VendorSaleListItemDtoDataTablePageApiResult {
  /**  */
  result?: VendorSaleListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorStatementOptionsDto {
  /**  */
  statements?: OptionItemDto[];
}

export interface VendorStatementOptionsDtoApiResult {
  /**  */
  result?: VendorStatementOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorUpdateCustomerCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface VendorUpdateCustomerWithResidencyVerificationCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface VendorUserOptionsDto {
  /**  */
  statuses?: OptionItemDto[];

  /**  */
  roles?: OptionItemDto[];
}

export interface VendorUserOptionsDtoApiResult {
  /**  */
  result?: VendorUserOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface VendorValidateUpdateCustomerWithResidencyVerificationCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface VerifyCustomerCommand {
  /**  */
  legalConfirmation: boolean;

  /**  */
  physicalAddress?: AddressDto;

  /**  */
  mailingAddress?: AddressDto;

  /**  */
  id: number;

  /**  */
  sportsmanId?: string;

  /**  */
  customerType?: string;

  /**  */
  createdTimestamp: Date;

  /**  */
  weight?: number;

  /**  */
  heightFeet?: number;

  /**  */
  heightInches?: number;

  /**  */
  passportNumber?: string;

  /**  */
  passportCountryCode?: string;

  /**  */
  hasBirthCertificate?: boolean;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumberPrimary?: string;

  /**  */
  phoneNumberSecondary?: string;

  /**  */
  textToTagSmsNumber?: string;

  /**  */
  receiveSmsUpdates: boolean;

  /**  */
  textToTagStatus?: TextToTagEnrollmentStatus;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  militaryIdNumber?: string;

  /**  */
  dateOfBirth: Date;

  /**  */
  driversLicenseStateCode?: string;

  /**  */
  socialSecurityNumberStatusCode?: SocialSecurityNumberStatus;

  /**  */
  suffixCode?: SuffixCode;

  /**  */
  ethnicCode?: EthnicCode;

  /**  */
  genderCode?: GenderCode;

  /**  */
  sourceIndicator?: SourceIndicator;

  /**  */
  statusCodeId?: number;

  /**  */
  hairColorCodeId?: number;

  /**  */
  eyeColorCodeId?: number;

  /**  */
  closedTimestamp?: Date;

  /**  */
  residencyCode?: ResidencyCode;

  /**  */
  licenseEligibilityCodes?: number[];

  /**  */
  pchpClassType?: PchpClassType;

  /**  */
  fullName?: string;
}

export interface VerifyLotteryTransactionDto {
  /**  */
  transactionId?: string;

  /**  */
  cardToken?: string;

  /**  */
  updateAutoRenewalInformation: boolean;
}

export interface VerifyProductTransactionDto {
  /**  */
  hipSurvey?: HipSurveyDto;

  /**  */
  customerMailingAddress?: CustomerMailingAddressDto;

  /**  */
  transactionId?: string;

  /**  */
  cardToken?: string;

  /**  */
  updateAutoRenewalInformation: boolean;
}

export interface VerifyRenewalAndCreateCommercialTransactionCommand {
  /**  */
  commercialLicenses?: CommercialLicenseListItemDto[];

  /**  */
  transactionId?: string;
}

export interface VoidTransactionForAdminCommand {
  /**  */
  transactionId: number;

  /**  */
  voidTransactionReason?: VoidTransactionReasons;
}

export interface VoidTransactionForVendorCommand {
  /**  */
  transactionId: number;

  /**  */
  voidTransactionReason?: VoidTransactionReasons;
}

export interface VoidTransactionRecordListItemDto {
  /**  */
  licenseNumber?: string;

  /**  */
  customerName?: string;

  /**  */
  sportsmanId?: string;

  /**  */
  clerk?: string;

  /**  */
  transactionStatus?: string;

  /**  */
  total: number;

  /**  */
  issued: Date;

  /**  */
  changed: Date;
}

export interface VoidTransactionRecordListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: VoidTransactionRecordListItemDto[];
}

export interface VoidTransactionRecordListItemDtoDataTablePageApiResult {
  /**  */
  result?: VoidTransactionRecordListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface WaitlistLoggedInUserToEventCommand {
  /**  */
  eventId: number;
}

export interface WaitlistLoggedInUserToEventResultDto {
  /**  */
  customerId: number;

  /**  */
  eventId: number;
}

export interface WaitlistLoggedInUserToEventResultDtoApiResult {
  /**  */
  result?: WaitlistLoggedInUserToEventResultDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface WebhookEventDto {
  /**  */
  eventType?: string;

  /**  */
  enrollment?: WebhookEventEnrollmentDto;
}

export interface WebhookEventEnrollmentDto {
  /**  */
  enrollmentId: number;

  /**  */
  enrollmentIdentifier?: string;

  /**  */
  courseType?: string;

  /**  */
  status?: string;
}

export interface WeeklyAchBatchDates {
  /**  */
  startDate?: string;

  /**  */
  endDate?: string;
}

export interface WeeklyAchBatchDatesApiResult {
  /**  */
  result?: WeeklyAchBatchDates;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface WeeklyAchListItemDto {
  /**  */
  vendorId: number;

  /**  */
  achBatchCreatedTimestamp?: string;

  /**  */
  vendorAccountNumber?: string;

  /**  */
  vendorName?: string;

  /**  */
  conFund1201: number;

  /**  */
  conFund1201A: number;

  /**  */
  dCrab: number;

  /**  */
  fedDuck: number;

  /**  */
  h4H5808: number;

  /**  */
  laDuck: number;

  /**  */
  collectorStamp: number;

  /**  */
  laFur: number;

  /**  */
  laTurkey: number;

  /**  */
  nha: number;

  /**  */
  salt: number;

  /**  */
  wHab: number;

  /**  */
  boatComm: number;

  /**  */
  charter: number;

  /**  */
  laAlligator1313: number;

  /**  */
  laAlligator1313A: number;

  /**  */
  charterBoatFishing: number;

  /**  */
  ldwfFoundationEscrow: number;

  /**  */
  wlfms1000022: number;

  /**  */
  w23W2300Licensing: number;

  /**  */
  nsfFee: number;

  /**  */
  appliedCredit: number;

  /**  */
  licensingTotal: number;

  /**  */
  alligatorLottery: number;

  /**  */
  whiteLakeLottery: number;

  /**  */
  whiteLakeHuntFees: number;

  /**  */
  wmaLottery: number;

  /**  */
  atchafalayaDelta: number;

  /**  */
  w23W2300Lottery: number;

  /**  */
  lotteryTotal: number;

  /**  */
  hunterEd: number;

  /**  */
  boaterEd: number;

  /**  */
  total: number;
}

export interface WeeklyAchListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords: number;

  /**  */
  totalRecords: number;

  /**  */
  rows?: WeeklyAchListItemDto[];
}

export interface WeeklyAchListItemDtoDataTablePageApiResult {
  /**  */
  result?: WeeklyAchListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export interface WeeklyAchReportOptionsDto {
  /**  */
  achBatches?: OptionItemDto[];

  /**  */
  vendors?: OptionItemDto[];
}

export interface WeeklyAchReportOptionsDtoApiResult {
  /**  */
  result?: WeeklyAchReportOptionsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors: boolean;
}

export enum AddressType {
  'MAIL' = 'MAIL',
  'PHYSICAL' = 'PHYSICAL',
  'SHIPPING' = 'SHIPPING'
}

export enum AntlerCode {
  'Antlered' = 'Antlered',
  'Antlerless' = 'Antlerless'
}

export enum AuditAction {
  'Unknown' = 'Unknown',
  'Deleted' = 'Deleted',
  'Modified' = 'Modified',
  'Added' = 'Added'
}

export enum BankAccountType {
  'Checking' = 'Checking',
  'Savings' = 'Savings'
}

export enum CertificationType {
  'COURSE' = 'COURSE',
  'INSTRUCTOR' = 'INSTRUCTOR',
  'EXEMPTION' = 'EXEMPTION',
  'PARTICIPATION' = 'PARTICIPATION'
}

export enum ChangePasswordType {
  'Self' = 'Self',
  'Other' = 'Other'
}

export enum CsvTypes {
  'Kalkomey' = 'Kalkomey',
  'BoatUs' = 'BoatUs'
}

export enum CustomerEventState {
  'Incomplete' = 'Incomplete',
  'Underage' = 'Underage',
  'NoShow' = 'NoShow',
  'Repeat' = 'Repeat',
  'Failed' = 'Failed'
}

export enum DayOfWeek {
  'Sunday' = 'Sunday',
  'Monday' = 'Monday',
  'Tuesday' = 'Tuesday',
  'Wednesday' = 'Wednesday',
  'Thursday' = 'Thursday',
  'Friday' = 'Friday',
  'Saturday' = 'Saturday'
}

export enum DonationTypeCode {
  'Catalog' = 'Catalog',
  'Checkout' = 'Checkout'
}

export enum EquipmentTypeCode {
  'WebPos' = 'WebPos',
  'Pos' = 'Pos',
  'VendorProvided' = 'VendorProvided'
}

export enum EthnicCode {
  'Other' = 'Other',
  'NativeAmerican' = 'NativeAmerican',
  'Asian' = 'Asian',
  'Hispanic' = 'Hispanic',
  'Black' = 'Black',
  'White' = 'White'
}

export enum FulfillmentType {
  'PSSLifetimeLicense' = 'PSSLifetimeLicense',
  'Southwick' = 'Southwick',
  'HardCard' = 'HardCard',
  'StateDuckstamp' = 'StateDuckstamp'
}

export enum GenderCode {
  'Male' = 'Male',
  'Female' = 'Female',
  'Unknown' = 'Unknown'
}

export enum HarvestReportSpecies {
  'Deer' = 'Deer',
  'Turkey' = 'Turkey'
}

export enum HarvestSpecies {
  'Deer' = 'Deer',
  'Turkey' = 'Turkey'
}

export enum HttpStatusCode {
  'Continue' = 'Continue',
  'SwitchingProtocols' = 'SwitchingProtocols',
  'Processing' = 'Processing',
  'EarlyHints' = 'EarlyHints',
  'OK' = 'OK',
  'Created' = 'Created',
  'Accepted' = 'Accepted',
  'NonAuthoritativeInformation' = 'NonAuthoritativeInformation',
  'NoContent' = 'NoContent',
  'ResetContent' = 'ResetContent',
  'PartialContent' = 'PartialContent',
  'MultiStatus' = 'MultiStatus',
  'AlreadyReported' = 'AlreadyReported',
  'IMUsed' = 'IMUsed',
  'MultipleChoices' = 'MultipleChoices',
  'MovedPermanently' = 'MovedPermanently',
  'Found' = 'Found',
  'SeeOther' = 'SeeOther',
  'NotModified' = 'NotModified',
  'UseProxy' = 'UseProxy',
  'Unused' = 'Unused',
  'RedirectKeepVerb' = 'RedirectKeepVerb',
  'PermanentRedirect' = 'PermanentRedirect',
  'BadRequest' = 'BadRequest',
  'Unauthorized' = 'Unauthorized',
  'PaymentRequired' = 'PaymentRequired',
  'Forbidden' = 'Forbidden',
  'NotFound' = 'NotFound',
  'MethodNotAllowed' = 'MethodNotAllowed',
  'NotAcceptable' = 'NotAcceptable',
  'ProxyAuthenticationRequired' = 'ProxyAuthenticationRequired',
  'RequestTimeout' = 'RequestTimeout',
  'Conflict' = 'Conflict',
  'Gone' = 'Gone',
  'LengthRequired' = 'LengthRequired',
  'PreconditionFailed' = 'PreconditionFailed',
  'RequestEntityTooLarge' = 'RequestEntityTooLarge',
  'RequestUriTooLong' = 'RequestUriTooLong',
  'UnsupportedMediaType' = 'UnsupportedMediaType',
  'RequestedRangeNotSatisfiable' = 'RequestedRangeNotSatisfiable',
  'ExpectationFailed' = 'ExpectationFailed',
  'MisdirectedRequest' = 'MisdirectedRequest',
  'UnprocessableEntity' = 'UnprocessableEntity',
  'Locked' = 'Locked',
  'FailedDependency' = 'FailedDependency',
  'UpgradeRequired' = 'UpgradeRequired',
  'PreconditionRequired' = 'PreconditionRequired',
  'TooManyRequests' = 'TooManyRequests',
  'RequestHeaderFieldsTooLarge' = 'RequestHeaderFieldsTooLarge',
  'UnavailableForLegalReasons' = 'UnavailableForLegalReasons',
  'InternalServerError' = 'InternalServerError',
  'NotImplemented' = 'NotImplemented',
  'BadGateway' = 'BadGateway',
  'ServiceUnavailable' = 'ServiceUnavailable',
  'GatewayTimeout' = 'GatewayTimeout',
  'HttpVersionNotSupported' = 'HttpVersionNotSupported',
  'VariantAlsoNegotiates' = 'VariantAlsoNegotiates',
  'InsufficientStorage' = 'InsufficientStorage',
  'LoopDetected' = 'LoopDetected',
  'NotExtended' = 'NotExtended',
  'NetworkAuthenticationRequired' = 'NetworkAuthenticationRequired'
}

export enum HuntLocationStatusCode {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE'
}

export enum HuntStatusCode {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
  'CANCELLED' = 'CANCELLED'
}

export enum InstructorCertReportSortBy {
  'HoursAscending' = 'HoursAscending',
  'HoursDescending' = 'HoursDescending'
}

export enum InstructorSummarySortBy {
  'HoursAscending' = 'HoursAscending',
  'HoursDescending' = 'HoursDescending'
}

export enum KlaviyoProductType {
  'LICENSE365' = 'LICENSE365',
  'TENDAY' = 'TENDAY',
  'FIVEDAY' = 'FIVEDAY',
  'THREEDAY' = 'THREEDAY',
  'ONEDAY' = 'ONEDAY',
  'OTHER' = 'OTHER'
}

export enum LandTypeCode {
  'PublicLand' = 'PublicLand',
  'PrivateLand' = 'PrivateLand',
  'DMap' = 'DMap',
  'Unknown' = 'Unknown'
}

export enum LotteryFeeType {
  'APPLICATION' = 'APPLICATION',
  'AWARD' = 'AWARD'
}

export enum LotteryNotificationActions {
  'AwardAccepted' = 'AwardAccepted',
  'AwardDeclined' = 'AwardDeclined',
  'HuntCanceled' = 'HuntCanceled',
  'ApplicationDisqualified' = 'ApplicationDisqualified',
  'ApplicationIneligible' = 'ApplicationIneligible',
  'ApplicationRemoved' = 'ApplicationRemoved',
  'ApplicationSubmitted' = 'ApplicationSubmitted',
  'ApplicationUnsuccessful' = 'ApplicationUnsuccessful',
  'ApplicationUpdated' = 'ApplicationUpdated',
  'DrawSuccessful' = 'DrawSuccessful',
  'HuntsSupplemental' = 'HuntsSupplemental',
  'LeftoversAvailable' = 'LeftoversAvailable',
  'LotteryCanceled' = 'LotteryCanceled'
}

export enum LotteryStatusCode {
  'ACTIVE' = 'ACTIVE',
  'COMPLETE' = 'COMPLETE',
  'DRAWN' = 'DRAWN',
  'CANCELLED' = 'CANCELLED',
  'EXECUTING' = 'EXECUTING',
  'FAILED' = 'FAILED'
}

export enum NotificationSendingOptions {
  'OnTrigger' = 'OnTrigger',
  'OnlyOnce' = 'OnlyOnce'
}

export enum ParticipantsReportGradeStatus {
  'Pass' = 'Pass',
  'Fail' = 'Fail'
}

export enum PchpClassType {
  'ClassI' = 'ClassI',
  'ClassII' = 'ClassII',
  'TemporaryClassII' = 'TemporaryClassII',
  'ClassIII' = 'ClassIII',
  'ClassIV' = 'ClassIV'
}

export enum ProcessType {
  'New' = 'New',
  'DuplicateLicense' = 'DuplicateLicense',
  'Void' = 'Void',
  'Reprint' = 'Reprint',
  'Activate' = 'Activate'
}

export enum ProductRuleTypeCode {
  'Allows' = 'Allows',
  'Excludes' = 'Excludes',
  'Upsells' = 'Upsells',
  'Includes' = 'Includes'
}

export enum RecentLeftoverNotificationJobStatus {
  'NotRunning' = 'NotRunning',
  'Running' = 'Running',
  'WaitingToComplete' = 'WaitingToComplete',
  'Complete' = 'Complete'
}

export enum ResidencyCode {
  'RESIDENT' = 'RESIDENT',
  'NONRESIDENT' = 'NONRESIDENT',
  'BOTH' = 'BOTH'
}

export enum SocialSecurityNumberStatus {
  'Exempt' = 'Exempt',
  'NonExempt' = 'NonExempt'
}

export enum SourceIndicator {
  'Internet' = 'Internet',
  'Vendor' = 'Vendor',
  'Import' = 'Import',
  'WebPOS' = 'WebPOS',
  'ControlCenter' = 'ControlCenter',
  'Mobile' = 'Mobile',
  'Student' = 'Student',
  'IVR' = 'IVR',
  'Kalkomey' = 'Kalkomey',
  'BoatUS' = 'BoatUS',
  'Commercial' = 'Commercial',
  'VmsImport' = 'VmsImport',
  'EducationApi' = 'EducationApi',
  'eCitation' = 'eCitation'
}

export enum SuffixCode {
  'Jr' = 'Jr',
  'Sr' = 'Sr',
  'I' = 'I',
  'II' = 'II',
  'III' = 'III',
  'IV' = 'IV',
  'V' = 'V',
  'VI' = 'VI',
  'VII' = 'VII',
  'VIII' = 'VIII',
  'IX' = 'IX',
  'X' = 'X'
}

export enum TagSpeciesCodes {
  'TK' = 'TK',
  'DR' = 'DR',
  'AD' = 'AD',
  'NA' = 'NA'
}

export enum TenderType {
  'Cash' = 'Cash',
  'CreditCard' = 'CreditCard',
  'Check' = 'Check',
  'NoTender' = 'NoTender',
  'Other' = 'Other'
}

export enum TextToTagEnrollmentStatus {
  'Unenrolled' = 'Unenrolled',
  'AwaitingConfirmation' = 'AwaitingConfirmation',
  'Enrolled' = 'Enrolled'
}

export enum TransactionStatus {
  'Active' = 'Active',
  'Expired' = 'Expired',
  'Void' = 'Void',
  'Superseded' = 'Superseded',
  'LdwfVoid' = 'LdwfVoid',
  'Invalidated' = 'Invalidated',
  'Charged' = 'Charged',
  'Returned' = 'Returned',
  'Revoked' = 'Revoked'
}

export enum TurkeyMaturityCode {
  'Adult' = 'Adult',
  'Juvenile' = 'Juvenile'
}

export enum ValidationMethod {
  'AdminPortal' = 'AdminPortal',
  'CustomerPortal' = 'CustomerPortal',
  'TextToTag' = 'TextToTag',
  'IVR' = 'IVR',
  'MobileApp' = 'MobileApp'
}

export enum VoidTransactionReasons {
  'DidNotPrint' = 'DidNotPrint',
  'NoPaymentReceived' = 'NoPaymentReceived',
  'WrongItemPurchased' = 'WrongItemPurchased',
  'Other' = 'Other'
}
